import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_CONFIG, AppConfig} from './config/app.config';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/modules/shared.module';
import {CoreModule} from './core/core.module';
import {TocModule} from './toc/toc.module';
import {PpModule} from './pp/pp.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from './app.translate.factory';
import {ProgressBarService} from './core/shared/progress-bar.service';
import {ProgressInterceptor} from './shared/interceptors/progress.interceptor';
import {LoggingInterceptor} from './shared/interceptors/Logging-Interceptor';
import {TimingInterceptor} from './shared/interceptors/timing.interceptor';
import {NgxExampleLibraryModule} from '@ismaestro/ngx-example-library';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RsaDeciderService} from './rsa-decider/shared/rsadecider-service';
import {GoogleAnalyticsEventsService} from "./google-analytics-events.service";
import {SplunkService} from "../app/rsa-decider/shared/splunk.service";
import { SafecoComponent } from './safeco/safeco.component';
import { RsaAlertDialogComponent } from './rsa-decider/dialog/rsa-alert-dialog/rsa-alert-dialog.component';
import {CloudWatchLoggingService} from '../app/rsa-decider/shared/cloudwatch-logging.service';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaLoaderService } from 'ng-recaptcha'
import { AlternateTransportComponent } from './alternate-transport/alternate-transport.component';
import {
  MatCardModule,
  MatListModule,
} from '@angular/material';
import { AlternateTransportLyft2Component } from './alternate-transport/alternate-transport-lyft2.component';
@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: false}),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    MatCardModule,
    MatListModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),    
    SharedModule.forRoot(),
    NgxExampleLibraryModule.forRoot({
      config: {
        say: 'hello'
      }
    }),
    CoreModule,
    TocModule,
    PpModule,
    AppRoutingModule   
  ],
  declarations: [
    AppComponent,
    SafecoComponent,
    RsaAlertDialogComponent,
    AlternateTransportComponent,
    AlternateTransportLyft2Component
  ],
  entryComponents: [
    RsaAlertDialogComponent
  ],
  exports: [
    MatListModule,
    MatCardModule
  ],
  providers: [  
    RsaDeciderService,
    {provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true},
    SplunkService,
    CloudWatchLoggingService,
    GoogleAnalyticsEventsService,
    RecaptchaLoaderService,
    {provide: APP_CONFIG, useValue: AppConfig},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    }  
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
