import { Component, ElementRef, OnInit } from '@angular/core';
import { RsaDeciderService } from '../rsa-decider/shared/rsadecider-service';
import { Router, ActivatedRoute } from '@angular/router';
import { RsachildService } from '../rsa-decider/shared/rsachild.service';

@Component({
  selector: 'app-alternate-transport-lyft2',
  templateUrl: './alternate-transport-lyft2.component.html',
  styleUrls: ['./alternate-transport-lyft2.component.scss']
})
export class AlternateTransportLyft2Component implements OnInit {

  appDataConfig: any;
  clientLogo: any;
  constructor(public rsaDeciderService: RsaDeciderService, private actroute: ActivatedRoute, private RsachildService: RsachildService, private el: ElementRef, private router: Router) {
    this.actroute.data.map(data => data.cres).subscribe((res) => {
      if (res)
        this.RsachildService.processResolverData(res);
    });
  }

  ngOnInit(): void {
    this.appDataConfig = this.rsaDeciderService.getRsaConfig();
    if (this.appDataConfig) {
      this.clientLogo = this.appDataConfig.logo;
    }
  }

}