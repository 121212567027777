import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AppConfig } from './config/app.config';
import { MatSnackBar } from '@angular/material';
import { RsaDeciderService } from './rsa-decider/shared/rsadecider-service';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import { GoogleAnalyticsEventsService } from "./google-analytics-events.service";


declare const Modernizr;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  isOnline: boolean;
  appDataConfig: any;

  constructor(private translateService: TranslateService,
    private title: Title,
    private meta: Meta,
    private snackBar: MatSnackBar,
    private router: Router,
    public RsaDeciderService: RsaDeciderService,
    private actroute: ActivatedRoute,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {
    this.isOnline = navigator.onLine;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let pageName = event.urlAfterRedirects.split("/")[1] == "" ? "/" : event.urlAfterRedirects.split("/")[1];
        (<any>window).ga('set', 'location', window.location.href);
        (<any>window).ga('set', 'page', pageName);
        (<any>window).ga('send', 'pageview');
      }
      if (event instanceof NavigationStart) {
        this.RsaDeciderService.rsaProgressData.browserRefresh = !router.navigated;
      }
    });
  }
  @HostListener('window:beforeunload', ['$event']) @HostListener('window:pagehide', ['$event']) beforeUnLoadHandler(event: Event) {
    if(this.RsaDeciderService.rsaProgressData.pageRedirects)
    event.returnValue = true;
  }
  // window.onbeforeunload = (event) => {
  //   if(this.RsaDeciderService.rsaProgressData.pageRedirects){
  //   let msg = "want to leave";
  //   event.returnValue= msg;
  //   return msg;
  //   }
  // };

  ngOnInit() {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.checkBrowserFeatures();
    this.getAPIConfiguration();
        let vh = (window.innerHeight * 0.01);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  checkBrowserFeatures() {
    let supported = true;
    for (const feature in Modernizr) {
      if (Modernizr.hasOwnProperty(feature) &&
        typeof Modernizr[feature] === 'boolean' && Modernizr[feature] === false) {
        supported = false;
        break;
      }
    }

    if (!supported) {
      this.translateService.get(['updateBrowser']).subscribe((texts) => {
        this.snackBar.open(texts['updateBrowser'], 'OK');
      });
    }

    return supported;
  }


  getAPIConfiguration() {

    let urlString = window.location.href;
    let urlArray = urlString.split('?rsahelpRequestId=');

    if (urlArray.length > 1) {
      let rsaHelpRequestId = urlArray[1];
      if (rsaHelpRequestId != "") {
        this.RsaDeciderService.setRsaHelpRequestId(rsaHelpRequestId);
      }
    }   
  }



}
