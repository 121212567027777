import { Injectable } from "@angular/core";
import { RsaDeciderService } from '../../rsa-decider/shared/rsadecider-service';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { SplunkService } from '../../rsa-decider/shared/splunk.service';
import { CloudWatchLoggingService } from '../../rsa-decider/shared/cloudwatch-logging.service';
// Environment
import { environment } from "../../../environments/environment";
import { DatePipe } from "@angular/common";


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
    private splunkService: SplunkService,
    private loggingService: CloudWatchLoggingService,
    private rsaDeciderService: RsaDeciderService,
    private datePipe: DatePipe
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Log only if its set in the environment

    if (environment.logging) {
      if (!request.headers.has("noLog")) { // splunk log calls should n't go for log 
        let correlationid = this.splunkService.corelationID; //Unique id for the session
        let eventAction = "";
        let apiUsage = '';
        // getting the user action that triggered the API request 
        if (request.headers.has("userTriggerEvent")) {
          eventAction = request.headers.get("userTriggerEvent");
          request = request.clone({
            headers: request.headers.delete("userTriggerEvent")
          });
        }
        if (request.headers.has("apiPurpose")) {
          apiUsage = request.headers.get("apiPurpose");
          request = request.clone({
            headers: request.headers.delete("apiPurpose")
          });
        }
        console.log(apiUsage);
        eventAction = eventAction + " triggered the api request - url : " + request.url + ", Body: " + JSON.stringify(request.body);
        let step1 = this.rsaDeciderService.rsaProgressData.step1,
          rsaProgressDataLog, callbackNumberLog = "";
        if (step1 && step1.menu && this.rsaDeciderService.rsaProgressData[step1.menu]) {
          rsaProgressDataLog = this.rsaDeciderService.rsaProgressData[step1.menu];
        }
        if (request.headers.has('X-Agero-SourceSystem')) {
          this.rsaDeciderService.rsaProgressData.sourceSystem = request.headers.get('X-Agero-SourceSystem');
        }
        callbackNumberLog = this.rsaDeciderService.callbackNumberLog();
        let apiurl = request.url;
        let menu = this.rsaDeciderService.rsaProgressData[step1.menu]
        let isScheduled;
        if (menu && menu != "undefined") {
          isScheduled = this.rsaDeciderService.rsaProgressData[step1.menu].step2.isScheduled
        }

        let correlationidEvent = this.loggingService.corelationID;
        let eventlogData = {
          "body": {
            'logLevel': 'Info',
            'requestTime': this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
            'logDescription': 'API request ' + apiurl,
            'logMessage': eventAction,
            'correlationid': correlationidEvent,
            'stepInfo': step1,
            'AdditionalInfo': rsaProgressDataLog,
            'deviceInfo': {
              'platform': navigator.platform,
              'userAgent': navigator.userAgent
            },
            'pageTitle': this.rsaDeciderService.rsaProgressData.splunkInfo.currentPageName,
            "callType": this.rsaDeciderService.rsaProgressData.splunkInfo.callType,
            'adID': this.rsaDeciderService.rsaProgressData.splunkInfo.adID,
            'deviceID': this.rsaDeciderService.rsaProgressData.splunkInfo.deviceID,
            "trackingId": this.rsaDeciderService.getRsaTrackingId(),
            "callbackNumber": callbackNumberLog,
            "isScheduled": isScheduled,
            "programCode": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.programcode : "",
            "trackingOtherVinDetails": this.rsaDeciderService.rsaProgressData.trackingIdFlags.trackingOtherVinDetails,
            "isTapOnPhone": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped,
            "isGoldenCall": (this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ||
              (this.rsaDeciderService.rsaProgressData.coverageStatus && this.rsaDeciderService.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.rsaDeciderService.getSkipStatusForLog(),
            "isSilverCall": this.rsaDeciderService.getSilverCallFlag(),
            "isUnpaidCall": this.rsaDeciderService.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
            // "isSilverCall": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ?false : this.rsaDeciderService.getSkipStatusForLog(),
            "clientType": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.clientType : "",
            "sourceSystem": this.rsaDeciderService.rsaProgressData.sourceSystem ? this.rsaDeciderService.rsaProgressData.sourceSystem : "",
            "isNgpClient": true,
            "coverageStatus": this.rsaDeciderService.rsaProgressData.coverageStatus ? this.rsaDeciderService.rsaProgressData.coverageStatus : "",
            "apiPurpose": apiUsage
          },
          "logLevel": "INFO",
          "application": "RSAHELP_UI",
          "environment": environment.envName
        }
        this.loggingService.eventLog(eventlogData);

        return next.handle(request).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              let trackingId;
              if (event && event.body && event.body.trackingId) {
                trackingId = event.body.trackingId;
              }
              if (environment.logSuccess) {
                eventlogData = {
                  "body": {
                    'logLevel': 'Info',
                    'requestTime': this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
                    'logDescription': 'Successful API request ' + apiurl,
                    'logMessage': JSON.stringify(event),
                    'correlationid': correlationidEvent,
                    'stepInfo': step1,
                    'AdditionalInfo': this.rsaDeciderService.rsaProgressData[step1.menu],
                    'deviceInfo': {
                      'platform': navigator.platform,
                      'userAgent': navigator.userAgent
                    },
                    'pageTitle': this.rsaDeciderService.rsaProgressData.splunkInfo.currentPageName,
                    'callType': this.rsaDeciderService.rsaProgressData.splunkInfo.callType,
                    'adID': this.rsaDeciderService.rsaProgressData.splunkInfo.adID,
                    'deviceID': this.rsaDeciderService.rsaProgressData.splunkInfo.deviceID,
                    "trackingId": trackingId ? trackingId : this.rsaDeciderService.getRsaTrackingId(),
                    "callbackNumber": callbackNumberLog,
                    "isScheduled": isScheduled,
                    "programCode": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.programcode : "",
                    "trackingOtherVinDetails": this.rsaDeciderService.rsaProgressData.trackingIdFlags.trackingOtherVinDetails,
                    "isTapOnPhone": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped,
                    "isGoldenCall": (this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ||
                      (this.rsaDeciderService.rsaProgressData.coverageStatus && this.rsaDeciderService.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.rsaDeciderService.getSkipStatusForLog(),
                    "isSilverCall": this.rsaDeciderService.getSilverCallFlag(),
                    "isUnpaidCall": this.rsaDeciderService.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
                    // "isSilverCall": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ?false : this.rsaDeciderService.getSkipStatusForLog(),
                    "clientType": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.clientType : "",
                    "sourceSystem": this.rsaDeciderService.rsaProgressData.sourceSystem ? this.rsaDeciderService.rsaProgressData.sourceSystem : "",
                    "isNgpClient": true,
                    "coverageStatus": this.rsaDeciderService.rsaProgressData.coverageStatus ? this.rsaDeciderService.rsaProgressData.coverageStatus : "",
                    "apiPurpose": apiUsage
                  },
                  "logLevel": "INFO",
                  "application": "RSAHELP_UI",
                  "environment": environment.envName
                }
                this.loggingService.eventLog(eventlogData);
              }
            }
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              // debugger;
              if (err.status === 401 || err.status === 403 || err.status === 404 || (err.status == 200 && !err.ok && err.error)) {
                eventlogData = {
                  "body": {
                    'logLevel': 'Error',
                    'requestTime': this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
                    'logDescription': 'Failed API request unauthorized ' + apiurl,
                    'logMessage': JSON.stringify(err),
                    'correlationid': correlationidEvent,
                    'stepInfo': step1,
                    'AdditionalInfo': this.rsaDeciderService.rsaProgressData[step1.menu],
                    'deviceInfo': {
                      'platform': navigator.platform,
                      'userAgent': navigator.userAgent
                    },
                    'pageTitle': this.rsaDeciderService.rsaProgressData.splunkInfo.currentPageName,
                    "callType": this.rsaDeciderService.rsaProgressData.splunkInfo.callType,
                    'adID': this.rsaDeciderService.rsaProgressData.splunkInfo.adID,
                    'deviceID': this.rsaDeciderService.rsaProgressData.splunkInfo.deviceID,
                    "trackingId": this.rsaDeciderService.getRsaTrackingId(),
                    "callbackNumber": callbackNumberLog,
                    "isScheduled": isScheduled,
                    "programCode": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.programcode : "",
                    "clientType": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.clientType : "",
                    "trackingOtherVinDetails": this.rsaDeciderService.rsaProgressData.trackingIdFlags.trackingOtherVinDetails,
                    "isTapOnPhone": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped,
                    "isGoldenCall": (this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ||
                      (this.rsaDeciderService.rsaProgressData.coverageStatus && this.rsaDeciderService.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.rsaDeciderService.getSkipStatusForLog(),
                    "isSilverCall": this.rsaDeciderService.getSilverCallFlag(),
                    "isUnpaidCall": this.rsaDeciderService.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
                    // "isSilverCall": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ?false : this.rsaDeciderService.getSkipStatusForLog(),
                    "sourceSystem": this.rsaDeciderService.rsaProgressData.sourceSystem ? this.rsaDeciderService.rsaProgressData.sourceSystem : "",
                    "isNgpClient": true,
                    "coverageStatus": this.rsaDeciderService.rsaProgressData.coverageStatus ? this.rsaDeciderService.rsaProgressData.coverageStatus : "",
                    "apiPurpose": apiUsage
                  },
                  "logLevel": "ERROR",
                  "application": "RSAHELP_UI",
                  "environment": environment.envName
                }
              } else if (apiurl.includes(environment.AppConfig.LIVETRACKING.url) && err.status == 0 && err.message == "Http failure response for (unknown url): 0 Unknown Error") {//LIVETRACKING ,0,unknown error
                eventlogData = {
                  "body": {
                    'logLevel': 'Info',
                    'requestTime': this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
                    'logDescription': 'Failed API request ' + apiurl,
                    'logMessage': JSON.stringify(err),
                    'correlationid': correlationidEvent,
                    'stepInfo': step1,
                    'AdditionalInfo': this.rsaDeciderService.rsaProgressData[step1.menu],
                    'deviceInfo': {
                      'platform': navigator.platform,
                      'userAgent': navigator.userAgent
                    },
                    'pageTitle': this.rsaDeciderService.rsaProgressData.splunkInfo.currentPageName,
                    "callType": this.rsaDeciderService.rsaProgressData.splunkInfo.callType,
                    'adID': this.rsaDeciderService.rsaProgressData.splunkInfo.adID,
                    'deviceID': this.rsaDeciderService.rsaProgressData.splunkInfo.deviceID,
                    "trackingId": this.rsaDeciderService.getRsaTrackingId(),
                    "callbackNumber": callbackNumberLog,
                    "isScheduled": isScheduled,
                    "programCode": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.programcode : "",
                    "trackingOtherVinDetails": this.rsaDeciderService.rsaProgressData.trackingIdFlags.trackingOtherVinDetails,
                    "isTapOnPhone": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped,
                    "isGoldenCall": (this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ||
                      (this.rsaDeciderService.rsaProgressData.coverageStatus && this.rsaDeciderService.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.rsaDeciderService.getSkipStatusForLog(),
                    "isSilverCall": this.rsaDeciderService.getSilverCallFlag(),
                    "isUnpaidCall": this.rsaDeciderService.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
                    // "isSilverCall": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ?false : this.rsaDeciderService.getSkipStatusForLog(),
                    "clientType": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.clientType : "",
                    "sourceSystem": this.rsaDeciderService.rsaProgressData.sourceSystem ? this.rsaDeciderService.rsaProgressData.sourceSystem : "",
                    "isNgpClient": true,
                    "coverageStatus": this.rsaDeciderService.rsaProgressData.coverageStatus ? this.rsaDeciderService.rsaProgressData.coverageStatus : "",
                    "apiPurpose": apiUsage
                  },
                  "logLevel": "INFO",
                  "application": "RSAHELP_UI",
                  "environment": environment.envName
                }
              }
              else {
                eventlogData = {
                  "body": {
                    'logLevel': 'Critical',
                    'requestTime': this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
                    'logDescription': 'Failed API request ' + apiurl,
                    'logMessage': JSON.stringify(err),
                    'correlationid': correlationidEvent,
                    'stepInfo': step1,
                    'AdditionalInfo': this.rsaDeciderService.rsaProgressData[step1.menu],
                    'deviceInfo': {
                      'platform': navigator.platform,
                      'userAgent': navigator.userAgent
                    },
                    'pageTitle': this.rsaDeciderService.rsaProgressData.splunkInfo.currentPageName,
                    "callType": this.rsaDeciderService.rsaProgressData.splunkInfo.callType,
                    'adID': this.rsaDeciderService.rsaProgressData.splunkInfo.adID,
                    'deviceID': this.rsaDeciderService.rsaProgressData.splunkInfo.deviceID,
                    "trackingId": this.rsaDeciderService.getRsaTrackingId(),
                    "callbackNumber": callbackNumberLog,
                    "isScheduled": isScheduled,
                    "programCode": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.programcode : "",
                    "trackingOtherVinDetails": this.rsaDeciderService.rsaProgressData.trackingIdFlags.trackingOtherVinDetails,
                    "isTapOnPhone": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped,
                    "isGoldenCall": (this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ||
                      (this.rsaDeciderService.rsaProgressData.coverageStatus && this.rsaDeciderService.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.rsaDeciderService.getSkipStatusForLog(),
                    "isSilverCall": this.rsaDeciderService.getSilverCallFlag(),
                    "isUnpaidCall": this.rsaDeciderService.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
                    // "isSilverCall": this.rsaDeciderService.rsaProgressData.trackingIdFlags.isPhoneTapped ?false : this.rsaDeciderService.getSkipStatusForLog(),
                    "clientType": this.rsaDeciderService.appDataConfig ? this.rsaDeciderService.appDataConfig.clientType : "",
                    "sourceSystem": this.rsaDeciderService.rsaProgressData.sourceSystem ? this.rsaDeciderService.rsaProgressData.sourceSystem : "",
                    "isNgpClient": true,
                    "coverageStatus": this.rsaDeciderService.rsaProgressData.coverageStatus ? this.rsaDeciderService.rsaProgressData.coverageStatus : "",
                    "apiPurpose": apiUsage
                  },
                  "logLevel": "CRITICAL",
                  "application": "RSAHELP_UI",
                  "environment": environment.envName
                }
              }
              this.loggingService.eventLog(eventlogData);
            }
          }));
      }
    }
  }
}
