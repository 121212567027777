import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AppConfig } from '../../config/app.config';
import { Router,Event,NavigationEnd } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { LoggerService } from '../../core/shared/logger.service';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { SplunkService } from './splunk.service';
import { environment } from "../../../environments/environment";
import { AppStrings } from "./application-strings";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { RsaDialog } from "../dialog/rsa-dialog.component";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import {DatePipe} from '@angular/common';
import { RsaAlertDialogComponent } from '../dialog/rsa-alert-dialog/rsa-alert-dialog.component';
import { CloudWatchLoggingService } from './cloudwatch-logging.service';
import {DeviceUUID} from 'device-uuid';

export class IPolicyConfig {
  policyNumber: number;
  firstName: string;
  lastName: string;
  phoneNumber: number;
  email: string;
}

@Injectable()
export class RsaDeciderService {
  public appDataConfig: any;
  public appVehicleMake: any;
  public appVehicleMakeFromVPIC: any;
  public appVehicleColor: any;
  public policyUrl = '../../assets/data/policy-data.json';
  public stepInfo;
  public audio = new Audio();
  public menuItems = ["battery_menu", "flat_menu", "lockout_menu", "needtow_menu", "outfuel_menu", "vehiclestuck_menu"];
  isAreaCodeApiCalled = false; 
  chatAvailable = false;
  chatAppCustomerInfo;
  skippedDl = false;
  skippedTD = false;
  skippedPolicy = false;
  skippedVin = false;

  public rsaProgressData: any = {
    "step1": { "JobId":"", "jobStatus":"", "menu": "", "editScreen": true, "editRouter": "", "agentCall": "", "stepInfo": "", "currentStep": "", "totalStep": "", "rsahelpRequestId": "", "rsaRequestId": "", "selectedMenu": "", "errorStatus": "", "vinErrorStatus": "", 'stepCount': 1 },
    "latitude": "",
    "longitude": "",
    "browserRefresh": false,
    "current_address": "",
    "audio_type": "2",
    "audioTypeFound": false,
    "httpUrlSearch": "",
    "sourceSystem": "",
    "coverageStatus": "",
    "loadingScreen": false,
    "isCallSubmitted": false,
    "splunkInfo": { "callType": "default", "adID": "", "deviceID": "", "currentPageName": "" },
    "trackingIdFlags": { "hasCallBack": false, "hasRsaHelpRequestId": false, "hasCallbackAndPhoneTap": false, "isPhoneTapped": false, "hasTrackingId": false, "isGoldCall": false, "isSilverCall":false, "isTrackingDetails": false, "saveLiveTrackingDetails": false, "trackingOtherVinDetails": false },
    "callbacknumber_fromurl": "",
    "rsaHelpCallBackNumber": "",
    "query_strings": { "estDateTime": "", "vdn": "" },
    "audioIntervalId": "",
    "towDestinationList": [],
    "selectedTowDestination":"",
    "temporaryLocation": { "tempLatitude": "", "tempLongitude": "" },
    "pageRedirects": false,
    'deviceInfo': {
      'platform': navigator.platform,
      'userAgent': navigator.userAgent,
      "deviceUID": new DeviceUUID().get()
    },
    "battery_menu": {
      "step2": { "q1": "", "q2": "","isScheduled":"","scheduleDate": "", "q3": "", "q4": "", "q5": "", "isElectricVehicle": true, "tire": "", "frontDriver": "", "frontPassenger": "", "rearDriver": "", "rearPassenger": "","time":"","utcOffset":"" },
      "step3": { "q1": "", "safeLocation":"", "blockTraffic":"", "garageHeight":"", "direction":"", "exit":"", "latitude": "", "longitude": "", "locationType": "", "locationTypeAdditional":"", "level":"", "section": "", "feet": "", "inches":"", "skipLocation": false },
      "step4": { "q1": "", "towaddress": "", "latitude": "", "longitude": "", "clear": "", "checkboxhelp": "", "placeholder": "Enter the Tow Destination", "agentcheckbox": "", "triggeragenttext": false, "skipTow": false, "isTowNeeded": false, "isCustomAddress": false, "serviceHour": "", "rating": "", "warrantyPeriod": "", "certificationFlag": "", "notes": "", "offers": [], "miles":"", "selectedTDId":"", "selectedTowPosition":"", "isTDRepairLocation":""},
      "step5": { "policynumber": "", "zipCode": "", "showinformation": "", "profFirstName": "", "profLastName": "", "firstname": "", "lastname": "", "ngpname":"", "phonenumber": "", "preference": 0, "email": "", "skipPolicy": false, "mvanumber": "", "ranumber": "", "coverageamount": "", "rentalcode": "", "agentname": "", "agentnumber": "", "previousPolicynumber": "", "isPolicyValid": false, "pmrNumber":""},
      "step6": { "q1": "", "vin": "", "vinDecoder": false, "showVinInfo":"", "skipVin": false, "mileage": "", "othercolor": "", "vehicleinformation": "", "year": null, "make": "", "makeValue": "", "model": "", "color": "" , "vehicleType":"", "isVinValid": false, "previousVinNumber": "", "isVinFound": false, "last4DigitVin": "", "previousLast4DigitVin": "", "otherVehicle": false},
      "step7": { "q1": "" },
      "scheduleService":{"q1": "","isScheduled":"","scheduleDate": "","time":"","utcOffset":""},
      "alternateTransportation": {"isAlternateTransportPageOffered": null, "isAlternateTransportOpted": null,"isAlternateTransportRequired": null, "voucherBenefit": null, "voucherExpiryInHrs" : null, "voucherNoOfRides" : null, "rideProgramID" : null, "isVoucherAlreadyIssuedForDevice":null, "isVoucherGenerated": null},
      "serviceName": "Battery Service",
      "symptom": {"key":"","value":"", "comments":""}
    },
    "flat_menu": {
      "step2": { "q1": "", "q2": "","isScheduled":"", "scheduleDate": "", "q3": "", "q5": "", "isElectricVehicle": false, "tire": "", "frontDriver": "", "frontPassenger": "", "rearDriver": "", "rearPassenger": "", "flatTireTrailer":"", "noOfFlatTiresTrailer":"", "tireSelected": [],"time":"","utcOffset":"" },
      "step3": { "q1": "",  "safeLocation":"", "blockTraffic":"", "garageHeight":"", "direction":"", "exit":"", "latitude": "", "longitude": "", "locationType": "", "locationTypeAdditional":"", "level":"", "section": "", "feet": "", "inches":"", "skipLocation": false },
      "step4": { "q1": "", "towaddress": "", "latitude": "", "longitude": "", "clear": "", "checkboxhelp": "", "placeholder": "Enter the Tow Destination", "agentcheckbox": "", "triggeragenttext": false, "skipTow": false, "isTowNeeded": false, "isCustomAddress": false, "serviceHour": "", "rating": "", "warrantyPeriod": "", "certificationFlag": "", "notes": "", "offers": [], "miles":"", "selectedTDId":"", "selectedTowPosition":"", "isTDRepairLocation":""},
      "step5": { "policynumber": "", "zipCode": "", "showinformation": "", "profFirstName": "", "profLastName": "", "firstname": "", "lastname": "", "ngpname":"", "phonenumber": "", "preference": 0, "email": "", "skipPolicy": false, "mvanumber": "", "ranumber": "", "coverageamount": "", "rentalcode": "", "agentname": "", "agentnumber": "", "previousPolicynumber": "", "isPolicyValid": false, "pmrNumber":""},
      "step6": { "q1": "", "vin": "", "vinDecoder": false, "showVinInfo":"", "skipVin": false, "mileage": "", "othercolor": "", "vehicleinformation": "", "year": null, "make": "", "makeValue": "", "model": "", "color": "" ,  "vehicleType":"", "isVinValid": false, "previousVinNumber": "", "isVinFound": false, "last4DigitVin": "", "previousLast4DigitVin": "", "otherVehicle": false},
      "step7": { "q1": "" },
      "scheduleService":{"q1": "","isScheduled":"","scheduleDate": "","time":"","utcOffset":""},
      "alternateTransportation": {"isAlternateTransportPageOffered": null, "isAlternateTransportOpted": null,"isAlternateTransportRequired": null, "voucherBenefit": null, "voucherExpiryInHrs" : null, "voucherNoOfRides" : null, "rideProgramID" : null, "isVoucherAlreadyIssuedForDevice":null, "isVoucherGenerated": null},
      "serviceName": "Flat Tire(s)",
      "symptom": {"key":"","value":"", "comments":""}
    },
    "lockout_menu": {
      "step2": { "q1": "", "q2": "", "isScheduled":"","scheduleDate": "", "q5": "", "isElectricVehicle": false,"time":"","utcOffset":"","isVehicleOn":"","keysLocated":"","trunkOptions":"" },
      "step3": { "q1": "",  "safeLocation":"", "blockTraffic":"", "garageHeight":"", "direction":"", "exit":"", "latitude": "", "longitude": "", "locationType": "", "locationTypeAdditional":"", "level":"", "section": "", "feet": "", "inches":"", "skipLocation": false },
      "step4": { "q1": "", "towaddress": "", "latitude": "", "longitude": "", "clear": "", "checkboxhelp": "", "placeholder": "Enter the Tow Destination", "agentcheckbox": "", "triggeragenttext": false, "skipTow": false, "isTowNeeded": false, "isCustomAddress": false, "serviceHour": "", "rating": "", "warrantyPeriod": "", "certificationFlag": "", "notes": "", "offers": [], "miles":"", "selectedTDId":"", "selectedTowPosition":"", "isTDRepairLocation":""},
      "step5": { "policynumber": "", "zipCode": "", "showinformation": "", "profFirstName": "", "profLastName": "", "firstname": "", "lastname": "", "ngpname":"",  "phonenumber": "", "preference": 0, "email": "", "skipPolicy": false, "mvanumber": "", "ranumber": "", "coverageamount": "", "rentalcode": "", "agentname": "", "agentnumber": "", "previousPolicynumber": "", "isPolicyValid": false, "pmrNumber":"" },
      "step6": { "q1": "", "vin": "", "vinDecoder": false, "showVinInfo":"", "skipVin": false, "mileage": "", "othercolor": "", "vehicleinformation": "", "year": null, "make": "", "makeValue": "", "model": "", "color": "" , "vehicleType":"", "isVinValid": false, "previousVinNumber": "", "isVinFound": false, "last4DigitVin": "", "previousLast4DigitVin": "", "otherVehicle": false},
      "step7": { "q1": "" },
      "scheduleService":{"q1": "","isScheduled":"","scheduleDate": "","time":"","utcOffset":""},
      "alternateTransportation": {"isAlternateTransportPageOffered": null, "isAlternateTransportOpted": null,"isAlternateTransportRequired": null, "voucherBenefit": null, "voucherExpiryInHrs" : null, "voucherNoOfRides" : null, "rideProgramID" : null, "isVoucherAlreadyIssuedForDevice":null, "isVoucherGenerated": null},
      "serviceName": "locked Out",
      "symptom": {"key":"","value":"","comments":""}
    },
    "needtow_menu": {
      "step2": { "q1": "", "licensePlate": "", "q2": "", "isScheduled":"", "q5": "", "isElectricVehicle": false, "scheduleDate": "", "q3": "", "tire": "", "frontDriver": "", "frontPassenger": "", "rearDriver": "", "rearPassenger": "","time":"","utcOffset":"","isSafeAtHome":"", "isTowRecommend":"","vehicleNeutral":"", "fourWheelDrive":""},
      "step3": { "q1": "",  "safeLocation":"", "blockTraffic":"", "garageHeight":"", "direction":"", "exit":"", "latitude": "", "longitude": "", "locationType": "", "locationTypeAdditional":"", "level":"", "section": "", "feet": "", "inches":"", "skipLocation": false },
      "step4": { "q1": "", "towaddress": "", "latitude": "", "longitude": "", "clear": "", "checkboxhelp": "", "placeholder": "Enter the Tow Destination", "agentcheckbox": "", "triggeragenttext": false, "skipTow": false, "isTowNeeded": true, "isCustomAddress": false, "serviceHour": "", "rating": "", "warrantyPeriod": "", "certificationFlag": "", "notes": "", "offers": [], "miles":"", "selectedTDId":"", "selectedTowPosition":"", "isTDRepairLocation":""},
      "step5": { "policynumber": "", "zipCode": "", "showinformation": "", "profFirstName": "", "profLastName": "", "firstname": "", "lastname": "", "ngpname":"",  "phonenumber": "", "preference": 0, "email": "", "skipPolicy": false, "mvanumber": "", "ranumber": "", "coverageamount": "", "rentalcode": "", "agentname": "", "agentnumber": "", "previousPolicynumber": "", "isPolicyValid": false, "pmrNumber":"" },
      "step6": { "q1": "", "vin": "", "vinDecoder": false, "showVinInfo":"", "skipVin": false, "mileage": "", "othercolor": "", "vehicleinformation": "", "year": null, "make": "", "makeValue": "", "model": "", "color": "" , "vehicleType":"", "isVinValid": false, "previousVinNumber": "", "isVinFound": false, "last4DigitVin": "", "previousLast4DigitVin": "", "otherVehicle": false, "isTrailerRequired": "" },
      "step7": { "q1": "" },
      "scheduleService":{"q1": "","isSafeAtHome":"","licensePlate":"","isScheduled":"","scheduleDate": "","time":"","utcOffset":""},
      "alternateTransportation": {"isAlternateTransportPageOffered": null, "isAlternateTransportOpted": null,"isAlternateTransportRequired": null,"voucherBenefit": null, "voucherExpiryInHrs" : null, "voucherNoOfRides" : null, "rideProgramID" : null, "isVoucherAlreadyIssuedForDevice":null, "isVoucherGenerated": null},
      "serviceName": "Need a Tow",
      "symptom": {"key":"","value":"","comments":""}
    },
    "outfuel_menu": {
      "step2": { "q1": "", "q2": "", "isScheduled":"","scheduleDate": "", "q5": "", "isElectricVehicle": false,"time":"","utcOffset":"" },
      "step3": { "q1": "",  "safeLocation":"", "blockTraffic":"", "garageHeight":"", "direction":"", "exit":"", "latitude": "", "longitude": "", "locationType": "", "locationTypeAdditional":"", "level":"", "section": "", "feet": "", "inches":"", "skipLocation": false },
      "step4": { "q1": "", "towaddress": "", "latitude": "", "longitude": "", "clear": "", "checkboxhelp": "", "placeholder": "Enter the Tow Destination", "agentcheckbox": "", "triggeragenttext": false, "skipTow": false, "isTowNeeded": false, "isCustomAddress": false, "serviceHour": "", "rating": "", "warrantyPeriod": "", "certificationFlag": "", "notes": "", "offers": [], "miles":"", "selectedTDId":"", "selectedTowPosition":"", "isTDRepairLocation":""},
      "step5": { "policynumber": "", "zipCode": "", "showinformation": "", "profFirstName": "", "profLastName": "", "firstname": "", "lastname": "", "phonenumber": "", "preference": 0, "email": "", "skipPolicy": false, "mvanumber": "", "ranumber": "", "ngpname":"",  "coverageamount": "", "rentalcode": "", "agentname": "", "agentnumber": "", "previousPolicynumber": "", "isPolicyValid": false, "pmrNumber":"" },
      "step6": { "q1": "", "vin": "", "vinDecoder": false, "showVinInfo":"", "skipVin": false, "mileage": "", "othercolor": "", "vehicleinformation": "", "year": null, "make": "", "makeValue": "", "model": "", "color": "" , "vehicleType":"", "isVinValid": false, "previousVinNumber": "", "isVinFound": false, "last4DigitVin": "", "previousLast4DigitVin": "", "otherVehicle": false},
      "step7": { "q1": "" },
      "scheduleService":{"q1": "","isScheduled":"","scheduleDate": "","time":"","utcOffset":""},
      "alternateTransportation": {"isAlternateTransportPageOffered": null, "isAlternateTransportOpted": null,"isAlternateTransportRequired": null, "voucherBenefit": null, "voucherExpiryInHrs" : null, "voucherNoOfRides" : null, "rideProgramID" : null, "isVoucherAlreadyIssuedForDevice":null, "isVoucherGenerated": null},
      "serviceName": "Out of Fuel",
      "symptom": {"key":"","value":"","comments":""}
    },
    "vehiclestuck_menu": {
      "step2": { "q1": "", "q2": "", "isScheduled":"", "scheduleDate": "", "q5": "", "isElectricVehicle": false,"time":"","utcOffset":"" },
      "step3": { "q1": "",  "safeLocation":"", "blockTraffic":"", "garageHeight":"", "direction":"", "exit":"", "latitude": "", "longitude": "", "locationType": "", "locationTypeAdditional":"", "level":"", "section": "", "feet": "", "inches":"", "skipLocation": false },
      "step4": { "q1": "", "towaddress": "", "latitude": "", "longitude": "", "clear": "", "checkboxhelp": "", "placeholder": "Enter the Tow Destination", "agentcheckbox": "", "triggeragenttext": false, "skipTow": false, "isTowNeeded": false, "isCustomAddress": false, "serviceHour": "", "rating": "", "warrantyPeriod": "", "certificationFlag": "", "notes": "", "offers": [], "miles":"", "selectedTDId":"", "selectedTowPosition":"", "isTDRepairLocation":""},
      "step5": { "policynumber": "", "zipCode": "", "showinformation": "", "profFirstName": "", "profLastName": "", "firstname": "", "lastname": "", "ngpname":"", "phonenumber": "", "preference": 0, "email": "", "skipPolicy": false, "mvanumber": "", "ranumber": "", "coverageamount": "", "rentalcode": "", "agentname": "", "agentnumber": "", "previousPolicynumber": "", "isPolicyValid": false, "pmrNumber":"" },
      "step6": { "q1": "", "vin": "", "vinDecoder": false, "showVinInfo":"", "skipVin": false, "mileage": "", "othercolor": "", "vehicleinformation": "", "year": null, "make": "", "makeValue": "", "model": "", "color": "" , "vehicleType":"", "isVinValid": false, "previousVinNumber": "", "isVinFound": false, "last4DigitVin": "", "previousLast4DigitVin": "", "otherVehicle": false},
      "step7": { "q1": "" },
      "scheduleService":{"q1": "","isScheduled":"","scheduleDate": "","time":"","utcOffset":""},
      "alternateTransportation": {"isAlternateTransportPageOffered": null, "isAlternateTransportOpted": null,"isAlternateTransportRequired": null, "voucherBenefit": null, "voucherExpiryInHrs" : null, "voucherNoOfRides" : null, "rideProgramID" : null, "isVoucherAlreadyIssuedForDevice":null, "isVoucherGenerated": null},
      "serviceName": "Vehicle Stuck",
      "symptom": {"key":"","value":"", "comments":""}
    },
    "programsubcode": "",
    "address_object" : {
      "streetAddress" : "",
      "city" : "",
      "state" : "",
      "country" : "",
      "postalcode" : ""
    },
    "tow_address_object" : {
      "streetAddress" : "",
      "city" : "",
      "state" : "",
      "country" : "",
      "postalcode" : ""
    },
    "policySearchResponse" : {},
    "vinUpdateProfileCalled" : false,
    "skipVinOEM" : false,
    "profileSearchError":false,
    "isNewProfileSearch":false,
    "encaptchaSuccess":false,
    "isEncaptchaSuccess":false,
    "payment": {"isPaymentReqd" : false,"amountDue" : "", "customerWebLink" : null},
    "profileCollected": {
      "isProfileFoundBasedOnPhoneNumber": false,
      "isCustomerEnteredPolicy": false,
      "isCustomerEnteredWholeVin": false,
      "isCustomerEnteredLast4digitVinForUSM": false,
      "isVehicleListedFromProfileSearch": false
    },
    "vehicleData": {
      "vinEntered": '',
      "vehicleSelected": false
    }
  };
  public rsaResetData = this.rsaProgressData;
  public rsaHelpRequestID;
  public rsaHelpTrackID;
  public appStrings;
  public attributeData;
  private apiUrl: string;
  public liveCallStatus: boolean = false;
  public escalationCallType: string;
  public isStep5;
  public isStep6;
  public reviewSubmit;
  public platformId;
  public isCustomVehicleModel:boolean = false;
  public transitionEndState;

  constructor(private datePipe: DatePipe,private http: HttpClient,
    private snackBar: MatSnackBar, private router: Router, private splunkService: SplunkService, private loggingService: CloudWatchLoggingService, public dialog: MatDialog, public _translateService: TranslateService) {
    this.apiUrl = '../../assets/data/api-data.json';
    this.router.events.subscribe((event:Event) => {
      if(event instanceof NavigationEnd ){
        this.isStep5 = event.url.indexOf("rsahelp-step5")
        this.isStep6 = event.url.indexOf("rsahelp-step6") 
        this.reviewSubmit = event.url.indexOf("review-submit")
      }
    });
  // _translateService.onLangChange.subscribe((event: LangChangeEvent) => {
  //   this.currentLang = _translateService.currentLang
  // });
    this.appStrings = AppStrings.en_app_strings;   
    this.attributeData = AppStrings.attributeData; 
  }

  public shareAllData(menu) {

    if (menu && menu != "" && menu != "accident_menu" && menu != "dontknow_menu") {
      for (let stepKey in this.rsaProgressData[menu]) {
        if (this.rsaProgressData[menu].hasOwnProperty(stepKey) && stepKey != "serviceName") {
          for (let key in this.rsaProgressData[menu][stepKey]) {
            if (this.rsaProgressData[menu][stepKey].hasOwnProperty(key) && key != "q2" && key != "q3" && key != "isTowNeeded") {
              if (key === "towaddress") {
                this.menuItems.forEach((eachServices) => {
                  if (this.rsaProgressData[eachServices][stepKey].hasOwnProperty(key)) {
                    this.rsaProgressData[eachServices][stepKey][key] = this.rsaProgressData[menu][stepKey][key];
                  }
                });
              } else {
                if ((this.rsaProgressData[menu][stepKey][key] && this.rsaProgressData[menu][stepKey][key] != "") || typeof (this.rsaProgressData[menu][stepKey][key]) === "boolean" || typeof (this.rsaProgressData[menu][stepKey][key]) === "number") {
                  this.menuItems.forEach((eachServices) => {
                    if (this.rsaProgressData[eachServices][stepKey].hasOwnProperty(key)) {
                      this.rsaProgressData[eachServices][stepKey][key] = this.rsaProgressData[menu][stepKey][key];
                    }
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  public setEscalationCallType(callType): void {
    this.escalationCallType = callType;
  }

  public setRsaHelpRequestId(id) {
    this.rsaHelpRequestID = id;
  }

  public getRsaHelpRequestId() {
    return this.rsaHelpRequestID;
  }

  public setRsaTrackingId(id) {
    this.rsaHelpTrackID = id;
  }

  public getRsaTrackingId() {
    return this.rsaHelpTrackID;
    //return "41c0ad20-1462-4b09-b0be-5c09f41d9075";

  }

  // public setliveCallStatus(flag) {
  //   this.liveCallStatus = flag;
  // }

  // public getliveCallStatus() {
  //   return this.liveCallStatus;
  // }


  public getRsaHelpRequestData(appURL, httpOptions) {
    return this.http.get<any>(appURL, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`getExternal URL` + appURL)),
        catchError(this.handleError('getExternal URL', []))
      );
  }

  public processRsaHelpRequest(responseData) {
    try {
      this.rsaProgressData.step1.menu = "";
      if (responseData) {
        this.nonAPISplunkLog("getTrackingDetails ngpPreDraftJobId", responseData.ngpPreDraftJobId);
        this.rsaProgressData.step1.JobId = responseData.ngpPreDraftJobId;
        this.nonAPISplunkLog("getTrackingDetails jobid", this.rsaProgressData.step1.JobId);
        if (responseData.additionalDetails) {
          this.rsaProgressData.step1.menu = responseData.additionalDetails.selectedMenu;
        }
        // setting menu for rsaHelpRequestId flow
        let isRsaHelpFlow = JSON.parse(localStorage.getItem("isRsaHelpRequestIdFlow"));
        if (isRsaHelpFlow && responseData.serviceDetails) {
          if (responseData.serviceDetails.disablementReason == "Tow-(Not leaking fuel)") {
            this.rsaProgressData.step1.menu = "needtow_menu";
          } else if (responseData.serviceDetails.disablementReason == "Jump Start (Did not stall while driving)") {
            this.rsaProgressData.step1.menu = "battery_menu";
          } else if (responseData.serviceDetails.disablementReason == "One Flat Tire - Good spare" ||
            responseData.serviceDetails.disablementReason == "Multiple Flat Tires") {
            this.rsaProgressData.step1.menu = "flat_menu";
          } else if (responseData.serviceDetails.disablementReason == "Lockout - Keys in car") {
            this.rsaProgressData.step1.menu = "lockout_menu";
          } else if (responseData.serviceDetails.disablementReason == "Vehicle Stuck") {
            this.rsaProgressData.step1.menu = "vehiclestuck_menu";
          } else if (responseData.serviceDetails.disablementReason == "Out of Fuel (Cost of fuel not covered)") {
            this.rsaProgressData.step1.menu = "outfuel_menu";
          }
          this.rsaProgressData.step1.selectedMenu =  this.rsaProgressData.step1.menu;
        } 
        
        if (responseData.requestor) {
          this.rsaProgressData.callbacknumber_fromurl = responseData.requestor.callbackNumber;
        }
        if (this.rsaProgressData.step1.menu) {
          if (responseData.scheduleDetails != null) {
            this.rsaProgressData[this.rsaProgressData.step1.menu].scheduleService.isScheduled = responseData.isScheduled;
            this.rsaProgressData[this.rsaProgressData.step1.menu].scheduleService.scheduleDate = responseData.scheduleDetails.date;
            this.rsaProgressData[this.rsaProgressData.step1.menu].scheduleService.time = responseData.scheduleDetails.timeRange;
            this.rsaProgressData[this.rsaProgressData.step1.menu].scheduleService.utcOffset = responseData.scheduleDetails.utcOffset;
          } else {
            this.rsaProgressData[this.rsaProgressData.step1.menu].scheduleService.isScheduled = responseData.isScheduled;
          }

          if(responseData.serviceDetails && responseData.serviceDetails.symptom && 
            this.rsaProgressData.step1.menu == "needtow_menu"){
              let key = Object.keys(responseData.serviceDetails.symptom);
              let value = Object.values(responseData.serviceDetails.symptom);
              this.rsaProgressData[this.rsaProgressData.step1.menu].symptom.key = key[0];
              this.rsaProgressData[this.rsaProgressData.step1.menu].symptom.value = value[0];
  
            }
        }
        this.rsaProgressData.step1.rsaRequestId = responseData.rsaRequestId;
        if (!this.rsaProgressData.trackingIdFlags.hasCallBack) {
          if (!this.rsaProgressData.step1.rsahelpRequestId)
            this.rsaProgressData.step1.rsahelpRequestId = responseData.rsahelpRequestId;
          if (responseData.rsahelpRequestId && responseData.rsahelpRequestId != "") {
            this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId = true;
          }
        }

        if (responseData.disablementLocation) {
          var locationAddress = (responseData.disablementLocation.address.streetAddress1) ? responseData.disablementLocation.address.streetAddress1 + ", " : '';
          locationAddress += (responseData.disablementLocation.address.city) ? responseData.disablementLocation.address.city + ", " : '';
          if (responseData.disablementLocation.address.zip) {
            this.rsaProgressData[this.rsaProgressData.step1.menu].step3.zip = responseData.disablementLocation.address.zip;
            locationAddress += (responseData.disablementLocation.address.state) ? responseData.disablementLocation.address.state + " " : "";
          } else {
            locationAddress += (responseData.disablementLocation.address.state) ? responseData.disablementLocation.address.state + ", " : "";
          }
          locationAddress += (responseData.disablementLocation.address.zip) ? responseData.disablementLocation.address.zip + ", " : "";
          locationAddress += (responseData.disablementLocation.address.country) ? responseData.disablementLocation.address.country + " " : "";
        }

        var towAddress = '';
        if (responseData.towDestination && responseData.towDestination.address) {
          towAddress = (responseData.towDestination.address.streetAddress1 && responseData.towDestination.address.streetAddress1 !== "NA") ? responseData.towDestination.address.streetAddress1 + ", " : '';
          towAddress += (responseData.towDestination.address.city) ? responseData.towDestination.address.city + ", " : '';
          towAddress += (responseData.towDestination.address.state) ? responseData.towDestination.address.state + ", " : "";
          towAddress+=(responseData.towDestination.address.zip)?responseData.towDestination.address.zip+", ":"";
          if (responseData.disablementLocation) {
            towAddress += (responseData.towDestination.address.country) ? responseData.towDestination.address.country + " " : "";
          }
        }
        var preferenceMode = 0;
        if (responseData.notificationPreferences) {
          if (responseData.notificationPreferences.preferredMode == "SMS") {
            preferenceMode = 0;
          } else if (responseData.notificationPreferences.preferredMode == "EMAIL") {
            preferenceMode = 1;
          } else if (responseData.notificationPreferences.preferredMode == "NONE") {
            preferenceMode = 2;
          }
        }


        for (let i = 0; i < this.menuItems.length; i++) {
          let menu = this.menuItems[i];
          if (responseData.disablementLocation) {
            this.rsaProgressData[menu].scheduleService.q1 = responseData.disablementLocation.customerAtLocation ? responseData.disablementLocation.customerAtLocation : "";
            this.rsaProgressData[menu].step3.latitude = responseData.disablementLocation.geographicalCoordinates.latitude ? parseFloat(responseData.disablementLocation.geographicalCoordinates.latitude) : "";
            this.rsaProgressData[menu].step3.longitude = responseData.disablementLocation.geographicalCoordinates.longitude ? parseFloat(responseData.disablementLocation.geographicalCoordinates.longitude) : "";
            this.rsaProgressData[menu].step3.locationType = (responseData.disablementLocation.locationType == 'BlockingTraffic') ?
              (responseData.disablementLocation.direction ? 'Roadside-Highway' : 'Roadside-Street') : responseData.disablementLocation.locationType;
            this.rsaProgressData[menu].step3.direction = responseData.disablementLocation.direction;
            this.rsaProgressData[menu].step3.exit = responseData.disablementLocation.exit;
            this.rsaProgressData[menu].step3.blockTraffic = responseData.disablementLocation.customerBlockingTraffic;
            this.rsaProgressData[menu].step3.safeLocation = responseData.disablementLocation.customerInSafeLocation;
            this.rsaProgressData[menu].step3.garageHeight = responseData.disablementLocation.garageHeight;
            this.rsaProgressData[menu].step3.locationTypeAdditional = responseData.disablementLocation.locationTypeAdditional;
            this.rsaProgressData[menu].step3.level = responseData.disablementLocation.garageDetails.level;
            this.rsaProgressData[menu].step3.section = responseData.disablementLocation.garageDetails.section;
            this.rsaProgressData[menu].step3.feet = responseData.disablementLocation.garageDetails.feet;
            this.rsaProgressData[menu].step3.inches = (responseData.disablementLocation.garageDetails.inches!=0?responseData.disablementLocation.garageDetails.inches : null);
          }

          this.rsaProgressData[menu].step3.q1 = locationAddress ? locationAddress : "";

          if (i != 2 && responseData.towDestination && towAddress != "") {
            this.rsaProgressData[menu].step4.q1 = towAddress;
            this.rsaProgressData[menu].step4.towaddress = towAddress;
            this.rsaProgressData[menu].step4.latitude = parseFloat(responseData.towDestination.geographicalCoordinates.latitude);
            this.rsaProgressData[menu].step4.longitude = parseFloat(responseData.towDestination.geographicalCoordinates.longitude);
            this.rsaProgressData[menu].step4.agentcheckbox = false;
            this.rsaProgressData[menu].step4.miles = responseData.towDestination.miles;
          }

          if (responseData.profile && responseData.profile.insurancePolicyNumber) {
            this.rsaProgressData[menu].step5.policynumber = responseData.profile.insurancePolicyNumber;
            this.rsaProgressData[menu].step5.zipCode = responseData.profile.zipCode;
            this.rsaProgressData[menu].step5.previousPolicynumber = responseData.profile.insurancePolicyNumber;
            if(responseData.profile.name) {
              this.rsaProgressData[menu].step5.profFirstName = responseData.profile.name.firstName;
              this.rsaProgressData[menu].step5.profLastName = responseData.profile.name.lastName;            
            }
          }
          

          if (responseData.requestor) {
            this.rsaProgressData[menu].step5.firstname = responseData.requestor.name.firstName;
            this.rsaProgressData[menu].step5.lastname = responseData.requestor.name.lastName;
            this.rsaProgressData[menu].step5.phonenumber = responseData.requestor.callbackNumber;
            this.rsaProgressData.rsaHelpCallBackNumber = responseData.requestor.callbackNumber
          }

          this.rsaProgressData[menu].step5.preference = preferenceMode;
          if (responseData.notificationPreferences) {
            this.rsaProgressData[menu].step5.email = responseData.notificationPreferences.emailId;
          }
          if(responseData.deviceInfo){
            this.rsaProgressData.deviceInfo.platform = responseData.deviceInfo.platform;
            this.rsaProgressData.deviceInfo.userAgent = responseData.deviceInfo.userAgent;
            this.rsaProgressData.deviceInfo.deviceUID = responseData.deviceInfo.deviceUID;
          }
          if (responseData.alternateTransportation) {
            this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered = responseData.alternateTransportation.isAlternateTransportPageOffered;
            this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted = responseData.alternateTransportation.isAlternateTransportOpted;
            this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired = responseData.alternateTransportation.isAlternateTransportRequired;
            this.rsaProgressData[menu].alternateTransportation.voucherBenefit = responseData.alternateTransportation.voucherBenefit;
            this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs = responseData.alternateTransportation.voucherExpiryInHrs;
            this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides = responseData.alternateTransportation.voucherNoOfRides;
            this.rsaProgressData[menu].alternateTransportation.rideProgramID = responseData.alternateTransportation.rideProgramID;
            this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice = responseData.alternateTransportation.isVoucherAlreadyIssuedForDevice;
            this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated = responseData.alternateTransportation.isVoucherGenerated;
          }
          if (responseData.vehicle) {
            this.rsaProgressData[menu].step6.vin = responseData.vehicle.vin;
            this.rsaProgressData[menu].step6.year = typeof (responseData.vehicle.year) === "number" ? (responseData.vehicle.year ? parseInt(responseData.vehicle.year) : null) : responseData.vehicle.year;
            this.rsaProgressData[menu].step6.make = responseData.vehicle.make;
            this.rsaProgressData[menu].step6.makeValue = responseData.vehicle.make;
            this.rsaProgressData[menu].step6.model = responseData.vehicle.model;
            this.rsaProgressData[menu].step6.color = responseData.vehicle.color;
            this.rsaProgressData[menu].step6.mileage = responseData.vehicle.mileage;
            this.rsaProgressData[menu].step6.previousVinNumber = responseData.vehicle.vin;
            this.rsaProgressData[menu].scheduleService.licensePlate = responseData.vehicle.licensePlate;
            this.rsaProgressData[menu].step6.isTrailerRequired = (responseData.vehicle.isTrailerRequired).toString();
            this.rsaProgressData[menu].step6.vehicleType = responseData.vehicle.vehicleType;
          }

          if (responseData.serviceDetails && responseData.serviceDetails.comments) {
            // let highwayNote = 'Customer’s Direction is ';
            // let indexOfHighwayNote =  responseData.serviceDetails.comments[0].value.indexOf(highwayNote) - 2 ;
            // let removedHighwayComment = responseData.serviceDetails.comments[0].value.slice(0,indexOfHighwayNote);
            // this.rsaProgressData[menu].step7.q1 = (responseData.serviceDetails.comments[0].value) ? removedHighwayComment : "";
            this.rsaProgressData[menu].step7.q1 = responseData.serviceDetails.comments[0].value;
            if (this.rsaProgressData.step1.menu == "needtow_menu" && responseData.serviceDetails.comments[1].value) {
              this.rsaProgressData[menu].symptom.comments = responseData.serviceDetails.comments[1].value;
            }
          }
          if (responseData.additionalDetails) {
            if (menu === "battery_menu") {
              this.rsaProgressData[menu].step2.q2 = responseData.additionalDetails.jumpStart;
              this.rsaProgressData[menu].step2.q3 = responseData.additionalDetails.vehicleStop;
              this.rsaProgressData[menu].step2.q4 = responseData.additionalDetails.vehicleType;              
            } else if (menu === "lockout_menu") {
              this.rsaProgressData[menu].step2.isVehicleOn = responseData.additionalDetails.isVehicleOn;
              this.rsaProgressData[menu].step2.keysLocated = responseData.additionalDetails.keysLocated;
              this.rsaProgressData[menu].step2.trunkOptions = responseData.additionalDetails.trunkOptions;
              this.rsaProgressData[menu].step2.q3 = responseData.additionalDetails.lockedInside;
            } else if (menu === "needtow_menu"){
              this.rsaProgressData[menu].scheduleService.isSafeAtHome = responseData.additionalDetails.customerAtHome;
              this.rsaProgressData[menu].step2.isTowRecommend = responseData.additionalDetails.towScheduleQuestion;
              this.rsaProgressData[menu].step2.vehicleNeutral = responseData.additionalDetails.vehicleNeutral;
              this.rsaProgressData[menu].step2.fourWheelDrive = responseData.additionalDetails.fourWheelDrive;
            } else if (menu === "outfuel_menu") {
              this.rsaProgressData[menu].step2.q2 = responseData.additionalDetails.fuelType;
            } else if (menu === "vehiclestuck_menu") {
              this.rsaProgressData[menu].step2.q2 = responseData.additionalDetails.distance;
              this.rsaProgressData[menu].step2.q3 = responseData.additionalDetails.driveable;
              this.rsaProgressData[menu].step2.q5 = responseData.additionalDetails.tiresStuckOn;
            } else if (menu === "flat_menu") {
              this.rsaProgressData[menu].step2.q3 = responseData.additionalDetails.spareTire;
              this.rsaProgressData[menu].step2.flatTireTrailer = responseData.additionalDetails.flatTireTrailer;
              this.rsaProgressData[menu].step2.noOfFlatTiresTrailer = responseData.additionalDetails.noOfFlatTiresTrailer;
              if (responseData.additionalDetails.tireSelected) {
                this.rsaProgressData[menu].step2.tireSelected = responseData.additionalDetails.tireSelected;
                responseData.additionalDetails.tireSelected.forEach((each, i) => {
                  if (each == "front-driver")
                    this.rsaProgressData[menu].step2.frontDriver = true;
                  if (each == "front-passenger")
                    this.rsaProgressData[menu].step2.frontPassenger = true;
                  if (each == "rear-driver")
                    this.rsaProgressData[menu].step2.rearDriver = true;
                  if (each == "rear-passenger")
                    this.rsaProgressData[menu].step2.rearPassenger = true;
                  if (i >= 0) {
                    this.rsaProgressData[menu].step2.tire = true;
                  }
                });
              }
            }

            this.rsaProgressData[menu].step4.skipTow = responseData.additionalDetails.isSkipTow;
            this.rsaProgressData[menu].step3.skipLocation = responseData.additionalDetails.isSkipLocation;
            this.rsaProgressData[menu].step5.skipPolicy = responseData.additionalDetails.isSkipPolicy;
            this.rsaProgressData[menu].step6.skipVin = responseData.additionalDetails.isSkipVin;
            this.rsaProgressData[menu].step4.isTowNeeded = responseData.additionalDetails.isTowNeeded;
            this.rsaProgressData[menu].step4.isCustomAddress = responseData.additionalDetails.isCustomAddress;            

            this.rsaProgressData[menu].step4.triggeragenttext = responseData.additionalDetails.isSkipTow;
            this.rsaProgressData[menu].step4.agentcheckbox = responseData.additionalDetails.isSkipTow;
            this.rsaProgressData[menu].step6.vehicleinformation = responseData.additionalDetails.vehicleListFromPolicy ? responseData.additionalDetails.vehicleListFromPolicy : "";
            this.rsaProgressData[menu].step5.isPolicyValid = responseData.additionalDetails.isPolicySearchSuccess;
            this.rsaProgressData[menu].step6.isVinFound = responseData.additionalDetails.isVinSearchSuccess;
            this.rsaProgressData[menu].step6.last4DigitVin = responseData.additionalDetails.vinSearch;
            this.rsaProgressData[menu].step6.otherVehicle = responseData.additionalDetails.isOtherVehicleSelected;
            this.rsaProgressData[menu].step6.previousLast4DigitVin = responseData.additionalDetails.vinSearch;
            this.rsaProgressData[menu].step2.isElectricVehicle = responseData.additionalDetails.isElectricVehicle;
            this.rsaProgressData[menu].step5.pmrNumber = responseData.additionalDetails.lastPolicyTried;
            this.rsaProgressData.profileSearchError = responseData.additionalDetails.isPolicySearchInvalid;
            this.rsaProgressData[menu].step6.vinDecoder = responseData.additionalDetails.vinDecoder;
            this.rsaProgressData.isEncaptchaSuccess = responseData.additionalDetails.isEncaptchaSuccess;
            if(responseData.additionalDetails && responseData.additionalDetails.payment){
              this.rsaProgressData.payment.isPaymentReqd = responseData.additionalDetails.payment.isPaymentReqd;
              this.rsaProgressData.payment.amountDue = responseData.additionalDetails.payment.amountDue;
              this.rsaProgressData.payment.customerWebLink = responseData.additionalDetails.payment.customerWebLink;
            }
            if (responseData.additionalDetails && responseData.additionalDetails.profileCollected) {
              this.rsaProgressData.profileCollected = responseData.additionalDetails.profileCollected;
            }
            if (responseData.additionalDetails.callType === "IVR") {
              this.rsaProgressData.audio_type = responseData.additionalDetails.ivrValue.toString();
              this.rsaProgressData.audioTypeFound = typeof (responseData.additionalDetails.ivrValue) === "number";
            }
            this.rsaProgressData.selectedTowDestination = responseData.additionalDetails.selectedTowObj ?responseData.additionalDetails.selectedTowObj : "";
          }
          // this.rsaProgressData.trackingIdFlags.isPhoneTapped = responseData.isTapOnPhone;
          this.rsaProgressData.trackingIdFlags.isGoldCall = responseData.isGoldCall;
          this.rsaProgressData.trackingIdFlags.isSilverCall = responseData.isSilverCall;
          this.rsaProgressData.step1.stepCount = responseData.stepId;
          this.rsaProgressData.trackingIdFlags.saveLiveTrackingDetails = responseData.isTrackingDetails;     
        }
        localStorage.setItem("rsaHelpRequest", null);

        return true;
      }
    } catch (e) {
      console.log("RsaHelpRequestParseError-JSON Object", e + " Object " + JSON.stringify(responseData));
    }
  }

  public setPhoneNumber(callBackNumber) {
    this.menuItems.forEach((menuItem) => {
      this.rsaProgressData[menuItem].step5.phonenumber = callBackNumber;
    });
    this.rsaProgressData.callbacknumber_fromurl = callBackNumber;
  }

  public setVdn(vdn) {
    this.rsaProgressData.query_strings.vdn = vdn;
  }

  public getdisablementReason() {
    let menu = this.rsaProgressData.step1.menu;
    let reasonValue = '';
    if (menu == "battery_menu") {
      if (this.rsaProgressData[menu].step2.q4 == "Electric") {
        reasonValue = environment.AppConfig.PROBLEMS[0].value;
      } else if (this.rsaProgressData[menu].step2.q2 == "yes" ) {
        reasonValue = environment.AppConfig.PROBLEMS[8].value;
      } else {
        reasonValue = environment.AppConfig.PROBLEMS[1].value;
      }
    } else if (menu == "flat_menu") {
      let flatTierCount = this.getFlatTireCount();
      if (flatTierCount == 1 && this.rsaProgressData[menu].step2.q3 == "yes") {
        reasonValue = environment.AppConfig.PROBLEMS[3].value;
      } else {
        // reasonValue = environment.AppConfig.PROBLEMS[2].value;
        reasonValue = environment.AppConfig.PROBLEMS[0].value;
      }
    } else if (menu == "lockout_menu") {
      reasonValue = environment.AppConfig.PROBLEMS[4].value;
    } else if (menu == "needtow_menu") {
      reasonValue = environment.AppConfig.PROBLEMS[0].value;
    } else if (menu == "outfuel_menu" && this.rsaProgressData.outfuel_menu.step2.q2 == "Electric") {
      reasonValue = environment.AppConfig.PROBLEMS[0].value;
    } else if (menu == "outfuel_menu") {
      reasonValue = environment.AppConfig.PROBLEMS[6].value;
    } else if (menu == "vehiclestuck_menu") {
      reasonValue = environment.AppConfig.PROBLEMS[5].value;
    }
    return reasonValue;
  }

  getAllAnswers() {
    var comments_msg = "|";
    let menu = this.rsaProgressData.step1.menu;
    if (this.rsaProgressData[menu].scheduleService.q1) {
      comments_msg += this.appDataConfig.rsa_step2_q1 + "|";
      comments_msg += this.rsaProgressData[menu].scheduleService.q1 + "|";
    }

    if (this.rsaProgressData[menu].step2.q2) {
      if (menu == "battery_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q2 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q2 + "|";
      }
      else if (menu == "lockout_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q4 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q2 + "|";
      }
      else if (menu == "outfuel_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q6 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q2 + "|";
      }
      else if (menu == "vehiclestuck_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q7 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q2 + " Feet (approximate)|";
      }

    }

    if (this.rsaProgressData[menu].step2.q3) {

      if (menu == "flat_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q3 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q3 + "|";
        if (this.rsaProgressData[menu].step2.frontDriver) {
          comments_msg += "frontDriver" + "|Yes|";
        }
        if (this.rsaProgressData[menu].step2.frontPassenger) {
          comments_msg += "frontPassenger" + "|Yes|";
        }
        if (this.rsaProgressData[menu].step2.rearDriver) {
          comments_msg += "rearDriver" + "|Yes|";
        }
        if (this.rsaProgressData[menu].step2.rearPassenger) {
          comments_msg += "rearPassenger" + "|Yes|";
        }
      } else if (menu == "lockout_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q5 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q3 + "|";
      } else if (menu == "vehiclestuck_menu") {
        comments_msg += this.appDataConfig.rsa_step2_q8 + "|";
        comments_msg += this.rsaProgressData[menu].step2.q3 + "|";
      }
    }

    if (this.rsaProgressData[menu].step3.skipLocation) {
      comments_msg += "Skipping Location" + "|Yes|";
    }

    if (this.rsaProgressData[menu].step4.skipTow) {
      comments_msg += "Skipping Tow Location" + "|Yes|";
    }

    if (this.rsaProgressData[menu].step5.skipPolicy) {
      comments_msg += "Skipping Policy" + "|Yes|";
    }

    if (this.rsaProgressData[menu].step6.skipVin) {
      comments_msg += "Skipping VIN" + "|Yes|";
    }

    if (this.rsaProgressData[menu].step6.othercolor) {
      comments_msg += "Other Color" + "|" + this.rsaProgressData[menu].step6.othercolor + "|";
    }


    return comments_msg;

  }

  public getSkipStatus() {
    let menu = this.rsaProgressData.step1.menu;
    var skipStatus = false;
    if (menu && menu != "accident_menu" && menu != "dontknow_menu") {
      if (this.rsaProgressData[menu].step3.skipLocation || (!this.rsaProgressData[menu].step3.skipLocation && !this.rsaProgressData[menu].step3.q1)) {
        skipStatus = true;
      }

      if ((this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle))
        || (!this.rsaProgressData[menu].step4.skipTow && !this.rsaProgressData[menu].step4.towaddress &&
          (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle))) {
        skipStatus = true;
      }

      if (this.rsaProgressData[menu].step5.skipPolicy || (!this.rsaProgressData[menu].step5.skipPolicy && !(this.rsaProgressData[menu].step5.firstname &&
        this.rsaProgressData[menu].step5.lastname &&
        this.rsaProgressData[menu].step5.phonenumber))) {
        //skipStatus = true;
      }
      //point to ANY if vin is skipped or VIN is not selected from the list or vin details are not provided
      if (this.rsaProgressData[menu].step6.skipVin || (!this.rsaProgressData[menu].step6.skipVin && !((this.rsaProgressData[menu].step6.year && this.rsaProgressData[menu].step6.makeValue && this.rsaProgressData[menu].step6.model && this.rsaProgressData[menu].step6.color) || this.rsaProgressData[menu].step6.isVinFound)) || this.rsaProgressData.trackingIdFlags.trackingOtherVinDetails) {
        skipStatus = true;
      }
    }
    return skipStatus;
  }

  public getSkipStatusForLog() {
    let menu = this.rsaProgressData.step1.menu;
    if (menu) {
      var skipStatus = false; this.skippedDl = false; this.skippedPolicy = false; this.skippedTD = false; this.skippedVin = false;
      if (menu && menu != "accident_menu" && menu != "dontknow_menu" && this.rsaProgressData[menu]) {
        if (this.rsaProgressData[menu].step3.skipLocation || (!this.rsaProgressData[menu].step3.skipLocation && !this.rsaProgressData[menu].step3.q1)) {
          skipStatus = true;
          this.skippedDl = true;
        }

        if ((this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle))
          || (!this.rsaProgressData[menu].step4.skipTow && !this.rsaProgressData[menu].step4.towaddress &&
            (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle))) {
          skipStatus = true;
          this.skippedTD = true;
        }
          if (this.rsaProgressData[menu].step5.skipPolicy || (!this.rsaProgressData[menu].step5.skipPolicy && !(this.rsaProgressData[menu].step5.firstname &&
            this.rsaProgressData[menu].step5.phonenumber))) {
            skipStatus = true;
             this.skippedPolicy = true;
          }

        if (this.rsaProgressData[menu].step6.skipVin || (!this.rsaProgressData[menu].step6.skipVin 
          && !((this.rsaProgressData[menu].step6.year && this.rsaProgressData[menu].step6.makeValue 
            && this.rsaProgressData[menu].step6.model && this.rsaProgressData[menu].step6.color) 
            || this.rsaProgressData[menu].step6.isVinFound)) || this.rsaProgressData.trackingIdFlags.trackingOtherVinDetails) {
          skipStatus = true;
          this.skippedVin = true;
        }

      } else if (menu == "accident_menu") {
        skipStatus = true;
      }
      return skipStatus;
    } else {
      return true;
    }
  }

  public getSubmitObject() {
    let menu = this.rsaProgressData.step1.menu;
    if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu") {
      let address = (this.rsaProgressData[menu].step3.q1).split(",");
      let addrStreet = null, addrCity = null, addrState = null, addrCountry = null, zipDetails = null, stateDetails;
      if(!this.rsaProgressData[menu].step3.skipLocation) {
        if(this.rsaProgressData.address_object.streetAddress != "" || this.rsaProgressData.address_object.city != ""
        || this.rsaProgressData.address_object.state != "" || this.rsaProgressData.address_object.country != "" 
        || this.rsaProgressData.address_object.postalcode != ""){
          addrStreet = this.rsaProgressData.address_object.streetAddress;
          addrCity = this.rsaProgressData.address_object.city;
          addrState = this.rsaProgressData.address_object.state;
          addrCountry = this.rsaProgressData.address_object.country;
          zipDetails = this.rsaProgressData.address_object.postalcode;
        }
        else{
        if (address.length == 4) {
          stateDetails = (address[2]).trim().split(" ");
          addrStreet = address[0].trim(), addrCity = address[1].trim(), addrState = stateDetails[0].trim(), addrCountry = address[3].trim();
          if (stateDetails[1] != "") {
            zipDetails = stateDetails[1];
          }
        } else if (address.length == 3) {
          stateDetails = (address[1]).trim().split(" ");
          if (stateDetails[1] != "") {
            zipDetails = stateDetails[1];
          }
          addrCity = address[0].trim(), addrState = stateDetails[0].trim(), addrCountry = address[2].trim();
        } else if (address.length > 4) {
          stateDetails = (address[address.length - 2]).trim().split(" ");
          addrStreet = address[0].trim(), addrCity = address[address.length - 3].trim(), addrState = stateDetails[0].trim(), addrCountry = address[address.length - 1].trim();
          if (stateDetails[1] != "") {
            zipDetails = stateDetails[1];
          }
        }
      }
      }       
    
      let disablementReason = this.getdisablementReason();
      //let appFlowComments = this.getAllAnswers();
      let appFlowComments = "";
      let addtitionalComments = ""
      if (menu == 'lockout_menu') {
        if (this.rsaProgressData[menu].step2) {
          if (this.rsaProgressData[menu].step2.q3 == 'yes')
            addtitionalComments = '***A Person or Animal is locked inside the vehicle.***';
          // debugger;
        }
      }
      let fuelType;
      if (menu == "outfuel_menu") {
        fuelType = this.rsaProgressData[menu].step2.q2;
      }
      if (menu == "battery_menu") {
        fuelType = this.rsaProgressData[menu].step2.q4;
      }
    //  let fuelType = (menu == "outfuel_menu") ? this.rsaProgressData[menu].step2.q2 : null;
      let latitude = this.rsaProgressData[menu].step3.latitude;
      let longitude = this.rsaProgressData[menu].step3.longitude;
      let emailId = (this.rsaProgressData[menu].step5.email) ? this.rsaProgressData[menu].step5.email : null;
      latitude = (latitude == "" || !latitude) ? 0 : latitude;
      longitude = (longitude == "" || !longitude) ? 0 : longitude;
      if (this.rsaProgressData[menu].step3.skipLocation) {
        latitude = 0;
        longitude = 0;
      }
      let towName, towType, towAddress, towStateDetails, towLat, towLong,towId;
      let towAddrStreet = "NA", towAddrCity = null, towAddrState = null, towAddrCountry = null, towzipDetails = null;
      if (this.rsaProgressData[menu].step4.towaddress && !this.rsaProgressData[menu].step4.skipTow) {
        // Tow DestinationBusinessName
        if (this.rsaProgressData[menu].step4.isCustomAddress) {
          towName = 'Unknown';
        } else {
          towId=this.rsaProgressData.selectedTowDestination.id;
          towName = this.rsaProgressData.selectedTowDestination.name;
        }
        // Tow DestinationType
        if (!this.rsaProgressData[menu].step4.isCustomAddress && this.appDataConfig.type == 'INS'
          && this.rsaProgressData[menu].step4.isTowNeeded && !this.rsaProgressData[menu].step2.isElectricVehicle) {
          towType = 'Repair Facility';
        } else if (!this.rsaProgressData[menu].step4.isCustomAddress && this.appDataConfig.type == 'AUTO'
          && this.rsaProgressData[menu].step4.isTowNeeded && !this.rsaProgressData[menu].step2.isElectricVehicle) {
          towType = 'Dealership';
        } else if(this.rsaProgressData[menu].step4.isCustomAddress && this.appDataConfig.type == 'AUTO'
        && this.rsaProgressData[menu].step4.isTowNeeded && !this.rsaProgressData[menu].step2.isElectricVehicle) {
          towType = 'Dealership';
        }
        else {
          towType = 'Repair Facility';
        }

        towType = this.rsaProgressData.selectedTowDestination.locationType == 'Repair Facility' ? 'Repair Facility' : towType;
      
        towAddress = (this.rsaProgressData[menu].step4.towaddress).split(",");
        
        if(this.rsaProgressData.tow_address_object.streetAddress != "" || this.rsaProgressData.tow_address_object.city != ""
        || this.rsaProgressData.tow_address_object.state != "" || this.rsaProgressData.tow_address_object.country != "" 
        || this.rsaProgressData.tow_address_object.postalcode != ""){
          towAddrStreet = this.rsaProgressData.tow_address_object.streetAddress;
          towAddrCity = this.rsaProgressData.tow_address_object.city;
          towAddrState = this.rsaProgressData.tow_address_object.state;
          towAddrCountry = this.rsaProgressData.tow_address_object.country;
          towzipDetails = this.rsaProgressData.tow_address_object.postalcode;
        } else {
        if (towAddress.length == 4) {
          towStateDetails = (towAddress[2]).trim().split(" ");
          towAddrStreet = towAddress[0].trim(), towAddrCity = towAddress[1].trim(), towAddrState = towStateDetails[0].trim(), towAddrCountry = towAddress[3].trim();
          if (towStateDetails[1] != "") {
            towzipDetails = towStateDetails[1];
          }

        } else if (towAddress.length == 3) {
          towStateDetails = (towAddress[1]).trim().split(" ");
          if (towStateDetails[1] != "") {
            towzipDetails = towStateDetails[1];
          }
          towAddrCity = towAddress[0].trim(), towAddrState = towStateDetails[0].trim(), towAddrCountry = towAddress[2].trim();
        } else if (towAddress.length > 4) {
          towStateDetails = (towAddress[towAddress.length - 2]).trim().split(" ");
          towAddrStreet = towAddress[0].trim(), towAddrCity = towAddress[towAddress.length - 3].trim(), towAddrState = towStateDetails[0].trim(), towAddrCountry = towAddress[towAddress.length - 1].trim();
          if (towStateDetails[1] != "") {
            towzipDetails = towStateDetails[1];
          }
        }
      }
        // towStateDetails = (towAddress[2]) ? (towAddress[2]).trim().split(" ") : '';
        // towzipDetails = (towStateDetails[1] != "") ? towStateDetails[1] : "";
        towLat = (this.rsaProgressData[menu].step4.latitude) ? this.rsaProgressData[menu].step4.latitude : 0;
        towLong = (this.rsaProgressData[menu].step4.longitude) ? this.rsaProgressData[menu].step4.longitude : 0;
      }
      let makeValue;
      if (this.rsaProgressData[menu].step6.make) {
        var makeObj = this.rsaProgressData[menu].step6.make;
        if (makeObj.make) {
          makeValue = makeObj.make;
        }else if(makeObj){
          makeValue = makeObj;
        }else if (this.rsaProgressData[menu].step6.makeValue.name) {
          makeValue = this.rsaProgressData[menu].step6.makeValue.name;
        }else if (this.rsaProgressData[menu].step6.makeValue) {
          makeValue = this.rsaProgressData[menu].step6.makeValue; 
        }
      }

      let submitObj: any;
      let preference: any;

      if (this.rsaProgressData[menu].step5.preference == "0") 
        preference = "SMS";
      else if(this.rsaProgressData[menu].step5.preference == "1")
        preference = "EMAIL";
      else if(this.rsaProgressData[menu].step5.preference == "2")
        preference = "NONE";
      else 
        preference = "";

        let serviceHour : any;
        let warrantyPriod: any;
        let rating: any;
        let certificationFlag: any;
        let notes: any;
        let offers: any;
        let miles: any;
        serviceHour = this.rsaProgressData[menu].step4.serviceHour;
        warrantyPriod = this.rsaProgressData[menu].step4.warrantyPeriod;
        rating = this.rsaProgressData[menu].step4.rating;
        certificationFlag = this.rsaProgressData[menu].step4.certificationFlag;
        notes = this.rsaProgressData[menu].step4.notes;
        offers = this.rsaProgressData[menu].step4.offers;
        miles = this.rsaProgressData[menu].step4.miles;

        this.rsaProgressData[menu].step3.zip = zipDetails;
        if(this.appDataConfig.isDisplayTowReasonReqd && menu == "needtow_menu" && this.rsaProgressData[menu].symptom.comments != ""){
          addtitionalComments = this.rsaProgressData[menu].symptom.comments;
        }

      if (this.appDataConfig.type == 'DIV') {

        if (this.appDataConfig.domain == 'payless') {
          submitObj = {
            "requestor": {
              "name": {
                "firstName": this.rsaProgressData[menu].step5.firstname,
                "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
              }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
              // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
            }, "disablementLocation": {
              "address": {
                "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                "country": addrCountry, "zip": zipDetails
              }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType, 
                 "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                 "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                 "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional,"geographicalCoordinates": {
                "latitude": latitude, "longitude": longitude},
                "garageDetails":
                {
                  "level":this.rsaProgressData[menu].step3.level,
                   "section":this.rsaProgressData[menu].step3.section,
                   "clearance":
                   {
                   "feet":this.rsaProgressData[menu].step3.feet,
                   "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                  }
                }
            }, "vehicle": {
              "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
              "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
              "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
            },
            "profile": { "membershipNumber": null, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName ? this.rsaProgressData[menu].step5.profFirstName : this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName? this.rsaProgressData[menu].step5.profLastName: this.rsaProgressData[menu].step5.lastname }, "contactInfo": { "address": { "streetAddress1": "NA", "city": "NA", "state": "MA", "country": "USA", "zip": null }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Home" } },
            "notificationPreferences": {
              "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
              "primaryPhoneNumber": null
            }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] },
            "customAttributes": [{ "key": "RA", "value": this.rsaProgressData[menu].step5.ranumber }, { "key": "MVA", "value": this.rsaProgressData[menu].step5.mvanumber }, { "key": "Coverage", "value": this.rsaProgressData[menu].step5.coverageamount }, { "key": "RentalLocationCode", "value": this.rsaProgressData[menu].step5.rentalcode }, { "key": "AgentName", "value": this.rsaProgressData[menu].step5.agentname }, { "key": "AgentPhoneNumber", "value": this.rsaProgressData[menu].step5.agentnumber }]
            ,"PlatformId" : this.platformId ? this.platformId : null,
            "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
            'deviceInfo': {
              'platform': navigator.platform,
              'userAgent': navigator.userAgent,
              "deviceUID": new DeviceUUID().get()
            },
            "alternateTransportation":{
              "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
              "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
              "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
              "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
              "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
              "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
              "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
              "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
              "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
            }
          }

          if (this.appDataConfig.domain == 'payless' && this.rsaProgressData[menu].step4.towaddress && !this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle)) {
            submitObj = {
              "requestor": {
                "name": {
                  "firstName": this.rsaProgressData[menu].step5.firstname,
                  "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
                }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
                // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
              }, "disablementLocation": {
                "address": {
                  "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                  "country": addrCountry, "zip": zipDetails
                }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType, 
                   "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                   "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation,  
                   "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional,"geographicalCoordinates": {
                  "latitude": latitude, "longitude": longitude},
                  "garageDetails":
                  {
                    "level":this.rsaProgressData[menu].step3.level,
                     "section":this.rsaProgressData[menu].step3.section,
                     "clearance":
                     {
                     "feet":this.rsaProgressData[menu].step3.feet,
                     "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                    }
                  }
              }, "towDestination": { "destinationBusinessName": towName, "destinationType": towType, "towDestinationId":towId,"address": { "streetAddress1": towAddrStreet, "city": towAddrCity, "state": towAddrState, "country": "USA", "zip": towzipDetails }, "geographicalCoordinates": { "latitude": towLat, "longitude": towLong }, "serviceHour": serviceHour, "warrantyPeriod":warrantyPriod,"rating":rating, "certificationFlag":certificationFlag, "notes" : notes, "offers": offers, "miles": miles }, "vehicle": {
                "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
                "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
                "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
              },
              "profile": { "membershipNumber": null, "name": { "firstName":this.rsaProgressData[menu].step5.profFirstName? this.rsaProgressData[menu].step5.profFirstName : this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName ? this.rsaProgressData[menu].step5.profLastName : this.rsaProgressData[menu].step5.lastname }, "contactInfo": { "address": { "streetAddress1": "NA", "city": "NA", "state": "MA", "country": "USA", "zip": null }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Home" } },
              "notificationPreferences": {
                "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
                "primaryPhoneNumber": null
              }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] },
              "customAttributes": [{ "key": "RA", "value": this.rsaProgressData[menu].step5.ranumber }, { "key": "MVA", "value": this.rsaProgressData[menu].step5.mvanumber }, { "key": "Coverage", "value": this.rsaProgressData[menu].step5.coverageamount }, { "key": "RentalLocationCode", "value": this.rsaProgressData[menu].step5.rentalcode }, { "key": "AgentName", "value": this.rsaProgressData[menu].step5.agentname }, { "key": "AgentPhoneNumber", "value": this.rsaProgressData[menu].step5.agentnumber }]
              ,"PlatformId" : this.platformId ? this.platformId : null,
              "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
              'deviceInfo': {
                'platform': navigator.platform,
                'userAgent': navigator.userAgent,
                "deviceUID": new DeviceUUID().get()
              },
              "alternateTransportation":{
                "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
                "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
                "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
                "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
                "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
                "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
                "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
                "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
                "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
              }
            }

          }
        } else if (this.appDataConfig.domain == 'zipcar') {
          submitObj = {
            "requestor": {
              "name": {
                "firstName": this.rsaProgressData[menu].step5.firstname,
                "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
              },"callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
              // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
            }, "disablementLocation": {
              "address": {
                "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                "country": addrCountry, "zip": zipDetails
              }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType,
                 "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                 "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                 "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
                "latitude": latitude, "longitude": longitude},
                "garageDetails":
                {
                  "level":this.rsaProgressData[menu].step3.level,
                   "section":this.rsaProgressData[menu].step3.section,
                   "clearance":
                   {
                   "feet":this.rsaProgressData[menu].step3.feet,
                   "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                  }
                }
            }, "vehicle": {
              "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
              "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
              "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
            },
            "profile": { "membershipNumber": null, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName ? this.rsaProgressData[menu].step5.profFirstName : this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName? this.rsaProgressData[menu].step5.profLastName : this.rsaProgressData[menu].step5.lastname }, "contactInfo": { "address": { "streetAddress1": "NA", "city": "NA", "state": "MA", "country": "USA", "zip": null }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Home" } },
            "notificationPreferences": {
              "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
              "primaryPhoneNumber": null
            }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] },
            "customAttributes": [{ "key": "MVA", "value": this.rsaProgressData[menu].step5.mvanumber }, { "key": "AgentName", "value": this.rsaProgressData[menu].step5.agentname }, { "key": "AgentPhoneNumber", "value": this.rsaProgressData[menu].step5.agentnumber }]
            ,"PlatformId" : this.platformId ? this.platformId : null,
            "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
            'deviceInfo': {
              'platform': navigator.platform,
              'userAgent': navigator.userAgent,
              "deviceUID": new DeviceUUID().get()
            },
            "alternateTransportation":{
              "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
              "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
              "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
              "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
              "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
              "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
              "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
              "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
              "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
            }
          }

          if (this.appDataConfig.domain == 'zipcar' && this.rsaProgressData[menu].step4.towaddress && !this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle)) {
            submitObj = {
              "requestor": {
                "name": {
                  "firstName": this.rsaProgressData[menu].step5.firstname,
                  "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
                }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
                // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
              }, "disablementLocation": {
                "address": {
                  "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                  "country": addrCountry, "zip": zipDetails
                }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType, 
                   "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                   "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                   "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional,"geographicalCoordinates": {
                  "latitude": latitude, "longitude": longitude},
                  "garageDetails":
                  {
                    "level":this.rsaProgressData[menu].step3.level,
                     "section":this.rsaProgressData[menu].step3.section,
                     "clearance":
                     {
                     "feet":this.rsaProgressData[menu].step3.feet,
                     "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                    }
                  }
              }, "towDestination": { "destinationBusinessName": towName, "destinationType": towType,"towDestinationId":towId, "address": { "streetAddress1": towAddrStreet, "city": towAddrCity, "state": towAddrState, "country": "USA", "zip": towzipDetails }, "geographicalCoordinates": { "latitude": towLat, "longitude": towLong } , "serviceHour": serviceHour, "warrantyPeriod":warrantyPriod,"rating":rating, "certificationFlag":certificationFlag, "notes" : notes, "offers": offers, "miles": miles}, "vehicle": {
                "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
                "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
                "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
              },
              "profile": { "membershipNumber": null, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName ? this.rsaProgressData[menu].step5.profFirstName: this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName? this.rsaProgressData[menu].step5.profLastName: this.rsaProgressData[menu].step5.lastname }, "contactInfo": { "address": { "streetAddress1": "NA", "city": "NA", "state": "MA", "country": "USA", "zip": null }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Home" } },
              "notificationPreferences": {
                "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
                "primaryPhoneNumber": null
              }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] },
              "customAttributes": [{ "key": "MVA", "value": this.rsaProgressData[menu].step5.mvanumber }, { "key": "AgentName", "value": this.rsaProgressData[menu].step5.agentname }, { "key": "AgentPhoneNumber", "value": this.rsaProgressData[menu].step5.agentnumber }],
              'deviceInfo': {
                'platform': navigator.platform,
                'userAgent': navigator.userAgent,
                "deviceUID": new DeviceUUID().get()
              },
              "alternateTransportation":{
                "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
                "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
                "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
                "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
                "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
                "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
                "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
                "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
                "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
              }
            }

          }

        } else if (this.appDataConfig.domain == 'avis' || this.appDataConfig.domain == 'budget') {

          submitObj = {
            "requestor": {
              "name": {
                "firstName": this.rsaProgressData[menu].step5.firstname,
                "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
              }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
              // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
            }, "disablementLocation": {
              "address": {
                "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                "country": addrCountry, "zip": zipDetails
              }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType,
                 "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                 "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                 "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
                "latitude": latitude, "longitude": longitude},
                "garageDetails":
                {
                  "level":this.rsaProgressData[menu].step3.level,
                   "section":this.rsaProgressData[menu].step3.section,
                   "clearance":
                   {
                   "feet":this.rsaProgressData[menu].step3.feet,
                   "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                  }
                }
            }, "vehicle": {
              "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
              "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
              "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
            },
            "profile": { "membershipNumber": null, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName? this.rsaProgressData[menu].step5.profFirstName : this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName ? this.rsaProgressData[menu].step5.profLastName: this.rsaProgressData[menu].step5.lastname }, "contactInfo": { "address": { "streetAddress1": "NA", "city": "NA", "state": "MA", "country": "USA", "zip": null }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Home" } },
            "notificationPreferences": {
              "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
              "primaryPhoneNumber": null
            }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] },
            "customAttributes": [{ "key": "RA", "value": this.rsaProgressData[menu].step5.ranumber }, { "key": "MVA", "value": this.rsaProgressData[menu].step5.mvanumber }]
            ,"PlatformId" : this.platformId ? this.platformId : null,
            "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
            'deviceInfo': {
              'platform': navigator.platform,
              'userAgent': navigator.userAgent,
              "deviceUID": new DeviceUUID().get()
            },
            "alternateTransportation":{
              "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
              "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
              "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
              "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
              "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
              "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
              "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
              "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
              "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
            }
          }

          if ((this.appDataConfig.domain == 'avis' || this.appDataConfig.domain == 'budget') && this.rsaProgressData[menu].step4.towaddress && !this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle)) {
            submitObj = {
              "requestor": {
                "name": {
                  "firstName": this.rsaProgressData[menu].step5.firstname,
                  "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
                }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
                // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
              }, "disablementLocation": {
                "address": {
                  "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                  "country": addrCountry, "zip": zipDetails
                }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType,   
                   "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                   "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                   "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
                  "latitude": latitude, "longitude": longitude
                },
                "garageDetails":
                {
                  "level":this.rsaProgressData[menu].step3.level,
                   "section":this.rsaProgressData[menu].step3.section,
                   "clearance":
                   {
                   "feet":this.rsaProgressData[menu].step3.feet,
                   "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                  }
                }
              }, "towDestination": { "destinationBusinessName": towName, "destinationType": towType,"towDestinationId":towId, "address": { "streetAddress1": towAddrStreet, "city": towAddrCity, "state": towAddrState, "country": "USA", "zip": towzipDetails }, "geographicalCoordinates": { "latitude": towLat, "longitude": towLong }, "serviceHour": serviceHour, "warrantyPeriod":warrantyPriod,"rating":rating, "certificationFlag":certificationFlag, "notes" : notes, "offers": offers, "miles": miles}, "vehicle": {
                "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
                "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
                "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
              },
              "profile": { "membershipNumber": null, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName? this.rsaProgressData[menu].step5.profFirstName: this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName? this.rsaProgressData[menu].step5.profLastName: this.rsaProgressData[menu].step5.lastname }, "contactInfo": { "address": { "streetAddress1": "NA", "city": "NA", "state": "MA", "country": "USA", "zip": null }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Home" } },
              "notificationPreferences": {
                "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
                "primaryPhoneNumber": null
              }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] },
              "customAttributes": [{ "key": "RA", "value": this.rsaProgressData[menu].step5.ranumber }, { "key": "MVA", "value": this.rsaProgressData[menu].step5.mvanumber }]
              ,"PlatformId" : this.platformId ? this.platformId : null,
              "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
              'deviceInfo': {
                'platform': navigator.platform,
                'userAgent': navigator.userAgent,
                "deviceUID": new DeviceUUID().get()
              },
              "alternateTransportation":{
                "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
                "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
                "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
                "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
                "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
                "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
                "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
                "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
                "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
              }
            }

          }


        }


      } else if (this.appDataConfig.type == 'INS') {
        submitObj = {
          "requestor": {
            "name": {
              "firstName": this.rsaProgressData[menu].step5.firstname,
              "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
            }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
            // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
          }, "disablementLocation": {
            "address": {
              "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
              "country": addrCountry, "zip": zipDetails
            }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType, 
               "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
               "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
               "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
              "latitude": latitude, "longitude": longitude
            },
            "garageDetails":
            {
              "level":this.rsaProgressData[menu].step3.level,
               "section":this.rsaProgressData[menu].step3.section,
               "clearance":
               {
               "feet":this.rsaProgressData[menu].step3.feet,
               "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
              }
            }
          },
          "profile": { "insurancePolicyNumber": this.rsaProgressData[menu].step5.policynumber, "zipCode": this.rsaProgressData[menu].step5.zipCode, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName?this.rsaProgressData[menu].step5.profFirstName: this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName? this.rsaProgressData[menu].step5.profLastName: this.rsaProgressData[menu].step5.lastname, "middleInitial": null }, "contactInfo": { "address": { "streetAddress1": address[0], "city": addrCity, "state": addrState, "country": addrCountry, "zip": zipDetails }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Mobile" } },

          "vehicle": {
            "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
            "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
            "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
          },
          "notificationPreferences": {
            "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
            "primaryPhoneNumber": null
          }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] }
          , "programSubCode": (this.appDataConfig.programcode== 'PRG' && this.rsaProgressData.programsubcode) ? this.rsaProgressData.programsubcode : null,
          "PlatformId" : this.platformId ? this.platformId : null,
          "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
          'deviceInfo': {
            'platform': navigator.platform,
            'userAgent': navigator.userAgent,
            "deviceUID": new DeviceUUID().get()
          },
          "alternateTransportation":{
            "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
            "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
            "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
            "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
            "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
            "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
            "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
            "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
            "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
          }
        }

        if (this.rsaProgressData[menu].step4.towaddress && !this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle)) {

          submitObj = {
            "requestor": {
              "name": {
                "firstName": this.rsaProgressData[menu].step5.firstname,
                "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
              }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
              // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
            }, "disablementLocation": {
              "address": {
                "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                "country": addrCountry, "zip": zipDetails
              }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType, 
                 "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                 "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                 "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
                "latitude": latitude, "longitude": longitude               
              },
              "garageDetails":
              {
                "level":this.rsaProgressData[menu].step3.level,
                 "section":this.rsaProgressData[menu].step3.section,
                 "clearance":
                 {
                 "feet":this.rsaProgressData[menu].step3.feet,
                 "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                }
              }
            },
            "profile": { "insurancePolicyNumber": this.rsaProgressData[menu].step5.policynumber, "zipCode": this.rsaProgressData[menu].step5.zipCode, "name": { "firstName": this.rsaProgressData[menu].step5.profFirstName? this.rsaProgressData[menu].step5.profFirstName :this.rsaProgressData[menu].step5.firstname, "lastName": this.rsaProgressData[menu].step5.profLastName? this.rsaProgressData[menu].step5.profLastName: this.rsaProgressData[menu].step5.lastname, "middleInitial": null }, "contactInfo": { "address": { "streetAddress1": addrStreet, "city": addrCity, "state": addrState, "country": addrCountry, "zip": zipDetails }, "addressType": "Home", "phoneNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl, "phoneType": "Mobile" } },
            "towDestination": { "destinationBusinessName": towName, "destinationType": towType,"towDestinationId":towId, "address": { "streetAddress1": towAddrStreet, "city": towAddrCity, "state": towAddrState, "country": "USA", "zip": towzipDetails }, "geographicalCoordinates": { "latitude": towLat, "longitude": towLong }, "serviceHour": serviceHour, "warrantyPeriod":warrantyPriod,"rating":rating, "certificationFlag":certificationFlag, "notes" : notes, "offers": offers, "miles": miles }, "vehicle": {
              "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
              "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
              "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
            },
            "notificationPreferences": {
              "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
              "primaryPhoneNumber": null
            }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] }
            , "programSubCode": (this.appDataConfig.programcode == 'PRG' && this.rsaProgressData.programsubcode) ? this.rsaProgressData.programsubcode : null
            ,"PlatformId" : this.platformId ? this.platformId : null,
            "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
            'deviceInfo': {
              'platform': navigator.platform,
              'userAgent': navigator.userAgent,
              "deviceUID": new DeviceUUID().get()
            },
            "alternateTransportation":{
              "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
              "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
              "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
              "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
              "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
              "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
              "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
              "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
              "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
            }
          }
        }


      } else {

        submitObj = {
          "requestor": {
            "name": {
              "firstName": this.rsaProgressData[menu].step5.firstname,
              "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
            }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
            // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
          }, "disablementLocation": {
            "address": {
              "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
              "country": addrCountry, "zip": zipDetails
            }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType,
               "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
               "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
               "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
              "latitude": latitude, "longitude": longitude
            },"garageDetails":
            {
              "level":this.rsaProgressData[menu].step3.level,
               "section":this.rsaProgressData[menu].step3.section,
               "clearance":
               {
               "feet":this.rsaProgressData[menu].step3.feet,
               "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
              }
            }
          }, "vehicle": {
            "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
            "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
            "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,"vehicleType": this.rsaProgressData[menu].step6.vehicleType
          },
          "notificationPreferences": {
            "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
            "primaryPhoneNumber": null
          }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] }
          ,"PlatformId" : this.platformId ? this.platformId : null,
          "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
          'deviceInfo': {
            'platform': navigator.platform,
            'userAgent': navigator.userAgent,
            "deviceUID": new DeviceUUID().get()
          },
          "alternateTransportation":{
            "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
            "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
            "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
            "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
            "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
            "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
            "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
            "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
            "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
          }
        }

        if (this.rsaProgressData[menu].step4.towaddress && !this.rsaProgressData[menu].step4.skipTow && (this.rsaProgressData[menu].step4.isTowNeeded || this.rsaProgressData[menu].step2.isElectricVehicle)) {

          submitObj = {
            "requestor": {
              "name": {
                "firstName": this.rsaProgressData[menu].step5.firstname,
                "lastName": this.rsaProgressData[menu].step5.lastname, "middleInitial": ""
              }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl == "" ? this.rsaProgressData[menu].step5.phonenumber : this.rsaProgressData.callbacknumber_fromurl
              // "callbackNumber": this.rsaProgressData[menu].step5.phonenumber
            }, "disablementLocation": {
              "address": {
                "streetAddress1": addrStreet, "city": addrCity, "state": addrState,
                "country": addrCountry, "zip": zipDetails
              }, "customerAtLocation": this.rsaProgressData[menu].scheduleService.q1, "locationType": this.rsaProgressData[menu].step3.locationType, 
                 "direction": this.rsaProgressData[menu].step3.direction, "exit": this.rsaProgressData[menu].step3.exit, "garageHeight": this.rsaProgressData[menu].step3.garageHeight, 
                 "customerBlockingTraffic": this.rsaProgressData[menu].step3.blockTraffic, "customerInSafeLocation": this.rsaProgressData[menu].step3.safeLocation, 
                 "locationTypeAdditional": this.rsaProgressData[menu].step3.locationTypeAdditional, "geographicalCoordinates": {
                "latitude": latitude, "longitude": longitude
              },"garageDetails":
              {
                "level":this.rsaProgressData[menu].step3.level,
                 "section":this.rsaProgressData[menu].step3.section,
                 "clearance":
                 {
                 "feet":this.rsaProgressData[menu].step3.feet,
                 "inches":this.rsaProgressData[menu].step3.inches!=0?this.rsaProgressData[menu].step3.inches:null
                }
              }
            }, "towDestination": { "destinationBusinessName": towName, "destinationType": towType,"towDestinationId":towId, "address": { "streetAddress1": towAddrStreet, "city": towAddrCity, "state": towAddrState, "country": "USA", "zip": towzipDetails }, "geographicalCoordinates": { "latitude": towLat, "longitude": towLong }, "serviceHour": serviceHour, "warrantyPeriod":warrantyPriod,"rating":rating, "certificationFlag":certificationFlag, "notes" : notes, "offers": offers, "miles": miles }, "vehicle": {
              "make": makeValue, "model": this.rsaProgressData[menu].step6.model, "year": this.rsaProgressData[menu].step6.year ? this.rsaProgressData[menu].step6.year : null, "licensePlate": this.rsaProgressData[menu].scheduleService.licensePlate,
              "mileage": this.rsaProgressData[menu].step6.mileage ? this.rsaProgressData[menu].step6.mileage : null, "vin": this.rsaProgressData[menu].step6.vin,
              "color": this.rsaProgressData[menu].step6.color, "fuelType": fuelType, "isTrailerRequired": this.rsaProgressData[menu].step6.isTrailerRequired,
              "vehicleType": this.rsaProgressData[menu].step6.vehicleType
            },
            "notificationPreferences": {
              "preferredMode": preference, "emailId": emailId, "textMessageNumber": this.rsaProgressData[menu].step5.phonenumber,
              "primaryPhoneNumber": null
            }, "serviceDetails": { "disablementReason": disablementReason, "comments": [{ "value": this.rsaProgressData[menu].step7.q1 + appFlowComments }, { "value": addtitionalComments }] }
            ,"PlatformId" : this.platformId ? this.platformId : null,
            "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
            'deviceInfo': {
              'platform': navigator.platform,
              'userAgent': navigator.userAgent,
              "deviceUID": new DeviceUUID().get()
            },
            "alternateTransportation":{
              "isAlternateTransportPageOffered":this.rsaProgressData[menu].alternateTransportation.isAlternateTransportPageOffered,
              "isAlternateTransportOpted": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportOpted,
              "isAlternateTransportRequired": this.rsaProgressData[menu].alternateTransportation.isAlternateTransportRequired,
              "voucherBenefit": this.rsaProgressData[menu].alternateTransportation.voucherBenefit,
              "voucherExpiryInHrs" : this.rsaProgressData[menu].alternateTransportation.voucherExpiryInHrs,
              "voucherNoOfRides" : this.rsaProgressData[menu].alternateTransportation.voucherNoOfRides,
              "rideProgramID" : this.rsaProgressData[menu].alternateTransportation.rideProgramID,
              "isVoucherAlreadyIssuedForDevice":this.rsaProgressData[menu].alternateTransportation.isVoucherAlreadyIssuedForDevice, 
              "isVoucherGenerated": this.rsaProgressData[menu].alternateTransportation.isVoucherGenerated
            }
          }
        }
      }

      if (this.rsaProgressData[menu].scheduleService.scheduleDate != "" && this.rsaProgressData[menu].scheduleService.scheduleDate != null && this.rsaProgressData[menu].scheduleService.isScheduled) {
        submitObj.scheduleDetails = {
          "timeRange": this.rsaProgressData[menu].scheduleService.time,
          "utcOffset": moment().utcOffset(),
          "date": this.rsaProgressData[menu].scheduleService.scheduleDate
        }
        submitObj.isScheduled = true
      } else {
        submitObj.isScheduled = false
      }
      return submitObj;
    } else {
      let addtitionalComments = "";
      if (menu == "accident_menu") {
        addtitionalComments = 'Customer has selected the ACCIDENT option';
      }
      let submitObj = {
        "requestor": {
          "name": {
            "firstName": null,
            "lastName": null, "middleInitial": ""
          }, "callbackNumber": this.rsaProgressData.callbacknumber_fromurl
        }, "disablementLocation": {
          "address": {
            "streetAddress1": null, "city": null, "state": null,
            "country": null, "zip": null
          }, "customerAtLocation": null, "locationType": null, "direction": null, "exit": null, "garageHeight":null, "customerBlockingTraffic":null,
             "customerInSafeLocation": null, "locationTypeAdditional": null, "geographicalCoordinates": {
             "latitude": 0, "longitude": 0
          },
          "garageDetails":
              {
                "level":null,
                 "section":null,
                 "clearance":
                 {
                 "feet":0,
                 "inches":null
                }
              }
        }, "vehicle": {
          "make": null, "model": null, "year": null, "licensePlate": "",
          "mileage": null, "vin": null,
          "color": null, "fuelType": null, "isTrailerRequired": null, "vehicleType":""
        },
        "profile": { "membershipNumber": null, "zipCode":null, "name": { "firstName": null, "lastName": null }, "contactInfo": { "address": { "streetAddress1": null, "city": null, "state": null, "country": null, "zip": null }, "addressType": "Home", "phoneNumber": this.appDataConfig.programcode=="PRG" ? this.rsaProgressData.callbacknumber_fromurl : null, "phoneType": "Home" } },
        "notificationPreferences": {
          "preferredMode": "", "textMessageNumber": null,
          "primaryPhoneNumber": null
        }, "serviceDetails": { "disablementReason": null, "comments": [{ "value": "" }, { "value": addtitionalComments }] },
        "programSubCode": (this.appDataConfig.programcode == 'PRG' && this.rsaProgressData.programsubcode) ? this.rsaProgressData.programsubcode : null
        ,"PlatformId" : this.platformId ? this.platformId : null,
        "TransitionEndState" : this.transitionEndState ? this.transitionEndState : null,
        'deviceInfo': {
          'platform': navigator.platform,
          'userAgent': navigator.userAgent,
          "deviceUID": new DeviceUUID().get()
        },
        "alternateTransportation":{
          "isAlternateTransportPageOffered": null,
          "isAlternateTransportOpted": null,
          "isAlternateTransportRequired": this.appDataConfig.alternateTransportation && this.appDataConfig.alternateTransportation.isAlternateTransportRequired,
          "voucherBenefit": this.appDataConfig.alternateTransportation && this.appDataConfig.alternateTransportation.voucherBenefit,
          "voucherExpiryInHrs" : this.appDataConfig.alternateTransportation && this.appDataConfig.alternateTransportation.voucherExpiryInHrs,
          "voucherNoOfRides" : this.appDataConfig.alternateTransportation && this.appDataConfig.alternateTransportation.voucherNoOfRides,
          "rideProgramID" : this.appDataConfig.alternateTransportation && this.appDataConfig.alternateTransportation.rideProgramID,
          "isVoucherAlreadyIssuedForDevice":null, 
          "isVoucherGenerated": null
        }
      }
      return submitObj;
    }
  }

  /* Find Source System */
  public sourceSystemDecider() {
    let sourceSystem;
    let menu = this.rsaProgressData.step1.menu;
    // no need to handle the source system as swoop is not rely on it
    if (this.rsaProgressData.trackingIdFlags.hasCallBack) {
      if (null != this.platformId && this.platformId === "Avaya") {
        sourceSystem = "DO"
      } else { // IVR flow
        sourceSystem = this.liveCallStatus ? "VDC" : "DO";
      }
    } else if (this.rsaProgressData.step1.rsahelpRequestId) { // non-IVR & having rsahelpRequestId
      sourceSystem = "MobileApp_DO";
    }
    else { // non-IVR flow
      sourceSystem = "RSAHelp_DO";
    }
    return sourceSystem;
  }

  openDialog(type) {
    let message;
    if(this._translateService.currentLang =="en")
    message ="Your request was unsuccessful. Please submit again, or call " + this.appDataConfig.number + " for service.";
    else
    message ="Ihre Anfrage war nicht erfolgreich. Bitte nochmal absenden oder anrufen " + this.appDataConfig.number + " für den Service.";
    const dialogRef = this.dialog.open(RsaAlertDialogComponent, {
      width: "320px",
      closeOnNavigation: true,
      disableClose: true,
      data: { type: type, appDataConfig: this.appDataConfig, message: message}
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  /* Update RSAHelpRequest */

  public updateRsaHelpRequest(appURL, rasHelpRequestId, requestId, httpOptions) {
    let submitObj = this.getSubmitObject();
    if (submitObj) {
      submitObj.rsaRequestId = requestId;
      submitObj.rsahelpRequestId = rasHelpRequestId;
    }
    return this.http.put<any>(appURL, submitObj, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`updated RSA Help Request`)),
        catchError(this.handleError('updated RSA Help Request', []))
      );
  }

  public saveTrackingRSARequestClick() {
    //save tracking will work only when tracking id is availble
    this.rsaProgressData.pageRedirects = true;
    this.rsaProgressData.trackingIdFlags.isGoldCall = this.rsaProgressData.trackingIdFlags.isPhoneTapped ? false : !this.getSkipStatusForLog();
    this.rsaProgressData.trackingIdFlags.isSilverCall = this.rsaProgressData.trackingIdFlags.isPhoneTapped ? false : this.getSkipStatusForLog();
    this.saveTrackingRSARequest().subscribe((data) => {
      this.rsaProgressData.isCallSubmitted = data.isCallSubmitted;
      /* if (this.rsaProgressData.trackingIdFlags.hasCallBack && data.isCallSubmitted) {
        this.rsaProgressData.loadingScreen = true;
        this.submitRequest(true).subscribe(data => {
          this.autoSubmission(data);
        });
        // if (this.appDataConfig.isNgpClient || this.rsaProgressData.confirmedNGP) {
        //   this.submitNgpNonIvrFlow(true).subscribe(data => {
        //     this.autoSubmission(data);
        //   });
        // } else {
        //   this.submitRequest(true).subscribe(data => {
        //     this.autoSubmission(data);
        //   });
        // }
      } */
    });
  }

  autoSubmission(data): void {
    this.rsaProgressData.loadingScreen = false;
    if (data && data.rsaRequestId) {
      this.rsaProgressData.trackingIdFlags.isTrackingDetails = true;
      this.rsaProgressData.step1.rsaRequestId = data.rsaRequestId;
      this.saveTrackingRSARequestClick();
      this.rsaProgressData.pageRedirects = false;
      this.router.navigate(["/submit-success", this.getRsaTrackingId()]);
    } else {
      this.rsaProgressData.loadingScreen = false;
      this.rsaProgressData.trackingIdFlags.isPhoneTapped = false;
      this.rsaProgressData.trackingIdFlags.hasCallbackAndPhoneTap = false;
      this.openDialog('alert');
    }
  }

  public headerPhoneClick() {
    //save tracking will work only when tracking id is availble
    this.rsaProgressData.pageRedirects = true;
    this.rsaProgressData.trackingIdFlags.isGoldCall = this.rsaProgressData.trackingIdFlags.isPhoneTapped ? false : !this.getSkipStatusForLog();
    this.rsaProgressData.trackingIdFlags.isSilverCall = this.rsaProgressData.trackingIdFlags.isPhoneTapped ? false : this.getSkipStatusForLog();
    this.saveTrackingRSARequest().subscribe((data) => {
      if(data.isCallActive == false){
        this.nonAPISplunkLog("Transfer to agent Phone Icon Non-active phone call");
      }
      this.rsaProgressData.isCallSubmitted = data.isCallSubmitted;
     /*  if(this.rsaProgressData.trackingIdFlags.hasCallBack && data.isCallSubmitted) {
        this.rsaProgressData.loadingScreen = true;
        this.submitRequest(true).subscribe(
          data => {
            this.rsaProgressData.loadingScreen = false;
            if (data && data.rsaRequestId) {
              this.rsaProgressData.trackingIdFlags.isTrackingDetails = true;
              this.rsaProgressData.step1.rsaRequestId = data.rsaRequestId;
              this.saveTrackingRSARequestClick();
              this.rsaProgressData.pageRedirects = false;
              this.router.navigate(["/submit-success", this.getRsaTrackingId()]);
            } else {
              this.rsaProgressData.loadingScreen = false;
              this.rsaProgressData.trackingIdFlags.isPhoneTapped = false;
              this.rsaProgressData.trackingIdFlags.hasCallbackAndPhoneTap = false;
              this.openDialog('alert');
            }
          }
        );
      } */
    });
  }  

  public saveTrackingRSARequest(isNgpCall?, isBeforeSubmission?) {
    let submitObj = this.getSubmitObject();
    let apiUrl = environment.AppConfig.TRACKING.saveTrackUrl;
    let trackingId = this.getRsaTrackingId();
    if (submitObj.profile && submitObj.profile.contactInfo) {
      submitObj.profile.contactInfo.phoneNumber = submitObj.profile.contactInfo.phoneNumber == "" ? this.rsaProgressData.callbacknumber_fromurl : submitObj.profile.contactInfo.phoneNumber;
    }

    if (submitObj.requestor) {
      submitObj.requestor.callbackNumber = submitObj.requestor.callbackNumber == "" ? this.rsaProgressData.callbacknumber_fromurl : submitObj.requestor.callbackNumber;
    }
    if (this.rsaProgressData.step1.rsaRequestId) {
      submitObj.rsaRequestId = this.rsaProgressData.step1.rsaRequestId;
    }
    if(this.rsaProgressData.step1.JobId){
      submitObj.ngpPreDraftJobId = this.rsaProgressData.step1.JobId;
    }
    // if (this.rsaProgressData.step1.JobId && isNgpCall) {
    //   submitObj.rsaRequestId = this.rsaProgressData.step1.JobId;
    // }
    if (this.rsaProgressData.trackingIdFlags.hasCallBack) {
      submitObj.vdn = this.rsaProgressData.query_strings.vdn;
      // submitObj.estDateTime = this.rsaProgressData.query_strings.estDateTime;
    }
    if (this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId) {
      submitObj.RSAHelpRequestId = this.rsaProgressData.step1.rsahelpRequestId;
    }
    let menu = this.rsaProgressData.step1.menu;
    this.getSymptomsForTow(menu, submitObj);
    submitObj.additionalDetails = {};
    if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu") {
      submitObj.additionalDetails = {
        isSkipTow: this.rsaProgressData[menu].step4.skipTow,
        isSkipLocation: this.rsaProgressData[menu].step3.skipLocation,
        isSkipPolicy: this.rsaProgressData[menu].step5.skipPolicy,
        isSkipVin: this.rsaProgressData[menu].step6.skipVin,
        isTowNeeded: this.rsaProgressData[menu].step4.isTowNeeded,
        vehicleListFromPolicy: this.rsaProgressData[menu].step5.skipPolicy ? "" : this.rsaProgressData[menu].step6.vehicleinformation,
        isCustomAddress: this.rsaProgressData[menu].step4.isCustomAddress,
        selectedTowObj: this.rsaProgressData.selectedTowDestination,
        isElectricVehicle: this.rsaProgressData[menu].step2.isElectricVehicle,
        selectedMenu: menu,
        isUISubmitInitiated : isBeforeSubmission ? isBeforeSubmission : false, // notify api about the submission is initiated from UI
        clientType : this.appDataConfig.type == 'AUTO' ? 'OEM' : this.appDataConfig.type,
        lastPolicyTried : this.rsaProgressData[menu].step5.pmrNumber,
        isPolicySearchInvalid:this.rsaProgressData.profileSearchError,
        vinDecoder: this.rsaProgressData[menu].step6.vinDecoder ? true : false,
        isEncaptchaSuccess: this.rsaProgressData.isEncaptchaSuccess ? true : false,
        payment:{
          isPaymentReqd : this.rsaProgressData.payment.isPaymentReqd,
          amountDue : this.rsaProgressData.payment.amountDue,
          customerWebLink : this.rsaProgressData.payment.customerWebLink
        },
        profileCollected: this.rsaProgressData.profileCollected
      }
      if (this.appDataConfig.programcode == 'TUR') {
        submitObj.additionalDetails.reservationNumber = this.rsaProgressData[menu].step5.policynumber;
      }
      switch (menu) {
        case "battery_menu":
          submitObj.additionalDetails.jumpStart = this.rsaProgressData[menu].step2.q2;        
          submitObj.additionalDetails.vehicleType = this.rsaProgressData[menu].step2.q4;
          submitObj.additionalDetails.vehicleStop = this.rsaProgressData[menu].step2.q3;
          break;
        case "needtow_menu":
          submitObj.additionalDetails.customerAtHome  = this.rsaProgressData[menu].scheduleService.isSafeAtHome;        
          submitObj.additionalDetails.towScheduleQuestion = this.rsaProgressData[menu].step2.isTowRecommend;
          submitObj.additionalDetails.vehicleNeutral = this.rsaProgressData[menu].step2.vehicleNeutral;
          submitObj.additionalDetails.fourWheelDrive = this.rsaProgressData[menu].step2.fourWheelDrive;
          break;
        case "flat_menu":
          submitObj.additionalDetails.spareTire = this.rsaProgressData[menu].step2.q3;
          submitObj.additionalDetails.tireSelected = this.rsaProgressData[menu].step2.tireSelected;
          if (this.appDataConfig.isTrailerRequired) {
            submitObj.additionalDetails.flatTireTrailer = this.rsaProgressData[menu].step2.flatTireTrailer;
            submitObj.additionalDetails.noOfFlatTiresTrailer = this.rsaProgressData[menu].step2.noOfFlatTiresTrailer;
          }
          break;
        case "lockout_menu":
          submitObj.additionalDetails.isVehicleOn = this.rsaProgressData[menu].step2.isVehicleOn; 
          submitObj.additionalDetails.keysLocated = this.rsaProgressData[menu].step2.keysLocated; 
          submitObj.additionalDetails.trunkOptions = this.rsaProgressData[menu].step2.trunkOptions; 
          submitObj.additionalDetails.lockedInside = this.rsaProgressData[menu].step2.q3;
          break;
        case "outfuel_menu":
          submitObj.additionalDetails.fuelType = this.rsaProgressData[menu].step2.q2;
          break;
        case "vehiclestuck_menu":
          submitObj.additionalDetails.distance = this.rsaProgressData[menu].step2.q2;
          submitObj.additionalDetails.Driveable = this.rsaProgressData[menu].step2.q3;
          submitObj.additionalDetails.tiresStuckOn = this.rsaProgressData[menu].step2.q5;
          break;
      }
      submitObj.isLockedInside = (menu == 'lockout_menu' && this.rsaProgressData[menu].step2 && this.rsaProgressData[menu].step2.q3 == 'yes')
      submitObj.additionalDetails.isPolicySearchSuccess = this.rsaProgressData[menu].step5.isPolicyValid;
      submitObj.additionalDetails.isVinSearchSuccess  = this.rsaProgressData[menu].step6.otherVehicle ? false : this.rsaProgressData[menu].step6.isVinFound;
      submitObj.additionalDetails.vinSearch  = this.rsaProgressData[menu].step6.otherVehicle && !this.rsaProgressData[menu].step6.isVinFound ? '' : this.rsaProgressData[menu].step6.previousLast4DigitVin;
      submitObj.additionalDetails.isOtherVehicleSelected = this.rsaProgressData[menu].step6.otherVehicle;
    }
    submitObj.additionalDetails.callType = this.rsaProgressData.splunkInfo.callType;    
    submitObj.additionalDetails.ivrValue = this.rsaProgressData.audio_type;
    submitObj.additionalDetails.isUISubmitInitiated = isBeforeSubmission ? isBeforeSubmission : false; // notify api about the submission is initiated from UI
    submitObj.isGoldCall = (this.rsaProgressData.trackingIdFlags.isPhoneTapped ||
      (this.rsaProgressData.coverageStatus && this.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.getSkipStatusForLog(),
    submitObj.isSilverCall = this.getSilverCallFlag(),
    submitObj.stepId = this.rsaProgressData.step1.stepCount;
    submitObj.isTapOnPhone = this.rsaProgressData.trackingIdFlags.isPhoneTapped;
    submitObj.isLandingPage = menu ? false : this.rsaProgressData.step1.stepCount == 1;
    submitObj.isAccidentCall  = menu === "accident_menu";
    submitObj.isTrackingDetails = this.rsaProgressData.trackingIdFlags.isTrackingDetails;
    submitObj.isNgpClient = true;
    submitObj.isTextNotified = this.appDataConfig ? this.appDataConfig.isTextNotified : false;
    submitObj.isEmailNotified = this.appDataConfig ? this.appDataConfig.isEmailNotified : false;
    submitObj.isNoneNotified = this.appDataConfig ? this.appDataConfig.isNoneNotified : false;
  // setting location type and comments in saveRSA api also for clickToCall design
    if (submitObj && submitObj.disablementLocation) {
      submitObj.disablementLocation.locationType = this.locationType();
    }
    this.setComments(submitObj,menu);
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.TRACKING.apiKey,
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        'X-Agero-TrackingId': trackingId,
        'Content-Type': 'application/json',
        'apiPurpose': 'Saving RSA Request Data'
      })
    };

    return this.http.put<any>(apiUrl, submitObj, httpOptions)
      .pipe(
        tap(heroes => {
          LoggerService.log(`fetched data`)}),
        tap(data => (this.liveCallStatus = data.isCallActive)),
        catchError(this.handleError('getHeroes', []))
      );
    }
  
  public getClientDeatils(appURL, httpOptions) {
    return this.http.get<any>(appURL, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`getClientDeatils` + appURL)),
        catchError(this.handleError('getHeroes', []))
      );
  }

  public getExternalUrl(appURL, httpOptions) {
    return this.http.get<any>(appURL, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`getExternal URL` + appURL)),
        catchError(this.handleError('getExternal URL', []))
      );
  }

  public getLiveTrackingDetails(appURL, httpOptions) {

    return this.http.get<any>(appURL, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`getLiveTrackingDetails URL` + appURL)),
        catchError(this.handleError('getLiveTrackingDetails URL', []))
      );


  }

  public getRSAHelpDeatils(appURL, httpOptions) {
    return this.http.get<any>(appURL, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`getRSAHelpDeatils` + appURL)),
        catchError(this.handleError('getHeroes', []))
      );
  }

  private constructPayloadForTrackingID() {
    let requestPayload: any = {};
    if (this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId) {
      requestPayload = {
        RSAHelpRequestId: this.rsaProgressData.step1.rsahelpRequestId,
        CallBackNo: this.rsaProgressData.rsaHelpCallBackNumber,
        ProgramCode: this.appDataConfig.programcode
      }
    } else if (this.rsaProgressData.trackingIdFlags.hasCallBack) {
      requestPayload = {
        CallBackNo: this.rsaProgressData.callbacknumber_fromurl,
        ProgramCode: this.appDataConfig.programcode,
        vdn: this.rsaProgressData.query_strings.vdn
      }
    } else {
      requestPayload.ProgramCode = this.appDataConfig.programcode;
    }
    return requestPayload;
  }

  public getTrackingDetailsAndID(trackingId?) {
    let apiUrl = environment.AppConfig.TRACKING.trackingUrlWhenVDN;
    let requestPayload = {
      CallBackNo: this.rsaProgressData.callbacknumber_fromurl,
      vdn: this.rsaProgressData.query_strings.vdn,
      TrackingId: trackingId
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.TRACKING.apiKey,
        'Content-Type': 'application/json',
        'apiPurpose': 'Getting RSA Request Data'
      })
    };

    return this.http.post<any>(apiUrl, requestPayload, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`getTrackinDetailsAndID` + apiUrl)),
      );
  }

  getRequestDetails(rsaHelpRequestId) {
    let apiUrl = environment.AppConfig.RSAHELP.url + rsaHelpRequestId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': environment.AppConfig.RSAHELP.apiKey,
        'apiPurpose': 'Getting RSAHELP Request Data'
      })
    };
    return this.http.get(apiUrl, httpOptions).pipe(
      tap(heroes => LoggerService.log(`fetched heroes`)),
      catchError(this.handleError())
    );
  }

  public getRSAHelpTrackID() {

    let apiUrl = environment.AppConfig.TRACKING.trackingUrl;
    let requestPayload = this.constructPayloadForTrackingID();
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.TRACKING.apiKey,
        'Content-Type': 'application/json',
        'apiPurpose': 'Getting Tracking Id'
      })
    };

    return this.http.post<any>(apiUrl, requestPayload, {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.TRACKING.apiKey,
        'Content-Type': 'application/json'
      }),
      observe: "body"
    }
    )
      .pipe(
        tap(heroes => LoggerService.log(`getRSAHelpDeatils` + apiUrl)),
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
     // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      LoggerService.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /* Get API details from HTTP request */
  public getRsaAPI(): Observable<any> {
    return this.http.get<any>(this.apiUrl)
      .pipe(
        tap(heroes => LoggerService.log(`getRsaAPI` + this.apiUrl)),
        catchError(this.handleError('getRsaAPI', []))
      );
  }

  /*get policy details from the server*/
  public getPolicyDetails(): Observable<IPolicyConfig[]> {
    return this.http.get<IPolicyConfig[]>(this.policyUrl)
      .pipe(
        tap(data => LoggerService.log(`fetched policydeatils>>>`)),
        catchError(this.handleError('policy details', []))
      );
  }
  private extractData(res: Response) {
    let body = res.json();
    return body;
  }

  /* Save app configuration Data */
  public setRsaConfig(data: any) {
    //redirect to service type selection page when page reloads
    // if (this.rsaProgressData.browserRefresh && !this.router.url.includes('/rsahelp-step1') && this.rsaProgressData.step1.menu != "") {
    //   this.router.navigate(['/rsahelp-step1', this.getRsaTrackingId()]);
    // }
    if (data) {
      let apidata = this.appStrings;
      apidata = {...apidata, ...AppStrings.clientStyles[data[0].programcode]};
      apidata.name = (data[0].name) ? data[0].name : "agero";
      apidata.contactname = (data[0].contactname) ? data[0].contactname : "agero";
      apidata.chatName = (data[0].chatName) ? data[0].chatName : data[0].name;
      apidata.enableChat = data[0].enableChat;
      if(data && data[0].hasOwnProperty('isActive') && !data[0].isActive){
        this.router.navigate(["/error"]);
      }
      if(data[0].hasOwnProperty('numberForIVR')){
        apidata.number = this.rsaProgressData.trackingIdFlags.hasCallBack ? data[0].numberForIVR : data[0].number;
      }
      else {
        apidata.number = this.rsaProgressData.trackingIdFlags.hasCallBack ? "781-306-3904,24376" : data[0].number;
      }
      apidata.type = (data[0].type) ? data[0].type : "AUTO";
      apidata.logo = environment.AppConfig.LogosBaseURL.baseurl + "/" + data[0].logo.replace(/\\/g, '/');
      apidata.domain = data[0].domain;
      apidata.programcode = data[0].programcode;
      apidata.clientType = data[0].clientType;
      apidata.color = data[0].programcode === "ADC" ? data[0].color :'#006ce6';
      if(data[0].hasOwnProperty('isSuggestionRequired')) {
        apidata.isSuggestionRequired = data[0].isSuggestionRequired;
      }
      if(data[0].hasOwnProperty('isMaxClient')) {
        apidata.isMaxClient = data[0].isMaxClient;
      }
      if (data[0].hasOwnProperty('isReservationClient')) {
        apidata.isReservationClient = data[0].isReservationClient;
      }
      if (data[0].hasOwnProperty('isMemberClient')) {
        apidata.isMemberClient = data[0].isMemberClient;
      }
      if(data[0].hasOwnProperty('notificationPreference')) {
        apidata.isTextNotified = data[0].notificationPreference['isTextNotified'];
        apidata.isEmailNotified = data[0].notificationPreference['isEmailNotified'];
        apidata.isNoneNotified = data[0].notificationPreference['isNoneNotified'];
      }
      if (data[0].hasOwnProperty('alternateTransportation')) {
        apidata.alternateTransportation = {};
        apidata.alternateTransportation.isAlternateTransportRequired = data[0].alternateTransportation.isAlternateTransportRequired;
        apidata.alternateTransportation.voucherBenefit = data[0].alternateTransportation.voucherBenefit;
        apidata.alternateTransportation.voucherExpiryInHrs = data[0].alternateTransportation.voucherExpiryInHrs;
        apidata.alternateTransportation.voucherNoOfRides = data[0].alternateTransportation.voucherNoOfRides;
        apidata.alternateTransportation.rideProgramID = data[0].alternateTransportation.rideProgramID;
      }
      if (data[0].hasOwnProperty('isTrailerRequired')) {
        apidata.isTrailerRequired = data[0].isTrailerRequired;
      }
      if (data[0].hasOwnProperty('disableServiceItems')) {
        apidata.disableServiceItems = {
          disableDeadBattery: data[0].disableServiceItems.disableDeadBattery,
          disableFlatTire: data[0].disableServiceItems.disableFlatTire
        }
      }
      if (data[0].policyProp) {
        apidata.policyProp = {
          minLength: data[0].policyProp.minLength,
          maxLength: data[0].policyProp.maxLength,
          regexFormat: data[0].policyProp.regexFormat,
          inputType: data[0].policyProp.inputType,
          displayText: data[0].policyProp.displayText,
          isPolicyHintRequired : data[0].policyProp.isPolicyHintRequired,
          isPolicyInputLabelRequired : data[0].policyProp.isPolicyInputLabelRequired,
          isPolicyAlertMsgRequired :data[0].policyProp.isPolicyAlertMsgRequired
        }
      }
      if (data[0].hasOwnProperty('isVinHintRequired')) {
        apidata.isVinHintRequired = data[0].isVinHintRequired;
      }
      if (data[0].hasOwnProperty('isVinAlertMsgRequired')) {
        apidata.isVinAlertMsgRequired = data[0].isVinAlertMsgRequired;
      }
      if(data[0].hasOwnProperty('phoneIcon')) {
        apidata.phoneIcon = {
          path: data[0].phoneIcon.path,
          text: data[0].phoneIcon.text
        }
      }
      if (data[0].hasOwnProperty('isProfileNotEnabled')) {
        apidata.isProfileNotEnabled = data[0].isProfileNotEnabled;
      }
      if (data[0].hasOwnProperty('isPaymentConfig') && !data[0].isPaymentConfig) {
        apidata.isPaymentConfig = false;
      } else {
        apidata.isPaymentConfig = true;
      }
      if(data[0].hasOwnProperty('isDisplayTowReasonReqd')){
        apidata.isDisplayTowReasonReqd = data[0].isDisplayTowReasonReqd;
      }
      apidata.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      this.appDataConfig = apidata;
    } else {
      //console.log("Error in App Config");
    }
  }

  /* Get app configuration Data */
  public getRsaConfig() {
    return this.appDataConfig;
  }

  public getRSAData() {
    return this.rsaProgressData;
  }

  public getAgentIcon(): Observable<any> {
    let apiUrl = environment.AppConfig.AGENT_ICON.url;
    const httpOptions = {
      headers: new HttpHeaders({ "x-apiKey": environment.AppConfig.AGENT_ICON.apikey })
    };
    return this.http.get<any>(apiUrl, httpOptions)
      .pipe(
        tap(data => LoggerService.log(`getagentIcon` + apiUrl)),
        catchError(this.handleError('getagentIcon', []))
      );
  }

  public setRSAData(data: any) {
    this.rsaProgressData = data;
    //this.setRSAstepConfig();
  }

  public resetRSAData() {
    this.rsaProgressData = this.rsaResetData;

  }

  setRSAstepConfig(currentStep) {

    let menu = this.rsaProgressData.step1.menu;

    switch (menu) {
      case "battery_menu": {
        // let total = (this.rsaProgressData.battery_menu.step3.skipLocation) ? 8 : 9;
        // let currentStepVal = currentStep;
        // if (this.rsaProgressData.battery_menu.step2.q2 == "yes") {
        //   // this.rsaProgressData.step1.stepInfo = "STEP " + currentStepVal + " OF " + total;
        //   if (this.rsaProgressData.battery_menu.step3.skipLocation) {
        //     if (currentStep == 5) currentStepVal = 4;
        //     if (currentStep == 6) currentStepVal = 5;
        //     if (currentStep == 7) currentStepVal = 6;
        //     if (currentStep == 8) currentStepVal = 7;
        //     if (currentStep == 9) currentStepVal = 8;
        //     if(this.rsaProgressData.battery_menu.step4.skipTow){
        //       total = 7;
        //       if (currentStep == 9) currentStepVal = 7;
        //     }
            
        //   } else {
        //     if(this.rsaProgressData.battery_menu.step4.skipTow){
        //       total = 8;
        //       if (currentStep == 9) currentStepVal = 8;
        //     }
        //   }
        // } else {
        //   total = (this.rsaProgressData.battery_menu.step3.skipLocation) ? 7 : 8;
        //   if (currentStep == 5) currentStepVal = 4;
        //   if (this.rsaProgressData.battery_menu.step3.skipLocation) {
        //     if (currentStep == 6) currentStepVal = 4;
        //     if (currentStep == 7) currentStepVal = 5;
        //     if (currentStep == 8) currentStepVal = 6;
        //   } else {
        //     if (currentStep == 6) currentStepVal = 5;
        //     if (currentStep == 7) currentStepVal = 6;
        //     if (currentStep == 8) currentStepVal = 7;
        //   }
        // }
        let total = this.rsaProgressData.battery_menu.alternateTransportation.isAlternateTransportPageOffered ? 11 : 10;
        let currentStepVal = currentStep;
        if (this.rsaProgressData.battery_menu.step2.q2 == "yes" ) { // jump start  or vehicle stop running (TOW)
          // if (this.rsaProgressData.battery_menu.step3.skipLocation) {
          //   total = total - 1;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          // }
          if ((this.rsaProgressData.battery_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.battery_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
            total = total + 1;
            currentStepVal = currentStepVal;
          }
          if (this.rsaProgressData.battery_menu.step4.skipTow) {
            total = total - 1;
            currentStepVal = currentStepVal > 9 ? currentStepVal - 1 : currentStepVal;
          }
        } else if (this.rsaProgressData.battery_menu.step2.q4 == "Electric") { // EV feature
          // if (this.rsaProgressData.battery_menu.step3.skipLocation) {
          //   total = total - 1;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          // }
          if ((this.rsaProgressData.battery_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.battery_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
            total = total + 1;
            currentStepVal = currentStepVal;
          }
          if (this.rsaProgressData.battery_menu.step4.skipTow) {
            total = total - 1;
            currentStepVal = currentStepVal > 9 ? currentStepVal - 1 : currentStepVal;
          }
        } else {
          // if (this.rsaProgressData.battery_menu.step3.skipLocation) {
          //   total = total - 3;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          //   currentStepVal = currentStepVal > 6 ? currentStepVal - 2 : currentStepVal;
          // } else {
            if ((this.rsaProgressData.battery_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.battery_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
              total = total - 1;
              currentStepVal = currentStepVal > 8 ? currentStepVal - 2 : currentStepVal;
            } else {
              total = total - 2;
              currentStepVal = currentStepVal > 7 ? currentStepVal - 2 : currentStepVal;
            }
       // }
        }
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang == "en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        this.rsaProgressData.step1.stepCount = currentStepVal;
        break;
      }
      case "flat_menu": {
        // let total = (this.rsaProgressData.flat_menu.step3.skipLocation) ? 8 : 9;
        let total = this.rsaProgressData.flat_menu.alternateTransportation.isAlternateTransportPageOffered ? 11 : 10;
        let currentStepVal = currentStep;
        let flatTierCount = this.getFlatTireCount();


        if (this.rsaProgressData.flat_menu.step2.q3 == "no" || flatTierCount > 1) { // TOW
          //console.log("Here flatTierCount" + flatTierCount);
          // if (this.rsaProgressData.flat_menu.step3.skipLocation) {
          //   if (currentStep == 5) currentStepVal = 4;
          //   if (currentStep == 6) currentStepVal = 5;
          //   if (currentStep == 7) currentStepVal = 6;
          //   if (currentStep == 8) currentStepVal = 7;
          //   if (currentStep == 9) currentStepVal = 8;
          //   if(this.rsaProgressData.battery_menu.step4.skipTow){
          //     total = 7;
          //     if (currentStep == 9) currentStepVal = 7;
          //   }
          // } else {
          //   if(this.rsaProgressData.battery_menu.step4.skipTow){
          //     total = 8;
          //     if (currentStep == 9) currentStepVal = 8;
          //   }
          // }
          // if (this.rsaProgressData.flat_menu.step3.skipLocation) {
          //   total = total - 1;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          // }
          if ((this.rsaProgressData.flat_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.flat_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
            total = total + 1;
            currentStepVal = currentStepVal;
          }
          if (this.rsaProgressData.flat_menu.step4.skipTow) {
            total = total - 1;
            currentStepVal = currentStepVal > 9 ? currentStepVal - 1 : currentStepVal;
          }
        } else {
          // if (total == 8) total = 7;
          // if (total == 7) total = 6;
          // if (this.rsaProgressData.flat_menu.step3.skipLocation) {
          //   if (currentStep == 6) currentStepVal = 4;
          //   if (currentStep == 7) currentStepVal = 5;
          //   if (currentStep == 8) currentStepVal = 6;
          //   if (currentStep == 9) currentStepVal = 7;
          // } else {
          //   if (currentStep == 6) currentStepVal = 5;
          //   if (currentStep == 7) currentStepVal = 6;
          //   if (currentStep == 8) currentStepVal = 7;
          //   if (currentStep == 9) currentStepVal = 8;
          //   total = 7;
          // }
          // if (this.rsaProgressData.flat_menu.step3.skipLocation) {
          //   total = total - 3;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          //   currentStepVal = currentStepVal > 6 ? currentStepVal - 2 : currentStepVal;
          // } else {
            if ((this.rsaProgressData.flat_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.flat_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
              total = total - 1;
              currentStepVal = currentStepVal > 8 ? currentStepVal - 2 : currentStepVal;
            } else {
              total = total - 2;
              currentStepVal = currentStepVal > 7 ? currentStepVal - 2 : currentStepVal;
            }
         // }
        }
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang == "en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        this.rsaProgressData.step1.stepCount = currentStepVal;
        break;
      }
      case "lockout_menu": {
        //let total = (this.rsaProgressData.lockout_menu.step3.skipLocation) ? 6 : 7;
        let total = 8;
        let currentStepVal = currentStep;
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang == "en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        // if (this.rsaProgressData.lockout_menu.step3.skipLocation) {
        //   if (currentStep == 5) currentStepVal = 4;
        //   if (currentStep == 6) currentStepVal = 5;
        //   if (currentStep == 9) currentStepVal = 6;
        // } else {
          if ((this.rsaProgressData.lockout_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.lockout_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
            total = total + 1;
            currentStepVal = currentStepVal > 8 ? currentStepVal - 2 : currentStepVal;
          }
          if (currentStep == 10) currentStepVal = 8;
       // }
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang == "en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        this.rsaProgressData.step1.stepCount = currentStepVal;
        break;
      }
      case "needtow_menu": {
        // let total = (this.rsaProgressData.needtow_menu.step3.skipLocation) ? 6 : 7;
        // let currentStepVal = currentStep;
        // if (currentStep == 4) currentStepVal = 3;
        // if (currentStep == 5) currentStepVal = 4;
        // if (currentStep == 6) currentStepVal = 5;
        // if (currentStep == 7) currentStepVal = 6;
        // if (currentStep == 8) currentStepVal = 7;
        // this.rsaProgressData.step1.stepInfo = "STEP " + currentStepVal + " OF " + total;
        // if (this.rsaProgressData.needtow_menu.step3.skipLocation) {
        //   if (currentStep == 5) currentStepVal = 3;
        //   if (currentStep == 6) currentStepVal = 4;
        //   if (currentStep == 7) currentStepVal = 5;
        //   if (currentStep == 8) currentStepVal = 6;
        //   this.rsaProgressData.step1.stepInfo = "STEP " + currentStepVal + " OF " + total;
        // }
        let total = this.rsaProgressData.needtow_menu.alternateTransportation.isAlternateTransportPageOffered ? 11 : 10;
        let currentStepVal = currentStep;
        // if (this.rsaProgressData.needtow_menu.step3.skipLocation) {
        //   total = total - 1;
        //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
        // }
        if ((this.rsaProgressData.needtow_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.needtow_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
          total = total + 1;
          currentStepVal = currentStepVal;
        }
        if (this.rsaProgressData.needtow_menu.step4.skipTow) {
          total = total - 1;
          currentStepVal = currentStepVal > 9 ? currentStepVal - 1 : currentStepVal;
        }
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang == "en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        this.rsaProgressData.step1.stepCount = currentStepVal;
        break;
      }
      case "outfuel_menu": {
        // let total = (this.rsaProgressData.outfuel_menu.step3.skipLocation) ? 7 : 8;
        let total = this.rsaProgressData.outfuel_menu.alternateTransportation.isAlternateTransportPageOffered ? 11 : 10;
        let currentStepVal = currentStep;
        if (this.rsaProgressData.outfuel_menu.step2.q2 == "Unknown" || this.rsaProgressData.outfuel_menu.step2.q2 == "Electric") {
          // this.rsaProgressData.step1.stepInfo = "STEP " + currentStepVal + " OF " + total;
          // if (this.rsaProgressData.outfuel_menu.step3.skipLocation) {
          //   if (currentStep == 5) currentStepVal = 4;
          //   if (currentStep == 6) currentStepVal = 5;
          //   if (currentStep == 7) currentStepVal = 6;
          //   if (currentStep == 8) currentStepVal = 7;
          // }
          // if (this.rsaProgressData.outfuel_menu.step3.skipLocation) {
          //   total = total - 1;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          // }
          if ((this.rsaProgressData.outfuel_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.outfuel_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
            total = total + 1;
            currentStepVal = currentStepVal;
          }
          if (this.rsaProgressData.outfuel_menu.step4.skipTow) {
            total = total - 1;
            currentStepVal = currentStepVal > 9 ? currentStepVal - 1 : currentStepVal;
          }
        } else {
          // total = (this.rsaProgressData.outfuel_menu.step3.skipLocation) ? 6 : 7;
          // if (currentStep == 5) currentStepVal = 4;
          // if (this.rsaProgressData.outfuel_menu.step3.skipLocation) {
          //   if (currentStep == 6) currentStepVal = 4;
          //   if (currentStep == 7) currentStepVal = 5;
          //   if (currentStep == 8) currentStepVal = 6;
          // } else {
          //   if (currentStep == 6) currentStepVal = 5;
          //   if (currentStep == 7) currentStepVal = 6;
          //   if (currentStep == 8) currentStepVal = 7;
          // }
          // if (this.rsaProgressData.outfuel_menu.step3.skipLocation) {
          //   total = total - 3;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          //   currentStepVal = currentStepVal > 6 ? currentStepVal - 2 : currentStepVal;
          // }
          // else {
            if ((this.rsaProgressData.outfuel_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.outfuel_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
              total = total - 1;
              currentStepVal = currentStepVal > 8 ? currentStepVal - 2 : currentStepVal;
            } else {
              total = total - 2;
              currentStepVal = currentStepVal > 7 ? currentStepVal - 2 : currentStepVal;
            }
         // }
        }
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang =="en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        this.rsaProgressData.step1.stepCount = currentStepVal;
        break;
      }
      case "vehiclestuck_menu": {
        // let total = (this.rsaProgressData.vehiclestuck_menu.step3.skipLocation) ? 7 : 8;
        let total = this.rsaProgressData.vehiclestuck_menu.alternateTransportation.isAlternateTransportPageOffered ? 11 : 10;
        let currentStepVal = currentStep;
        if (this.rsaProgressData.vehiclestuck_menu.step2.q3 == "no") {
          // this.rsaProgressData.step1.stepInfo = "STEP " + currentStepVal + " OF " + total;
          // if (this.rsaProgressData.vehiclestuck_menu.step3.skipLocation) {
          //   if (currentStep == 5) currentStepVal = 4;
          //   if (currentStep == 6) currentStepVal = 5;
          //   if (currentStep == 7) currentStepVal = 6;
          //   if (currentStep == 8) currentStepVal = 7;
          // }
          // if (this.rsaProgressData.vehiclestuck_menu.step3.skipLocation) {
          //   total = total - 1;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          // }
          if ((this.rsaProgressData.vehiclestuck_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.vehiclestuck_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
            total = total + 1;
            currentStepVal = currentStepVal;
          }
          if (this.rsaProgressData.vehiclestuck_menu.step4.skipTow) {
            total = total - 1;
            currentStepVal = currentStepVal > 9 ? currentStepVal - 1 : currentStepVal;
          }
        } else {
          // total = (this.rsaProgressData.vehiclestuck_menu.step3.skipLocation) ? 6 : 7;
          // if (currentStep == 5) currentStepVal = 4;
          // if (this.rsaProgressData.vehiclestuck_menu.step3.skipLocation) {
          //   if (currentStep == 6) currentStepVal = 4;
          //   if (currentStep == 7) currentStepVal = 5;
          //   if (currentStep == 8) currentStepVal = 6;
          // } else {
          //   if (currentStep == 6) currentStepVal = 5;
          //   if (currentStep == 7) currentStepVal = 6;
          //   if (currentStep == 8) currentStepVal = 7;
          // }
          // if (this.rsaProgressData.vehiclestuck_menu.step3.skipLocation) {
          //   total = total - 3;
          //   currentStepVal = currentStepVal > 4 ? currentStepVal - 1 : currentStepVal;
          //   currentStepVal = currentStepVal > 6 ? currentStepVal - 2 : currentStepVal;
          // } else {
            if ((this.rsaProgressData.vehiclestuck_menu.step3.locationType == "GeneralParkingGarage" || this.rsaProgressData.vehiclestuck_menu.step3.locationType == "Residence/Work-ParkingGarage") && (this.appDataConfig.programcode == 'TRS'|| this.appDataConfig.programcode == 'CHR')) {
              total = total - 1;
              currentStepVal = currentStepVal > 8 ? currentStepVal - 2 : currentStepVal;
            } else {
              total = total - 2;
              currentStepVal = currentStepVal > 7 ? currentStepVal - 2 : currentStepVal;
            }
          //}
        }
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang =="en" ? "STEP " + currentStepVal + " OF " + total : "SCHRITT " + currentStepVal + " VON " + total;
        this.rsaProgressData.step1.stepCount = currentStepVal;
        break;
      }

      default: {
        this.rsaProgressData.step1.stepInfo = this._translateService.currentLang =="en" ? "STEP 1 OF 7" : "SCHRITT 1 VON 7";
        this.rsaProgressData.step1.stepCount = 1;
      }
    }

  }

  public getFlatTireCount() {
    let flatTierCount = 0;
    if (this.rsaProgressData.flat_menu.step2.frontDriver) {
      flatTierCount++;
    }
    if (this.rsaProgressData.flat_menu.step2.frontPassenger) {
      flatTierCount++;
    }
    if (this.rsaProgressData.flat_menu.step2.rearDriver) {
      flatTierCount++;
    }
    if (this.rsaProgressData.flat_menu.step2.rearPassenger) {
      flatTierCount++;
    }
    return flatTierCount;
  }

  public getVINDecoderAPI(vin: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', environment.AppConfig.VIN.apiKey);
    headers = headers.set('apiPurpose', 'VIN Decoder API');
    let body = {
      vin : vin,
      System: environment.AppConfig.VIN.system
    };
    return this.http.post<any>(environment.AppConfig.VIN.url, body , { headers: headers })
      .pipe(
        tap(heroes => LoggerService.log(`fetched VIN details`)),
        catchError(this.handleError('VIN Decoders api', []))
      );
  }

  public getVehicleInfoMakeAPI(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', environment.AppConfig.VEHICLEINFOMAKE.apiKey);
    headers = headers.set('apiPurpose', 'Getting Vehicle Make Values');
    let serviceUrl = (this.appDataConfig.programcode === "HDS" || this.appDataConfig.programcode === "AHM") ? environment.AppConfig.VEHICLEINFOMCMAKE.url:environment.AppConfig.VEHICLEINFOMAKE.url; 
    return this.http.get<any>(serviceUrl, { headers: headers })
      .pipe(
        tap(heroes => LoggerService.log(`fetched Make`)),
        catchError(this.handleError('Vehicle Make', []))
      );
  }

  public getVehicleInfoModelAPI(make) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', environment.AppConfig.VEHICLEINFOMODEL.apiKey);
    let vehicleModelUrl = environment.AppConfig.VEHICLEINFOMODEL.url + make + '/models';
 
    return this.http.get<any>(vehicleModelUrl, { headers: headers })
      .pipe(
        tap(heroes => LoggerService.log(`fetched Make`)),
        catchError(this.handleError('Vehicle Make', []))
      );
  }
  
  public getVehicleMakeFromVPICAPI() {

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', environment.AppConfig.VPICMAKE.apiKey);
    headers = headers.set('apiPurpose', 'Getting Vehicle Make Values from VPIC');

    return this.http.get<any>(environment.AppConfig.VPICMAKE.url, { headers: headers })
      .pipe(
        tap(heroes => LoggerService.log(`fetched Make`)),
        catchError(this.handleError('Vehicle Make', []))
      );
  }

  public getVehicleModelFromVPICAPI(make, year) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', environment.AppConfig.VPICMODEL.apiKey);
    headers = headers.set('apiPurpose', 'Getting Vehicle list based on Make and Year')

    let vehicleModelUrl = environment.AppConfig.VPICMODEL.url + '?year='+year+'&makeid='+ make;

    return this.http.get<any>(vehicleModelUrl, { headers: headers })
      .pipe(
        tap(heroes => LoggerService.log(`fetched Model`)),
        catchError(this.handleError('Vehicle Model', []))
      );
  }

  public getVehicleColorAPI() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Authorization', environment.AppConfig.COLOR.apiKey);
    return this.http.get<any>(environment.AppConfig.COLOR.url, { headers: headers })
      .pipe(
        tap(heroes => LoggerService.log(`fetched Color`)),
        catchError(this.handleError('Vehicle Make', []))
      );
  }

  public setColorData(obj) {
    this.appVehicleColor = obj;
  }
  public getColorData() {
    return this.appVehicleColor;
  }

  public setVehicleMake(obj) {
    this.appVehicleMake = obj;
  }
  public getVehicleMakeData() {
    return this.appVehicleMake;
  }

  public setVehicleMakeFromVPIC(obj) {
    this.appVehicleMakeFromVPIC = obj;
  }
  public getVehicleMakeDataFromVPIC() {
    return this.appVehicleMakeFromVPIC;
  }

  public showSnackBar(name): void {
    const config: any = new MatSnackBarConfig();
    //config.duration = environment.AppConfig.snackBarDuration;
    this.snackBar.open('Unfortunately there is some issue in API', 'OK', config);
  }

  public playAudio(file) {
    let fileSrc = "";
    let filesrcObj = environment.AppConfig.audioFiles.find((eachItem) => {
      return eachItem.id == file
    });
    fileSrc = filesrcObj ? filesrcObj.filename : "";

    if (this.rsaProgressData.audio_type == "0" || this.rsaProgressData.audio_type == "1" || this.rsaProgressData.audio_type == "3") {
      this.audio.src = environment.AppConfig.AUDIO_URL + fileSrc;
      this.audio.load();
      let playPromise = this.audio.play();
      if (playPromise !== undefined) {
        playPromise.then((success) => {
          // Automatic playback started!
          // Show playing UI.
          this.splunkAudioLog(environment.AppConfig.AUDIO_URL + fileSrc, success, true);
        })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            this.splunkAudioLog(environment.AppConfig.AUDIO_URL + fileSrc, error, false);
          });
      }
    }
  }
  private splunkAudioLog(fileName, response, status) {
    if (environment.logging) {
      let correlationid = this.splunkService.corelationID, //Unique id for the session
       eventAction = "", successMsg = "Audio API Success", failureMsg = "Audio API failed",
        mapProperties = status ? fileName + ' is loaded successfully' + response : fileName + ' is not found' + response;

      eventAction = status ? `${eventAction}${successMsg}${JSON.stringify(mapProperties)}` : `${eventAction}${failureMsg}${JSON.stringify(mapProperties)}`
      let step1 = this.rsaProgressData.step1,
        rsaProgressDataLog, callbackNumberLog = "";
      if (step1 && step1.menu && this.rsaProgressData[step1.menu]) {
        rsaProgressDataLog = this.rsaProgressData[step1.menu];
      }
      callbackNumberLog = this.callbackNumberLog();
      let menu = this.rsaProgressData[step1.menu]
      let isScheduled;
      if(menu && menu != "undefined"){
        isScheduled = this.rsaProgressData[step1.menu].scheduleService.isScheduled 
      }
      let correlationidEvent = this.loggingService.corelationID
      let eventLogData = {
        "body" : {
          'logLevel': 'Info',
          'requestTime':  this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
          'logDescription': status ? 'Audio API Success request' : 'Audio API failed request',
          'logMessage': eventAction,
          'correlationid': correlationidEvent,
          'stepInfo': step1,
          'AdditionalInfo': rsaProgressDataLog,
          'deviceInfo': {
            'platform': navigator.platform,
            'userAgent': navigator.userAgent
          },
          'pageTitle': this.rsaProgressData.splunkInfo.currentPageName,
          'callType': this.rsaProgressData.splunkInfo.callType,
          'adID': this.rsaProgressData.splunkInfo.adID,
          'deviceID': this.rsaProgressData.splunkInfo.deviceID,
          "trackingId": this.getRsaTrackingId(),
          "callbackNumber": callbackNumberLog,
          "isScheduled":isScheduled,
          "programCode": this.appDataConfig ? this.appDataConfig.programcode : "",
          "isGoldenCall": (this.rsaProgressData.trackingIdFlags.isPhoneTapped ||
            (this.rsaProgressData.coverageStatus && this.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.getSkipStatusForLog(),
          "isSilverCall": this.getSilverCallFlag(),
          "isUnpaidCall": this.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
          "clientType": this.appDataConfig ? this.appDataConfig.clientType : "",
          "sourceSystem": this.rsaProgressData.sourceSystem ? this.rsaProgressData.sourceSystem : "",
          "isNgpClient": true,
          "coverageStatus" : this.rsaProgressData.coverageStatus ? this.rsaProgressData.coverageStatus : "",
          "profileCollected" : this.rsaProgressData.profileCollected,
          "jobStatus": this.rsaProgressData.step1.jobStatus,
          "isChatEnabled" : this.chatAvailable
        },
        "logLevel" : "INFO",
        "application" : "RSAHELP_UI",
        "environment" : environment.envName
      }
      this.loggingService.eventLog(eventLogData);
    }
  }

  public dateValidation(modifiedDate) {
    console.log(moment(modifiedDate).format());
    return moment.duration(moment().diff(moment(modifiedDate).add(3, 'hours').format()));
  }

  public audioTypeSelection(url, apiKey) {
    let httpsOptions = {
      headers: new HttpHeaders({
        'Authorization': apiKey,
        'Content-Type': 'application/json',
        'apiPurpose': 'Selecting Audio Type'
      })
    }
    return this.http.get<any>(url, httpsOptions)
      .pipe(
        tap(response => LoggerService.log(`Audio Type URL` + url)),
        catchError(this.handleError('Audio Type URL', []))
      );
  }

  public nonAPISplunkLog(pageLog?, data?) {
    if (environment.logging) {
      let correlationid = this.splunkService.corelationID, //Unique id for the session
       step1 = this.rsaProgressData.step1,
        callbackNumberLog = "", rsaProgressDataLog;

      if (step1 && step1.menu && this.rsaProgressData[step1.menu]) {
        rsaProgressDataLog = this.rsaProgressData[step1.menu];
      }
      callbackNumberLog = this.callbackNumberLog();
      let menu = this.rsaProgressData[step1.menu]
      let isScheduled;
      if(menu && menu != "undefined"){
        isScheduled = this.rsaProgressData[step1.menu].scheduleService.isScheduled 
      }
      let correlationidEvent = this.loggingService.corelationID
      let eventlogData = {
        "body" : {
          'logLevel': 'Info',
          'requestTime':  this.datePipe.transform(new Date, 'yyyy-MM-dd hh:mm:ss'),
          'logDescription': 'PageInfo',
          'logMessage': 'Current page info: ' + pageLog,
          'correlationid': correlationidEvent,
          'stepInfo': step1,
          'AdditionalInfo': rsaProgressDataLog,
          'deviceInfo': {
            'platform': navigator.platform,
            'userAgent': navigator.userAgent
          },
          'data':data ? data : '',
          'httpUrl': window.location.href,
          'referer': window.document && window.document.referrer ? window.document.referrer : "unavailable",
          'pageTitle': this.rsaProgressData.splunkInfo.currentPageName,
          'callType': this.rsaProgressData.splunkInfo.callType,
          'adID': this.rsaProgressData.splunkInfo.adID,
          'deviceID': this.rsaProgressData.splunkInfo.deviceID,
          "trackingId": this.getRsaTrackingId(),
          "callbackNumber": callbackNumberLog,
          "isScheduled":isScheduled,
          "programCode": this.appDataConfig ? this.appDataConfig.programcode : "",
          "isGoldenCall": (this.rsaProgressData.trackingIdFlags.isPhoneTapped ||
            (this.rsaProgressData.coverageStatus && this.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.getSkipStatusForLog(),
          "isSilverCall": this.getSilverCallFlag(),
          "isDLSkip": this.skippedDl,
          "isTDSkip" : this.skippedTD,
          "isPolicySkip": this.skippedPolicy,
          "isVINSkip": this.skippedVin,
          "isPhoneTapped": this.rsaProgressData.trackingIdFlags.isPhoneTapped,
          "trackingOtherVinDetails": this.rsaProgressData.trackingIdFlags.trackingOtherVinDetails,
          "isUnpaidCall": this.rsaProgressData.step1.jobStatus == "Unpaid" ? true : false,
          "clientType": this.appDataConfig ? this.appDataConfig.clientType : "",
          "sourceSystem": this.rsaProgressData.sourceSystem ? this.rsaProgressData.sourceSystem : "",
          "isNgpClient": true,
          "coverageStatus" : this.rsaProgressData.coverageStatus ? this.rsaProgressData.coverageStatus : "",
          "profileCollected" : this.rsaProgressData.profileCollected,
          "jobStatus":this.rsaProgressData.step1.jobStatus,
          "isChatEnabled" : this.chatAvailable
        },
        "logLevel" : "INFO",
        "application" : "RSAHELP_UI",
        "environment" : environment.envName
      }
      this.loggingService.eventLog(eventlogData);
    }
  }  

  public callbackNumberLog() {

    let rsaProgressDataLog, callbackNumberLog = "";
    //Default flow
    if (this.rsaProgressData.step1.menu && this.rsaProgressData[this.rsaProgressData.step1.menu]) {
      rsaProgressDataLog = this.rsaProgressData[this.rsaProgressData.step1.menu],
        callbackNumberLog = rsaProgressDataLog.step5.phonenumber;
    }
    //IVR flow
    if (callbackNumberLog === "" && this.rsaProgressData.trackingIdFlags.hasCallBack) {
      callbackNumberLog = this.rsaProgressData.callbacknumber_fromurl;
    } else if (callbackNumberLog === "" && this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId) { // rsahelpRequestId flow
      callbackNumberLog = this.rsaProgressData.rsaHelpCallBackNumber;
    }
    return callbackNumberLog;
  }

  public menuDeselection(menuId, el) {
    menuId.forEach((menu) => {
      var element: any = el.querySelector("#" + menu);
      setTimeout(() => {
        element.style.backgroundColor = '#fff';
        // element.querySelector("img").src = this.appDataConfig["rsa_" + menu];
      }, 300);
    });
  }

  public getPolicyDetailsByPhoneNumber(phoneNumber) {
    let body = {
      phoneNumber: phoneNumber,
      jobId: this.rsaProgressData.step1.JobId,
      trackingId: this.getRsaTrackingId(),
      programCode: this.appDataConfig.programcode
    }
    let url = environment.AppConfig.ProfileSearchBasedonPhoneNumber.url
    return this.http.post<any>(url, body, {
      headers: new HttpHeaders({
        "Authorization": environment.AppConfig.ProfileSearchBasedonPhoneNumber.apikey,
          "X-Agero-ProgramCode": this.appDataConfig.programcode,
          // "X-Agero-ProgramSubCode": this.rsaProgressData.programsubcode,
          "X-Agero-SourceSystem": this.sourceSystemDecider(),
          'X-Agero-ClientType':this.appDataConfig.clientType,
          'Content-Type': 'application/json',
          'apiPurpose': 'Profile search based on Customer Phone number for NGP clients'
      }),
      observe: 'response'
    })
      .pipe(
        tap(response => console.log("data fetched")),
        catchError(this.handleError('Api fails', []))
      );
  }

  // public addingEasternTimeZone(splittedArray) {
  //   if(splittedArray && splittedArray.length>2) {
  //     let h = Math.floor(momenttz().tz("America/New_York").utcOffset()/60);
  //     let m = Math.abs(momenttz().tz("America/New_York").utcOffset()%60);
  //     let hh = (h>9)? Math.abs(h) : '0'+Math.abs(h);
  //     let mm = (m>9)? m : '0'+m;
  //     let ESTtimeZone =  (h>0) ? "+"+ hh +":" + mm : "-"+ hh +":" + mm;
  //     let zoneRemovedTime = splittedArray[5] ? splittedArray[5].replace(/[a-zA-Z ]/g, "") : null;

  //     return  splittedArray[3]+":"+splittedArray[4]+":"+zoneRemovedTime+ESTtimeZone;
  //   }
  // }

  // public convertESTtoCorrespondingTimeZone(estDateTime){
  //   let UTCValue = moment(estDateTime).utc().format(); //converting to UTC format.
  //   console.log("EST time :", estDateTime);
  //   console.log("EST to UTCValue :", UTCValue);
  //   // console.log(momenttz.tz(UTCValue, momenttz.tz.guess(true)).format());
  //   // console.log(moment(UTCValue).format());
  //   console.log("current time", moment().format());
  //   console.log('EST to currentZone', moment(UTCValue).format());
  //   console.log('Difference in ms', moment().diff(moment(UTCValue).format()));

  //   return moment.duration(moment().diff(moment(UTCValue).add(1, 'hours').format()));
  // }
  
  getTowDestinationAPI() {
    let menu = this.rsaProgressData.step1.menu;
    let appURL;
    let serviceName = 'dealers';
    let serviceForNGP = 'Tow';
    let make = (this.rsaProgressData[menu].step6.makeValue) ? ((this.rsaProgressData[menu].step6.makeValue).replace(/\s/g, "")) : this.rsaProgressData[menu].step6.makeValue ;
    let model = (this.rsaProgressData[menu].step6.model) ? ((this.rsaProgressData[menu].step6.model).replace(/\s/g, "")): this.rsaProgressData[menu].step6.model;
      if (this.appDataConfig.type == "INS") {        
        if (((this.appDataConfig.programcode != 'USM') || (this.appDataConfig.programcode == 'USM'
            && (this.rsaProgressData[menu].step6.otherVehicle || this.rsaProgressData[menu].step5.skipPolicy)))) {
          appURL = `${environment.AppConfig.SWOOP_TOWDESTINATION.url}?jobid=${this.rsaProgressData.step1.JobId}&latitude=${this.rsaProgressData[menu].step3.latitude}&longitude=${this.rsaProgressData[menu].step3.longitude}&vehiclemake=${make}&service=${serviceForNGP}`;
        }
        else {
          appURL = `${environment.AppConfig.SWOOP_TOWDESTINATION.url}?jobid=${this.rsaProgressData.step1.JobId}&latitude=${this.rsaProgressData[menu].step3.latitude}&longitude=${this.rsaProgressData[menu].step3.longitude}&vehiclemake=${make}&service=${serviceForNGP}`;
        }
      } else {
        appURL = `${environment.AppConfig.SWOOP_TOWDESTINATION.url}?jobid=${this.rsaProgressData.step1.JobId}&latitude=${this.rsaProgressData[menu].step3.latitude}&longitude=${this.rsaProgressData[menu].step3.longitude}&vehiclemake=${make}&service=${serviceForNGP}`;
        //appURL = (make && this.rsaProgressData[menu].serviceName) ? appURL + "&vehiclemake=" + make + "&service=" + this.rsaProgressData[menu].serviceName : appURL;
      }
    return this.http.get<any>(appURL, {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.SWOOP_TOWDESTINATION.apikey,
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        'apiPurpose': 'Getting Tow Destination for NGP',
        'X-Agero-ExtendedRetry': '1'
      }),
      observe: 'response'
    })
      .pipe(
        tap(heroes => LoggerService.log(`fetched data`))
      );
  }

  getEVChargeStations(): Observable<any> {
    let menu = this.rsaProgressData.step1.menu;
    let appURL = environment.AppConfig.EVCHARGESTATION.url;
    let requestPayload = {
      "latitude": this.rsaProgressData[menu].step3.latitude,
      "longitude": this.rsaProgressData[menu].step3.longitude
    };
    return this.http.post<any>(appURL, requestPayload, {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.EVCHARGESTATION.apikey,
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        'X-Agero-TrackingId': this.getRsaTrackingId(),
        'content-type': 'application/json',
        'apiPurpose': 'Getting nearest EV charge stations',
        'X-Agero-ExtendedRetry': '1'
      }),
      observe: 'response'
    })
      .pipe(
        tap(heroes => LoggerService.log(`fetched data`))
      );
  }

  getVinWithLast4Digits(vin) {
    let menu = this.rsaProgressData.step1.menu;
    let policyNumber = this.rsaProgressData[menu].step5.policynumber;
      if(!this.appDataConfig.isProfileNotEnabled){
      let appURL = `${environment.AppConfig.VINWITH4DIGITS.url}`;
      let requestPayload = {
        policyNumber,
        vin
      } 
  
      return this.http.post<any>(appURL, requestPayload, {
        headers: new HttpHeaders({
          'Authorization': environment.AppConfig.VINWITH4DIGITS.apikey,
          'X-Agero-ProgramCode': this.appDataConfig.programcode,
          'X-Agero-TrackingId': this.getRsaTrackingId(),
          'Content-Type': 'application/json',
          'apiPurpose': 'Get Policy Details for USM'
        }),
        observe: 'response'
      })
        .pipe(
          tap(heroes => console.log("data fetched"))
        );
    }
  }

  public preDraftJob(callbackNumber?) : Observable<any>{
    let trackingId = this.getRsaTrackingId();
    let url = environment.AppConfig.NGP.url 
    let requestPayload = {
      "trackingID": trackingId,
      "programCode": this.appDataConfig.programcode,
      "callbackNumber": this.rsaProgressData.callbacknumber_fromurl ? this.rsaProgressData.callbacknumber_fromurl : callbackNumber
    } 
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.NGP.apikey,
        'Content-Type': 'application/json',
        'apiPurpose': 'Creating PreDraft Job',
        "X-Agero-SourceSystem": this.sourceSystemDecider(),
      })
    };
    this.nonAPISplunkLog('requestPayload of preDraftJob ',requestPayload);
    return this.http.post<any>(url, requestPayload, httpOptions)
    .pipe(
      tap(heroes => {
        let trackingId = this.getRsaTrackingId();
        this.rsaProgressData.step1.JobId = heroes.Result.id;
        this.rsaProgressData.step1.jobStatus = heroes.Result.status;
        this.nonAPISplunkLog('Result of preDraftJob ',heroes);
        localStorage.setItem('jobId', JSON.stringify({ jobId: heroes.Result.id }));
        localStorage.setItem('trackingId', JSON.stringify({ trackingId:trackingId }));
      }),
      catchError(this.handleError('Job_id', []))
    );
  } 

  UpdateProfile(data, isUSM?, vehicleData?, isExactProfileFound?) {
    let sourceSystem = this.sourceSystemDecider();
    let personId;
    let vehicleId;
    if (isUSM) { // USM
      personId = data.personId;
      vehicleId = data.vehicleId;
    } else {
      personId  = isExactProfileFound ? isExactProfileFound[0].people[0].personId :  data['body'].profiles[0].people[0].personId;
      if (vehicleData && vehicleData.hasOwnProperty('vehicleId')) { // not for Goodsam, OEM client
        vehicleId = vehicleData.vehicleId;
      } else {
        let vehicleList = data['body'].profiles[0].vehicles;
        for (let vehicle of vehicleList) {
          if (vehicle.hasOwnProperty('unlisted') && !vehicle.unlisted) {
            vehicleId = vehicle.vehicleId;
            break;
          }
        }
      }
    }
    let JobId = this.rsaProgressData.step1.JobId;
    let trackingId = this.getRsaTrackingId();
    return this.http.post<any>(environment.AppConfig.NGP.UpdateProfile, {
      "trackingID": trackingId,
      "jobID": JobId,
      "personId": personId,
      "vehicleId": vehicleId
    }, {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.NGP.apikey,
        'Content-Type': 'application/json',
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        "TrackingId": trackingId,
        "X-Agero-JobId": JobId,
        'X-Agero-SourceSystem': sourceSystem,
        'apiPurpose': 'Updating Profile for NGP'
      })
    })
      .pipe(
        tap(heroes => {
          LoggerService.log(`UpdateProfile`)
        }),
        catchError(this.handleError('UpdateProfile api', []))
      );
  }

  public getDetailsFromVINNGP(number: any, isPolicy?: string) {
    let sourceSystem = this.sourceSystemDecider();
    let JobId = this.rsaProgressData.step1.JobId;
    let trackingId = this.getRsaTrackingId();
    let policyData;
    let vinData;
    let apiPurpose;
    if (isPolicy === "policy") {
      vinData = null;
      policyData = number;
      apiPurpose = 'Profile/Policy Search for NGP';
    } else {
      vinData = number;
      policyData = null;
      apiPurpose = 'VIN Search for NGP';
    }
    return this.http.post<any>(environment.AppConfig.NGP.profileUrl, {
      "trackingID": trackingId,
      "jobID": JobId,
      "policyNumber": policyData,
      "vin": vinData
    }, {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.NGP.apikey,
        'Content-Type': 'application/json',
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        "X-Agero-TrackingId": trackingId,
        'X-Agero-SourceSystem': sourceSystem,
        'apiPurpose': apiPurpose
      }),
      observe: 'response'
    })
      .pipe(
        tap(data => {
          this.nonAPISplunkLog('Result of getDetailsFromVINNGP ',data);
          let menu = this.rsaProgressData.step1.menu;
          LoggerService.log(`fetched VIN search limit`)
        })
      );
  }

  setComments(submitObj, menu): void {
    submitObj.serviceDetails.comments[0].value = '';
    this.setCommentWithEscalationCallType(submitObj);
    if ((menu && menu == "battery_menu" || menu == "outfuel_menu") && (this.rsaProgressData[menu].step2.q4 ||
      this.rsaProgressData[menu].step2.q2) && this.rsaProgressData[menu].step2.isElectricVehicle) {
      this.setCommentwithEVNotes(submitObj);
    }
    if (submitObj.disablementLocation.locationType == 'MilitaryBase') {
      this.setCommentWithMilitaryBaseNotes(submitObj);
    }
    if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu" && this.rsaProgressData[menu].step5.skipPolicy) {
      this.setCommentWithPolicyNumber(submitObj, menu);
    }
    if (submitObj.disablementLocation.locationType == 'BlockingTraffic') {
      this.setCommentWithHighwayLocation(submitObj, menu);
    }
    if (menu && menu == "vehiclestuck_menu" && this.rsaProgressData[menu].step2.q5) {
      this.setCommentWithVehicleStuck(submitObj, menu);
    }
  }
 
  coverageSearch(isFinalSubmission?: boolean) {
    let submitObj = this.getSubmitObject();
    let menu = this.rsaProgressData.step1.menu;
    if (submitObj && submitObj.disablementLocation) {
      submitObj.disablementLocation.locationType = this.locationType();
    }
    // if (isFinalSubmission) {
    //   this.setComments(submitObj, menu);
    // }
    let JobId = this.rsaProgressData.step1.JobId
    let trackingId = this.getRsaTrackingId()
    let sourceSystem = this.sourceSystemDecider();
    if (submitObj.profile && submitObj.profile.contactInfo) {
      submitObj.profile.contactInfo.phoneNumber = submitObj.profile.contactInfo.phoneNumber == "" ? this.rsaProgressData.callbacknumber_fromurl : submitObj.profile.contactInfo.phoneNumber;
    }
    if (submitObj.requestor) {
      submitObj.requestor.callbackNumber = submitObj.requestor.callbackNumber == "" ? this.rsaProgressData.callbacknumber_fromurl : submitObj.requestor.callbackNumber;
    }
    if (this.rsaProgressData.step1.JobId) {
    //  submitObj.rsaRequestId = this.rsaProgressData.step1.JobId;
    }
    if (this.rsaProgressData.trackingIdFlags.hasCallBack) {
      submitObj.vdn = this.rsaProgressData.query_strings.vdn;
      // submitObj.estDateTime = this.rsaProgressData.query_strings.estDateTime;
    }
    if (this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId) {
      submitObj.RSAHelpRequestId = this.rsaProgressData.step1.rsahelpRequestId;
    }
    this.getSymptomsForTow(menu, submitObj);
    submitObj.additionalDetails = {};
    if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu") {
      submitObj.additionalDetails = {
        isSkipTow: this.rsaProgressData[menu].step4.skipTow,
        isSkipLocation: this.rsaProgressData[menu].step3.skipLocation,
        isSkipPolicy: this.rsaProgressData[menu].step5.skipPolicy,
        isSkipVin: this.rsaProgressData[menu].step6.skipVin,
        isTowNeeded: this.rsaProgressData[menu].step4.isTowNeeded,
        vehicleListFromPolicy: this.rsaProgressData[menu].step5.skipPolicy ? "" : this.rsaProgressData[menu].step6.vehicleinformation,
        isCustomAddress: this.rsaProgressData[menu].step4.isCustomAddress,
        selectedTowObj: this.rsaProgressData.selectedTowDestination,
        isElectricVehicle: this.rsaProgressData[menu].step2.isElectricVehicle,
        selectedMenu: menu,
        lastPolicyTried : this.rsaProgressData[menu].step5.pmrNumber,
        isPolicySearchInvalid:this.rsaProgressData.profileSearchError
      }
      if (this.appDataConfig.programcode == 'TUR') {
        submitObj.additionalDetails.reservationNumber = this.rsaProgressData[menu].step5.policynumber;
      }
      switch (menu) {
        case "battery_menu":
          submitObj.additionalDetails.jumpStart = this.rsaProgressData[menu].step2.q2;
          submitObj.additionalDetails.vehicleStop = this.rsaProgressData[menu].step2.q3;
          break;
        case "flat_menu":
          submitObj.additionalDetails.spareTire = this.rsaProgressData[menu].step2.q3;
          submitObj.additionalDetails.tireSelected = this.rsaProgressData[menu].step2.tireSelected;
          if (this.appDataConfig.isTrailerRequired) {
            submitObj.additionalDetails.flatTireTrailer = this.rsaProgressData[menu].step2.flatTireTrailer;
            submitObj.additionalDetails.noOfFlatTiresTrailer = this.rsaProgressData[menu].step2.noOfFlatTiresTrailer;
          }
          break;
        case "needtow_menu":
          submitObj.additionalDetails.customerAtHome  = this.rsaProgressData[menu].scheduleService.isSafeAtHome;        
          submitObj.additionalDetails.towScheduleQuestion = this.rsaProgressData[menu].step2.isTowRecommend;
          submitObj.additionalDetails.vehicleNeutral = this.rsaProgressData[menu].step2.vehicleNeutral;
          submitObj.additionalDetails.fourWheelDrive = this.rsaProgressData[menu].step2.fourWheelDrive;
          break;
        case "lockout_menu":
          submitObj.additionalDetails.isVehicleOn = this.rsaProgressData[menu].step2.isVehicleOn; 
          submitObj.additionalDetails.keysLocated = this.rsaProgressData[menu].step2.keysLocated; 
          submitObj.additionalDetails.trunkOptions = this.rsaProgressData[menu].step2.trunkOptions; 
          submitObj.additionalDetails.lockedInside = this.rsaProgressData[menu].step2.q3;
          break;
        case "outfuel_menu":
          submitObj.additionalDetails.fuelType = this.rsaProgressData[menu].step2.q2;
          break;
        case "vehiclestuck_menu":
          submitObj.additionalDetails.distance = this.rsaProgressData[menu].step2.q2;
          submitObj.additionalDetails.Driveable = this.rsaProgressData[menu].step2.q3;
          submitObj.additionalDetails.tiresStuckOn = this.rsaProgressData[menu].step2.q5;
          break;
      }
      submitObj.isLockedInside = (menu == 'lockout_menu' && this.rsaProgressData[menu].step2 && this.rsaProgressData[menu].step2.q3 == 'yes')
      submitObj.additionalDetails.isPolicySearchSuccess = this.rsaProgressData[menu].step5.isPolicyValid;
      submitObj.additionalDetails.isVinSearchSuccess  = this.rsaProgressData[menu].step6.otherVehicle ? false : this.rsaProgressData[menu].step6.isVinFound;
      submitObj.additionalDetails.vinSearch  = this.rsaProgressData[menu].step6.otherVehicle && !this.rsaProgressData[menu].step6.isVinFound ? '' : this.rsaProgressData[menu].step6.previousLast4DigitVin;
      submitObj.additionalDetails.isOtherVehicleSelected = this.rsaProgressData[menu].step6.otherVehicle;
    }
    submitObj.additionalDetails.callType = this.rsaProgressData.splunkInfo.callType;    
    submitObj.additionalDetails.ivrValue = this.rsaProgressData.audio_type;
    submitObj.isGoldCall = (this.rsaProgressData.trackingIdFlags.isPhoneTapped ||
      (this.rsaProgressData.coverageStatus && this.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.getSkipStatusForLog(),
    submitObj.isSilverCall = this.getSilverCallFlag(),
    submitObj.stepId = this.rsaProgressData.step1.stepCount;
    submitObj.isTapOnPhone = this.rsaProgressData.trackingIdFlags.isPhoneTapped;
    submitObj.isLandingPage = menu ? false : this.rsaProgressData.step1.stepCount == 1;
    submitObj.isAccidentCall  = menu === "accident_menu";
    submitObj.isTrackingDetails = this.rsaProgressData.trackingIdFlags.isTrackingDetails;
    submitObj.isNgpClient = true;
    submitObj.isTextNotified = this.appDataConfig ? this.appDataConfig.isTextNotified : false;
    submitObj.isEmailNotified = this.appDataConfig ? this.appDataConfig.isEmailNotified : false;
    submitObj.isNoneNotified = this.appDataConfig ? this.appDataConfig.isNoneNotified : false;
    if (isFinalSubmission) {
      this.setComments(submitObj, menu);
    }
    return this.http.post<any>(environment.AppConfig.NGP.CoverageSearch, submitObj , {
        headers: new HttpHeaders({
          'Authorization': environment.AppConfig.NGP.apikey,
          'Content-Type': 'application/json',
          'X-Agero-ProgramCode': this.appDataConfig.programcode,
          'X-Agero-ClientType': this.appDataConfig.clientType,
          'X-Agero-SourceSystem': sourceSystem,
          'X-Agero-TrackingId': trackingId,
          'X-Agero-JobId': JobId,
          'apiPurpose': 'Checking Coverage',
          'X-Agero-ExtendedRetry': '1'
        }),
        observe: 'response'
      })
    .pipe(
      tap(heroes => {
      }),
      catchError(this.handleError('Job_id', []))
    );
  }

  setCommentWithPolicyNumber(submitObj: any, menu: string ): void {
    let profileName = this.appDataConfig.isMemberClient ? 'Member' : 'Policy';
    let comment = 'Customer entered ' + profileName + ' Number: ' + this.rsaProgressData[menu].step5.pmrNumber;
    if (submitObj.serviceDetails.comments[0].value != "" && submitObj.serviceDetails.comments[0].value.indexOf(comment) == -1) {
      submitObj.serviceDetails.comments[0].value = comment + ', ' + submitObj.serviceDetails.comments[0].value;
    } else if (submitObj.serviceDetails.comments[0].value == ""){
      submitObj.serviceDetails.comments[0].value = comment;
    }
    if(submitObj && submitObj.additionalDetails && submitObj.additionalDetails.selectedMenu)
      this.rsaProgressData[submitObj.additionalDetails.selectedMenu].step7.q1 = submitObj.serviceDetails.comments[0].value;
  }

  setCommentWithEscalationCallType(submitObj: any): void {
    if (this.escalationCallType) {
      if (submitObj.serviceDetails.comments[0].value != "" && submitObj.serviceDetails.comments[0].value.indexOf(this.escalationCallType) == -1) {
        submitObj.serviceDetails.comments[0].value = submitObj.serviceDetails.comments[0].value + ', ' + this.escalationCallType;
      } else if (this.escalationCallType && submitObj.serviceDetails.comments[0].value == "") {
        submitObj.serviceDetails.comments[0].value = this.escalationCallType
      }
      if (submitObj && submitObj.additionalDetails && submitObj.additionalDetails.selectedMenu)
        this.rsaProgressData[submitObj.additionalDetails.selectedMenu].step7.q1 = submitObj.serviceDetails.comments[0].value;
    }
  }

  setCommentwithEVNotes(submitObj: any): void {
    let noteToAgentForEV: string = 'Tow Electric Vehicle to charging station requested';
    if (submitObj.serviceDetails.comments[0].value != "" && submitObj.serviceDetails.comments[0].value.indexOf(noteToAgentForEV) == -1) {
      submitObj.serviceDetails.comments[0].value = submitObj.serviceDetails.comments[0].value + ', ' + noteToAgentForEV;
    } else if (submitObj.serviceDetails.comments[0].value == ""){
      submitObj.serviceDetails.comments[0].value = noteToAgentForEV;
    }
    if(submitObj && submitObj.additionalDetails && submitObj.additionalDetails.selectedMenu)
      this.rsaProgressData[submitObj.additionalDetails.selectedMenu].step7.q1 = submitObj.serviceDetails.comments[0].value;
  }

  setCommentWithMilitaryBaseNotes(submitObj: any): void {
    let noteForMilitaryBase: string = 'Customer states they are on a Military Base';
    if (submitObj.serviceDetails.comments[0].value != "" && submitObj.serviceDetails.comments[0].value.indexOf(noteForMilitaryBase) == -1) {
      submitObj.serviceDetails.comments[0].value = submitObj.serviceDetails.comments[0].value + ', ' + noteForMilitaryBase;
    } else if (submitObj.serviceDetails.comments[0].value == ""){
      submitObj.serviceDetails.comments[0].value = noteForMilitaryBase;
    }
    if(submitObj && submitObj.additionalDetails && submitObj.additionalDetails.selectedMenu)
      this.rsaProgressData[submitObj.additionalDetails.selectedMenu].step7.q1 = submitObj.serviceDetails.comments[0].value;
  }

  setCommentWithHighwayLocation(submitObj: any, menu: string): void {
    let directionOnHighway: string = '';
    if (this.rsaProgressData[menu].step3.direction) {
      directionOnHighway = 'Customer’s Direction is ' + this.rsaProgressData[menu].step3.direction;
    }
    if (this.rsaProgressData[menu].step3.exit) {
      directionOnHighway = directionOnHighway + ', ' + 'Nearest Exit is ' + this.rsaProgressData[menu].step3.exit;
    }
    if (submitObj.serviceDetails.comments[0].value != "" && submitObj.serviceDetails.comments[0].value.indexOf(directionOnHighway) == -1) {
      submitObj.serviceDetails.comments[0].value = submitObj.serviceDetails.comments[0].value + ', ' + directionOnHighway;
    } else if (submitObj.serviceDetails.comments[0].value == "") {
      submitObj.serviceDetails.comments[0].value = directionOnHighway;
    }
    if(submitObj && submitObj.additionalDetails && submitObj.additionalDetails.selectedMenu)
      this.rsaProgressData[submitObj.additionalDetails.selectedMenu].step7.q1 = submitObj.serviceDetails.comments[0].value;
  }

  setCommentWithVehicleStuck(submitObj: any, menu: string): void {
    let noteForVehicleTiresStuck = 'Vehicle tires are stuck on ' + this.rsaProgressData[menu].step2.q5;
    if (submitObj.serviceDetails.comments[0].value != "" && submitObj.serviceDetails.comments[0].value.indexOf(noteForVehicleTiresStuck) == -1) {
      submitObj.serviceDetails.comments[0].value = submitObj.serviceDetails.comments[0].value + ', ' + noteForVehicleTiresStuck;
    } else if (submitObj.serviceDetails.comments[0].value == ""){
      submitObj.serviceDetails.comments[0].value = noteForVehicleTiresStuck;
    }
    if(submitObj && submitObj.additionalDetails && submitObj.additionalDetails.selectedMenu)
    this.rsaProgressData[submitObj.additionalDetails.selectedMenu].step7.q1 = submitObj.serviceDetails.comments[0].value;
  }

  submitNgpIvrFlow(){
    let JobId = this.rsaProgressData.step1.JobId
    let trackingId = this.getRsaTrackingId()
    let sourceSystem = this.sourceSystemDecider();
    let data = {
      "trackingID": trackingId, 
      "jobID" :JobId
    };
    this.nonAPISplunkLog('submitNgpIvrFlow', data);
    return this.http.post<any>(environment.AppConfig.NGP.SubmitRequest, {
          "trackingID": trackingId, 
          "jobID" :JobId
        } , {
            headers: new HttpHeaders({
              'Authorization': environment.AppConfig.NGP.apikey,
              'Content-Type': 'application/json',
              'X-Agero-ProgramCode': this.appDataConfig.programcode,
              'X-Agero-ClientType':this.appDataConfig.clientType,
              'X-Agero-SourceSystem': sourceSystem,
              'apiPurpose': 'Final Job Submission',
              'X-Agero-ExtendedRetry': '0'
            })
          })
        .pipe(
          tap(heroes => {
            this.rsaProgressData.step1.jobStatus = heroes.status;
            this.nonAPISplunkLog('result of submitNgpIvrFlow',heroes);
            LoggerService.log(`submitNgpIvrFlow`)        
          }),
          catchError(this.handleError('submitNgpIvrFlow api', []))
        );
  }

  submitNgpNonIvrFlow(isFinalSubmission?: boolean){
    let submitObj = this.getSubmitObject();
    let menu = this.rsaProgressData.step1.menu;
    if (submitObj && submitObj.disablementLocation) {
      submitObj.disablementLocation.locationType = this.locationType();
    }
    if (isFinalSubmission) {
      this.setComments(submitObj, menu);
    }
    let JobId = this.rsaProgressData.step1.JobId
    let trackingId = this.getRsaTrackingId()
    let sourceSystem = this.sourceSystemDecider();
    if (submitObj.profile && submitObj.profile.contactInfo) {
      submitObj.profile.contactInfo.phoneNumber = submitObj.profile.contactInfo.phoneNumber == "" ? this.rsaProgressData.callbacknumber_fromurl : submitObj.profile.contactInfo.phoneNumber;
    }
    if (submitObj.requestor) {
      submitObj.requestor.callbackNumber = submitObj.requestor.callbackNumber == "" ? this.rsaProgressData.callbacknumber_fromurl : submitObj.requestor.callbackNumber;
    }
    if (this.rsaProgressData.trackingIdFlags.hasCallBack) {
      submitObj.vdn = this.rsaProgressData.query_strings.vdn;
      // submitObj.estDateTime = this.rsaProgressData.query_strings.estDateTime;
    }
    if (this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId) {
      submitObj.RSAHelpRequestId = this.rsaProgressData.step1.rsahelpRequestId;
    }
    this.getSymptomsForTow(menu, submitObj);
    submitObj.additionalDetails = {};
    if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu") {
      submitObj.additionalDetails = {
        isSkipTow: this.rsaProgressData[menu].step4.skipTow,
        isSkipLocation: this.rsaProgressData[menu].step3.skipLocation,
        isSkipPolicy: this.rsaProgressData[menu].step5.skipPolicy,
        isSkipVin: this.rsaProgressData[menu].step6.skipVin,
        isTowNeeded: this.rsaProgressData[menu].step4.isTowNeeded,
        vehicleListFromPolicy: this.rsaProgressData[menu].step5.skipPolicy ? "" : this.rsaProgressData[menu].step6.vehicleinformation,
        isCustomAddress: this.rsaProgressData[menu].step4.isCustomAddress,
        selectedTowObj: this.rsaProgressData.selectedTowDestination,
        isElectricVehicle: this.rsaProgressData[menu].step2.isElectricVehicle,
        selectedMenu: menu,
        lastPolicyTried : this.rsaProgressData[menu].step5.pmrNumber,
        isPolicySearchInvalid:this.rsaProgressData.profileSearchError
      }
      if (this.appDataConfig.programcode == 'TUR') {
        submitObj.additionalDetails.reservationNumber = this.rsaProgressData[menu].step5.policynumber;
      }
      switch (menu) {
        case "battery_menu":
          submitObj.additionalDetails.jumpStart = this.rsaProgressData[menu].step2.q2;
          submitObj.additionalDetails.vehicleStop = this.rsaProgressData[menu].step2.q3;
          break;
        case "flat_menu":
          submitObj.additionalDetails.spareTire = this.rsaProgressData[menu].step2.q3;
          submitObj.additionalDetails.tireSelected = this.rsaProgressData[menu].step2.tireSelected;
          if (this.appDataConfig.isTrailerRequired) {
            submitObj.additionalDetails.flatTireTrailer = this.rsaProgressData[menu].step2.flatTireTrailer;
            submitObj.additionalDetails.noOfFlatTiresTrailer = this.rsaProgressData[menu].step2.noOfFlatTiresTrailer;
          }
          break;
        case "needtow_menu":
          submitObj.additionalDetails.customerAtHome  = this.rsaProgressData[menu].scheduleService.isSafeAtHome;        
          submitObj.additionalDetails.towScheduleQuestion = this.rsaProgressData[menu].step2.isTowRecommend;
          submitObj.additionalDetails.vehicleNeutral = this.rsaProgressData[menu].step2.vehicleNeutral;
          submitObj.additionalDetails.fourWheelDrive = this.rsaProgressData[menu].step2.fourWheelDrive;
          break;
        case "lockout_menu":
          submitObj.additionalDetails.isVehicleOn = this.rsaProgressData[menu].step2.isVehicleOn; 
          submitObj.additionalDetails.keysLocated = this.rsaProgressData[menu].step2.keysLocated; 
          submitObj.additionalDetails.trunkOptions = this.rsaProgressData[menu].step2.trunkOptions; 
          submitObj.additionalDetails.lockedInside = this.rsaProgressData[menu].step2.q3;
          break;
        case "outfuel_menu":
          submitObj.additionalDetails.fuelType = this.rsaProgressData[menu].step2.q2;
          break;
        case "vehiclestuck_menu":
          submitObj.additionalDetails.distance = this.rsaProgressData[menu].step2.q2;
          submitObj.additionalDetails.Driveable = this.rsaProgressData[menu].step2.q3;
          submitObj.additionalDetails.tiresStuckOn = this.rsaProgressData[menu].step2.q5;
          break;
      }
      submitObj.isLockedInside = (menu == 'lockout_menu' && this.rsaProgressData[menu].step2 && this.rsaProgressData[menu].step2.q3 == 'yes')
      submitObj.additionalDetails.isPolicySearchSuccess = this.rsaProgressData[menu].step5.isPolicyValid;
      submitObj.additionalDetails.isVinSearchSuccess  = this.rsaProgressData[menu].step6.otherVehicle ? false : this.rsaProgressData[menu].step6.isVinFound;
      submitObj.additionalDetails.vinSearch  = this.rsaProgressData[menu].step6.otherVehicle && !this.rsaProgressData[menu].step6.isVinFound ? '' : this.rsaProgressData[menu].step6.previousLast4DigitVin;
      submitObj.additionalDetails.isOtherVehicleSelected = this.rsaProgressData[menu].step6.otherVehicle;
    }
    submitObj.additionalDetails.callType = this.rsaProgressData.splunkInfo.callType;    
    submitObj.additionalDetails.ivrValue = this.rsaProgressData.audio_type;
    submitObj.isGoldCall = (this.rsaProgressData.trackingIdFlags.isPhoneTapped ||
      (this.rsaProgressData.coverageStatus && this.rsaProgressData.coverageStatus != 'Covered')) ? false : !this.getSkipStatusForLog(),
    submitObj.isSilverCall = this.getSilverCallFlag(),
    submitObj.stepId = this.rsaProgressData.step1.stepCount;
    submitObj.isTapOnPhone = this.rsaProgressData.trackingIdFlags.isPhoneTapped;
    submitObj.isLandingPage = menu ? false : this.rsaProgressData.step1.stepCount == 1;
    submitObj.isAccidentCall  = menu === "accident_menu";
    submitObj.isTrackingDetails = this.rsaProgressData.trackingIdFlags.isTrackingDetails;
    submitObj.isNgpClient = true;
    submitObj.isTextNotified = this.appDataConfig ? this.appDataConfig.isTextNotified : false;
    submitObj.isEmailNotified = this.appDataConfig ? this.appDataConfig.isEmailNotified : false;
    submitObj.isNoneNotified = this.appDataConfig ? this.appDataConfig.isNoneNotified : false;
    this.nonAPISplunkLog('submitNgpNonIvrFlow',submitObj);
    const httpOptions: { headers; observe; } = {
      headers: new HttpHeaders({
        'Authorization': environment.AppConfig.NGP.apikey,
        'Content-Type': 'application/json',
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        'X-Agero-ClientType': this.appDataConfig.clientType,
        'X-Agero-SourceSystem': sourceSystem,
        'apiPurpose': 'Direct Job Submission'
      }),
       observe: 'response'
    };
    if (this.rsaProgressData.splunkInfo.callType == 'default' || this.rsaProgressData.splunkInfo.callType == 'IVR' || this.rsaProgressData.splunkInfo.callType == 'Client') {
      httpOptions.headers = httpOptions.headers.set('X-Agero-DirectClient', 'DirectClient');
    }
    return this.http.post<any>(environment.AppConfig.NGP.NonIvrFlow, submitObj, httpOptions)
    .pipe(
      tap(heroes => {this.nonAPISplunkLog('Result of submitNgpNonIvrFlow ',heroes); }),
      catchError(this.handleError('Job_id', []))
    );
  }

  locationType() {
    let submitObj = this.getSubmitObject();
    if (submitObj && submitObj.disablementLocation) {
      let locationType = submitObj.disablementLocation.locationType;
      if (locationType === 'General Parking Lot') {
        locationType = locationType.replace(/\s/g, "");
      } else if (locationType === 'General Parking Garage') {
        locationType = locationType.replace(/\s/g, "");
      } else if (locationType === 'Military Base') {
        locationType = locationType.replace(/\s/g, "");
      }
      if (locationType === "Residence/Work-Street") {
        submitObj.disablementLocation.locationType = "Roadside-Highway";
      } else if (locationType === "Other/I don't know") {
        submitObj.disablementLocation.locationType = "Unknown";
      } else {
        submitObj.disablementLocation.locationType = locationType;
      }
      submitObj.disablementLocation.locationType = (submitObj.disablementLocation.customerBlockingTraffic == 'yes') ? 'BlockingTraffic' : submitObj.disablementLocation.locationType;
    }
    return submitObj.disablementLocation.locationType;
  }

  // reset towaddress to empty when menu option is changed
  resetTowAddress(): void {
    let isRsaHelpFlow = JSON.parse(localStorage.getItem("isRsaHelpRequestIdFlow"));
    // introduced this condition for rsaHelpRequest flow to ignore clearing towAddress and its details
    if (!isRsaHelpFlow || isRsaHelpFlow && this.rsaProgressData.step1.selectedMenu != this.rsaProgressData.step1.menu){
      this.menuItems.forEach(menuItem => {
        this.rsaProgressData[menuItem].step4.towaddress = '';
        this.rsaProgressData[menuItem].step4.latitude = '';
        this.rsaProgressData[menuItem].step4.longitude = '';
        this.rsaProgressData[menuItem].step4.isCustomAddress = false;
        this.rsaProgressData[menuItem].step4.placeholder = '';
      });
    }
  }

  setElectricVehicleFalse(): void {
    this.menuItems.forEach(menuItem => {
      if (menuItem == 'battery_menu') {
        if(this.rsaProgressData[menuItem].step2.q4 == 'Electric' || this.rsaProgressData[menuItem].step2.q4 == '')
        this.rsaProgressData[menuItem].step2.isElectricVehicle =true ;
      } else {
        this.rsaProgressData[menuItem].step2.isElectricVehicle = false;
      }
    });
  }

  policySearchForNgp(phoneNumber) {
    let url = environment.AppConfig.ANI_NGP.url + phoneNumber,
      httpOptions = {
        headers: new HttpHeaders({
          "Authorization": environment.AppConfig.ANI_NGP.apikey,
          "X-Agero-ProgramCode": this.appDataConfig.programcode,
          "X-Agero-TrackingId": this.getRsaTrackingId(),
          'Content-Type': 'application/json',
          'apiPurpose': 'ANI search for Hagerty client'
        })
      };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(response => console.log("data fetched")),
        catchError(this.handleError('Api fails', []))
      );
  }

  submitClickToCall() {
    let request = {
      trackingId: this.getRsaTrackingId()
    };
    if(environment.AppConfig.hasOwnProperty('CLICKTOCALL')){
      return this.http.post<any>(environment.AppConfig['CLICKTOCALL'].url, request, {
        headers: new HttpHeaders({
          'Authorization': environment.AppConfig['CLICKTOCALL'].apikey,
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }).pipe(
        tap(data => { this.nonAPISplunkLog('Result of ClickToCall', data); }),
        catchError(this.handleError('Job_id', []))
      );
    }
  }

  getSilverCallFlag(): boolean {
    if (this.rsaProgressData.trackingIdFlags.isPhoneTapped) {
      return false;
    } else if (this.rsaProgressData.coverageStatus && this.rsaProgressData.coverageStatus != 'Covered') {
      return  true;
    } else {
      return this.getSkipStatusForLog();
    }
  }

  public programSubCodeForNgp(policyNumber:string): Observable<any> {
    //let phoneNum = this.rsaProgressData.callbacknumber_fromurl ? this.rsaProgressData.callbacknumber_fromurl : number;
    //let phnumber = phoneNum.replace(/-/g, "");

    let apiUrl = `${environment.AppConfig.NGP.GetProgramSubCode}/${policyNumber}`;
    
    
    const httpOptions = {
      headers: new HttpHeaders({ "Authorization": environment.AppConfig.NGP.apikey,
      'apiPurpose': 'Getting program subcode'
    })
    };
    return this.http.get<any>(apiUrl, httpOptions)
      .pipe(
        tap(data => {
          this.nonAPISplunkLog("Result of programsubcode ",data);
          LoggerService.log(`programSubCode` + apiUrl)
      })
      );
  }

  
  public getCustomVehicleModel() {
    return this.isCustomVehicleModel;
  }

  public setCustomVehicleModel(value) {
    this.isCustomVehicleModel = value;
  }
  checkDriverAvailablity(){
    let data = this.rsaProgressData[this.rsaProgressData.step1.menu];
    let apiUrl = environment.AppConfig.DRIVER_AVAILABILITY.url + `?lat=${data.step3.latitude}&lng=${data.step3.longitude}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': environment.AppConfig.DRIVER_AVAILABILITY.apikey,
        'apiPurpose': 'Checking Drivers Availability'
      })
    };
    return this.http.get(apiUrl, httpOptions).pipe(
      tap(res =>{
        this.nonAPISplunkLog("Result of drivers availability ",res);
        LoggerService.log(`fetched drivers availability`)}),
      catchError(this.handleError())
    );
  }
  getWeatherStatus(){
    let data = this.rsaProgressData[this.rsaProgressData.step1.menu];
    let apiUrl = environment.AppConfig.WEATHER_STATUS.url + `?disablementLocationDateTime=${moment().utc().format()}&zipcode=${data.step3.zip}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-apikey': environment.AppConfig.WEATHER_STATUS.apikey,
        'apiPurpose': 'Getting Weather Status'
      })
    };
    return this.http.get(apiUrl, httpOptions).pipe(
      tap(res =>{
        this.nonAPISplunkLog("Result of weather messaging ",res)}),
      catchError(this.handleError())
    );
  }
  public getLoadingConfig(){
    let config={};
    config = {
      
        backdropBackgroundColour: this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('spinner') ? this.appDataConfig['styles']['spinner']['backdropBackgroundColour'] : 'rgba(255,255,255,0.3)',
        backdropBorderRadius: this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('spinner') ? this.appDataConfig['styles']['spinner']['backdropBorderRadius'] : '10px',
        primaryColour: this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('spinner') ? this.appDataConfig['styles']['spinner']['primaryColour'] : '#fff',
        secondaryColour: this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('spinner') ? this.appDataConfig['styles']['spinner']['secondaryColour'] : '#006CE6',
        tertiaryColour: this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('spinner') ? this.appDataConfig['styles']['spinner']['tertiaryColour'] : '#ffffff',
      
      }
    return config;
  }
//RS1610 Feature Implementation NonIVR Transitioning to VIVR experience
  recaptchaSiteVerify(captchaResponse){
    let apiUrl = environment.AppConfig.RECAPTCHA_VALIDATE.url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':environment.AppConfig.RECAPTCHA_VALIDATE.apikey,
        'apiPurpose': 'Validating Recaptcha'
        
      })
    };
      let recaptchaObj = {
        "event":{
                
                "token":captchaResponse,
                "expectedAction":"login" 
      }
      }
      return this.http.post<any>(apiUrl, recaptchaObj, httpOptions)
      .pipe(
        tap(heroes => LoggerService.log(`fetched data`)),
        catchError(this.handleError('getHeroes', []))
      );
    
  }

  getCustomerRate(jobId){
    let apiUrl = environment.AppConfig.CUSTOMER_RATE.url + jobId;
    let trackingId = this.getRsaTrackingId()
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':environment.AppConfig.CUSTOMER_RATE.apikey,
        'X-Agero-ProgramCode': this.appDataConfig.programcode,
        'X-Agero-TrackingId': trackingId,
        'apiPurpose': 'Getting amount to be paid by Customer'
        })
    };
     
      return this.http.get<any>(apiUrl, httpOptions)
      .pipe(
        tap(data => {
          this.nonAPISplunkLog("Result of getCustomerRate ",data);
          LoggerService.log(`getCustomerRate` + apiUrl)
      }));
  }

  getSymptomsForTow(menu, submitObj) { // mapping symptoms for the reason - Tow (Not leaking fuel)
    if (submitObj && submitObj.serviceDetails && menu == "needtow_menu" && this.appDataConfig.isDisplayTowReasonReqd) { // need tow
      submitObj.serviceDetails.symptom = {};
      let key = this.rsaProgressData[menu].symptom.key;
      let value = this.rsaProgressData[menu].symptom.value;
      submitObj.serviceDetails.symptom = (key && value) ? { [key]: value } : null; 
    } else if (submitObj && submitObj.serviceDetails && submitObj.serviceDetails.disablementReason == "Tow (Not leaking fuel)"
      && menu == "flat_menu" && this.appDataConfig.isDisplayTowReasonReqd) { // single flat tire with No spare and multiple flat tire with Yes/No spare
      submitObj.serviceDetails.symptom = {};
      let key = "WheelIssues";
      let value = this.rsaProgressData[menu].step2.q3 == "no" ? "Flat Tire No Spare" : "Flat tire";
      submitObj.serviceDetails.symptom = { [key]: value };
    } else if (submitObj && submitObj.serviceDetails && submitObj.serviceDetails.disablementReason == "Tow (Not leaking fuel)"
      && menu == "outfuel_menu" && this.rsaProgressData.outfuel_menu.step2.q2 == "Electric" && this.appDataConfig.isDisplayTowReasonReqd) { // out of fuel with electric charge
      submitObj.serviceDetails.symptom = {};
      let key = "VehicleNotOperating";
      let value = "Out of Charge Event";
      submitObj.serviceDetails.symptom = { [key]: value };
    } else if (submitObj && submitObj.serviceDetails && submitObj.serviceDetails.disablementReason == "Vehicle Stuck"
      && menu == "vehiclestuck_menu"  && this.appDataConfig.isDisplayTowReasonReqd) { // vehicle stuck
      submitObj.serviceDetails.symptom = {};
      let key = "VehicleNotOperating";
      let value = "Winch Required";
      submitObj.serviceDetails.symptom = { [key]: value };
    }
  }

  getNGPJobStatus(jobId){
    let apiUrl = environment.AppConfig.NGP.GetNGPJobStatus + jobId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':environment.AppConfig.NGP.apikey,
        'X-Agero-ProgramCode': this.appDataConfig.programcode
        })
    };
     
      return this.http.get<any>(apiUrl, httpOptions)
      .pipe(
        tap(data => {
          this.nonAPISplunkLog("Result of getNGPJobstatus ",data);
          LoggerService.log(`getNGPJobstatus` + apiUrl)
      }));
  }
  
  // enabling chat feature
  loadChatBubble(showChat: boolean, pageName?: string) {
    if (this.appDataConfig && this.appDataConfig.enableChat && showChat) {
      this.chatAvailable = true;
      var chatAppCustomerInfo = {
        callbackNumber: this.rsaProgressData.callbacknumber_fromurl,
        clientCode: this.appDataConfig.programcode,
        clientName: this.appDataConfig.chatName,
        jobId: this.rsaProgressData.step1.JobId,
        appName: 'intake-process',
        stepInfo: pageName
      };
      this.ac(chatAppCustomerInfo);
    } else { // won't show chat Bubble
      this.chatAvailable = false;
      this.ac();
    }
  }

  ac(customerInfo?) {
    const windowRef: any = window;
    if (windowRef && windowRef.associateC1ChatCustomer) {
      if (customerInfo) {
        console.info("APP called", customerInfo);
        this.nonAPISplunkLog("CustomerInfo parameters for Chat Bubble", customerInfo);
        windowRef.associateC1ChatCustomer(customerInfo);
      }
    } else {
      setTimeout(() =>
        this.ac(customerInfo),
        500);
    }
  }

  openChat() {
    const windowRef: any = window;
    if (windowRef && windowRef.openC1Chat) {
      windowRef.openC1Chat();
      console.log("Opening Chat Window");
      this.nonAPISplunkLog("Opening Chat Window");
    } else {
      setTimeout(() =>
        this.openChat(),
        5000);
    }
  }

  /*get attribute details from the server*/
  public getAttributeDetails(): any {
    return this.attributeData;
  }
}
