import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

// import { AppConfig } from "../../config/app.config";


@Injectable()
export class CloudWatchLoggingService {
    
  corelationID:any= new Date().valueOf()+ "" + Math.floor(100000 + Math.random() * 900000) ;
  constructor(private http: HttpClient) {}
  
  public eventLog(logData): any {   
    let Authorization= environment.AppConfig.CLOUDWATCH.apiKey;
    let xhr = new XMLHttpRequest();
    xhr.open("POST",environment.AppConfig.CLOUDWATCH.url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization', Authorization);
    //console.log(JSON.stringify(logData));
    xhr.send(JSON.stringify(logData));
    }
}
