import { Component, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RsaDialog } from '../../rsa-decider/./dialog/rsa-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RsaDeciderService } from '../../rsa-decider/shared/rsadecider-service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
// import { AppConfig } from '../../config/app.config';
import 'rxjs/add/operator/map';
import { GoogleAnalyticsEventsService } from "../../google-analytics-events.service";
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss']
})


export class Error404Component {
  public errorAssistance: boolean = false;
  public today: Date = new Date();
  public rsahelpRequestId;
  public userInputTollfree = "";
  public assistance = new FormGroup({
    phoneNumber: new FormControl('', Validators.required)
  });

  constructor(public dialog: MatDialog, public googleAnalyticsEventsService: GoogleAnalyticsEventsService, private http: HttpClient, private actroute: ActivatedRoute, public router: Router, private RsaDeciderService: RsaDeciderService, private ngZone: NgZone) {

    this.googleAnalyticsEventsService.emitEvent("Landing Screen | "+ "adID= "+ this.RsaDeciderService.rsaProgressData.splunkInfo.adID + " | deviceID= " + this.RsaDeciderService.rsaProgressData.splunkInfo.deviceID, "Enter-10-Digit-Call-BackNumber", "Landing Screen", 10);

    this.actroute.data.map(data => data.cres).subscribe((data) => {
      if (data) {
        let response = JSON.parse(data);
        if (response) {
          this.googleAnalyticsEventsService.emitEvent("Landing Screen", "RSA-HELP-REQUEST | " + response.programCode, "RsaHelpRequestId | " + response.rsahelpRequestId, 10);
          this.rsahelpRequestId = response.rsahelpRequestId;
          //this.router.navigate(["/rsahelp-step1?num="+response.programCode]);
          localStorage.setItem("rsaHelpRequest", data);
          localStorage.setItem("isRsaHelpRequestIdFlow", response.rsahelpRequestId !== "" ? "true" : "false" );
          //this.processRsaHelpRequest(response);
          window.location.href = "?num=" + response.programCode;
        }
      }else{
        let rsaHelpRequestId = this.RsaDeciderService.getRsaHelpRequestId();
        if (rsaHelpRequestId || rsaHelpRequestId != "") {
          let hostname = (window.location.hostname != "localhost") ? window.location.hostname : "";
          //hostname = "usaa.towupload.com";
          let domainName = hostname.split(".");
          let clientId =domainName[0];
          if (clientId && clientId != null && clientId.trim() != "" && clientId != "www") {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization':  environment.AppConfig.ClientDetailsUrl.clientapikey,
                'apiPurpose': 'Getting Client details'
              })
            };
            let apiUrl = environment.AppConfig.ClientDetailsUrl.clienturl + clientId;
            this.RsaDeciderService.getClientDeatils(apiUrl, httpOptions).subscribe((data: any) => {
              if (data.length) {
                //this.router.navigate(["/rsahelp-step1?num"+data[0].number]);
                //this.googleAnalyticsEventsService.emitEvent("Landing-Screen", "Call-Back-Number-Success-"+ data[0].name, "Starting RSA "+data[0].name, 10);
                window.location.href = "?num=" + data[0].number;
              }
            });
          }
        }
      }
    });
  }

  ngOnInit() {
    this.RsaDeciderService.loadChatBubble(true, 'Error screen');
    this.RsaDeciderService.nonAPISplunkLog("Landing Page- Toll Free Number required");
  }

  openDialog(val): void {
    const dialogRef = this.dialog.open(RsaDialog, {
      closeOnNavigation: true,
      disableClose: true,
      data: { type: val }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');      
    });
  }

  validateTollFree(event) {
    if (event.target.value.length <= 12) {
      this.userInputTollfree = event.target.value;
    } else {
      event.target.value = this.userInputTollfree;
    }
  }

  validateAssistance() {
    let clientId = this.assistance.get('phoneNumber').value;
    if (clientId && clientId != null && clientId.trim() != "") {
      let apiUrl = environment.AppConfig.ClientDetailsUrl.clienturl + clientId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization':  environment.AppConfig.ClientDetailsUrl.clientapikey,
          'apiPurpose': 'Getting Client details'
        })
      };
      if(clientId == "800-444-4195" || clientId == "800-203-3377" || clientId == "888-839-5381" 
      || clientId == "866-864-7079" || clientId == "800-294-1303"){
        this.router.navigate(["/info"],{ queryParams: { id: clientId } });
      }else{ 
      this.RsaDeciderService.getClientDeatils(apiUrl, httpOptions).subscribe((data: any) => {
        if (data.length) {
          //this.router.navigate(["/rsahelp-step1?num"+data[0].number]);
          //this.googleAnalyticsEventsService.emitEvent("Landing-Screen", "Call-Back-Number-Success-"+ data[0].name, "Starting RSA "+data[0].name, 10);
          this.RsaDeciderService.rsaProgressData.pageRedirects = false;
          if(this.RsaDeciderService.rsaProgressData.httpUrlSearch){
            window.location.href = "?num=" + data[0].number+"&"+this.RsaDeciderService.rsaProgressData.httpUrlSearch;
          } else {
            window.location.href = "?num=" + data[0].number;
          }
        } else {
          this.errorAssistance = true;
          this.googleAnalyticsEventsService.emitEvent("Landing Screen | "+ "adID= "+ this.RsaDeciderService.rsaProgressData.splunkInfo.adID + " | deviceID= " + this.RsaDeciderService.rsaProgressData.splunkInfo.deviceID, "Call-Back-Number-Failure", "Call-Back-Number | " + clientId, 10);
        }

      });
    }
    } else {
      this.errorAssistance = true;
    }

  }
}
