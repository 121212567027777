import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AppConfig } from './config/app.config';
import { TocComponent } from './toc/toc/toc.component';
import { PpComponent } from './pp/pp/pp.component';
import { Error404Component } from './core/error404/error-404.component';
import { RsaresolverService } from './rsa-decider/shared/rsaresolver.service';
import { ErrorRsaresolverService } from './core/error404/error-resolver.service'
import { SafecoComponent } from './safeco/safeco.component';
import { environment } from 'src/environments/environment';
import { AlternateTransportComponent } from './alternate-transport/alternate-transport.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },

  {
    path: '', loadChildren: "../app/rsa-decider/rsadecider.module#RsaDeciderModule",
    resolve:
    {
      cres: RsaresolverService
    }
  },
  {
    path: 'info', component: SafecoComponent
  
  },
  {
    path: 'toc.html', component: TocComponent
  
  },
  {
    path: 'pp.html', component: PpComponent
  
  },
  {
    path:'lyft', component: AlternateTransportComponent,
    resolve:
    {
      cres: RsaresolverService
    }
  },

  { path: "service-rsa-request", component: Error404Component, resolve:{cres:ErrorRsaresolverService} },

  // otherwise redirect to 404
  { path: '**', redirectTo: '/service-rsa-request' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [RsaresolverService]
})

export class AppRoutingModule {
}
